import React, {useEffect, useState, useRef} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {loadSingleArticleId, updateArticle} from 'services/articles';
import moment from 'moment';
import _ from 'lodash';
import SearchInput from 'components/SearchInput';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Divider,
  Switch,
  TextField,
  Grid,
  Paper,
  Select,
  InputLabel,
  NativeSelect,
  Input,
  FormControl,
  Button,
  IconButton,
  TextareaAutosize,
  Checkbox,
} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import 'date-fns';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Controller, FormProvider} from 'react-hook-form';
import Swal from 'sweetalert2';
import {EditorState} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import slugify from 'slugify';
// import {IKImage, IKContext, IKUpload} from 'imagekitio-react';
import {functionUrl} from 'helper/functions';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  DateTimePicker,
  DatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
// import {CKEditor} from '@ckeditor/ckeditor5-react';
import {Editor} from '@tinymce/tinymce-react';
import {createArticle} from 'services/articles';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MomentUtils from '@date-io/moment';
import {storageRef, databaseRef} from 'services/firebase';
import Resizer from 'react-image-file-resizer';

// required parameter to fetch images
const urlEndpoint = 'https://ik.imagekit.io/gx66kjovk';

// optional parameters (needed for client-side upload)
const publicKey = 'public_XI/2WRm1ZtqX2Pth86kXnKoYnZ8=';
const authenticationEndpoint = 'https://www.your-server.com/auth';

const K_1_JAN_2000 = moment('2000-01-01', 'YYYY-MM-DD').valueOf();
const K_31_DEC_2100 = moment('2100-12-31', 'YYYY-MM-DD').valueOf();

const articleScheme = yup
  .object()
  .noUnknown(true)
  .required()
  .shape({
    title: yup
      .string()
      .typeError('Format title salah')
      .required('Judul artikel harus diisi')
      .max(200),
    slug: yup
      .string()
      .typeError('Format title salah')
      .required('Judul artikel harus diisi')
      .max(200),
    content: yup
      .string()
      .typeError('Format content salah')
      .required('content harus diisi'),
    caption: yup
      .string()
      .typeError('Format caption salah')
      .required('caption harus diisi'),
    coverImageURL: yup
      .string()
      .typeError('Format coverImageURL salah')
      .default('https://dummyimage.com/400x400/ffffff/595459')
      // .required('coverImageURL harus diisi')
      .max(500),
    isShowCoverInDetail: yup
      .boolean()
      .typeError('tipe isShowCoverInDetail salah')
      .default(false),
    categoryId: yup
      .string()
      .typeError('Format categoryId salah')
      .max(200)
      .required('Kategori harus diisi'),
    authorId: yup
      .string()
      .typeError('Format authorId salah')
      .max(200)
      .required('author harus diisi'),
    publishedAt: yup
      .number()
      .typeError('Format publishedAt salah')
      .required('waktu publikasi wajib diisi'),
    status: yup
      .string()
      .oneOf(['published', 'draft'])
      .default('draft'),
  });

const styles = {
  dialogPaper: {
    minHeight: '90vh',
    maxHeight: '90vh',
    borderRadius: 10,
  },
  addStockPaper: {
    minHeight: 450,
    maxWidth: 300,
  },
};

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const STATUSES = [
  {label: 'Draft', value: 'draft'},
  {label: 'Published', value: 'published'},
];

export default function ArticleEditor() {
  const serverTimeOffset = useSelector((state) =>
    _.get(state, 'user.serverTimeOffset', 0),
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const categories = useSelector((state) =>
    Object.entries(
      _.get(state, 'categories.data', {}),
    ).map(([key, value]) => ({key, ...value})),
  );
  const authors = useSelector((state) =>
    Object.entries(
      _.get(state, 'authors.data', {}),
    ).map(([key, value]) => ({key, ...value})),
  );
  const articles = useSelector((state) =>
    Object.values(_.get(state, 'articles.data', [])),
  );
  const uploadBtnRef = useRef(null);
  const [permalink, setPermalink] = useState('');
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(articleScheme),
  });

  const {
    control,
    watch,
    getValues,
    handleSubmit,
    errors,
    register,
    reset,
    setValue,
  } = methods;

  useEffect(() => {
    dispatch({
      type: 'categories/LOAD_DATA',
    });
    dispatch({
      type: 'authors/LOAD_DATA',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {articleId} = useParams();
  const [mode, setMode] = useState('create');
  useEffect(() => {
    if (articleId && articleId !== 'create') {
      setMode('edit');
      const selectedArticle = _.find(
        articles,
        (article) => article.articleId === articleId,
      );
      reset(selectedArticle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleId]);

  const [content, setContent] = useState('');

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  const handleSubmitError = (data) => {
    console.error('error', data);
  };

  const [selectedDate, setSelectedDate] = React.useState(
    new Date('2019-01-01T18:54'),
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [loading, setLoading] = useState(false);
  const [featuredImageUrl, setFeaturedImageUrl] = useState('');
  const onError = (data) => {
    console.error(data);
  };
  const onSuccess = (data) => {
    setLoading(true);
    if (mode === 'create')
      createArticle(data)
        .then((res) => {
          if (res && res.isSuccess) {
            Swal.fire('Yeay..', 'Article Saved!', 'success');
            history.goBack();
          }
          if (res && res.error) {
            Swal.fire('Upps..', _.get(res, 'error.message'), 'error');
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    else
      updateArticle({...data, articleId})
        .then((res) => {
          if (res && res.isSuccess) {
            Swal.fire('Yeay..', 'Article Saved!', 'success');
            history.goBack();
          }
          if (res && res.error) {
            Swal.fire('Upps..', _.get(res, 'error.message'), 'error');
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    // setValue('coverImageURL', data && data.filePath);
    // setFeaturedImageUrl(data && data.filePath);
  };

  useEffect(() => {
    if (loading)
      Swal.fire({
        html: 'Loading...',
        didOpen: () => {
          Swal.showLoading();
        },
      });
    else Swal.hideLoading();
  }, [loading]);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        750,
        5000,
        'webp',
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        'file',
      );
    });

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      const image = await resizeFile(file);
      console.log(file);
      const fileRef = storageRef.child(databaseRef.push().key);
      fileRef.put(image).then((res) => {
        res.ref.getDownloadURL().then((url) => {
          setFeaturedImageUrl(url);
          setValue('coverImageURL', url);
          console.log('uploaded', url);
        });
      });
    } catch (err) {
      console.log(err);
    }
    // const file = e.target.files[0] || {};
  };
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="id">
      <FormProvider {...methods}>
        <Grid container justify="space-between" className="mb-1">
          <Grid item md={6} sm={12}>
            <Grid container spacing={1} justify="space-between">
              <Grid item>
                <span></span>
                <span>
                  <h3>
                    <IconButton
                      // variant="outlined"
                      size="small"
                      disabled={loading}
                      style={{marginRight: 10}}
                      color="secondary"
                      onClick={() => history.goBack()}>
                      <i
                        className="fa fa-arrow-left"
                        style={{fontSize: 20}}
                      />
                    </IconButton>
                    {mode === 'create' ? 'Create' : 'Edit'} Article
                  </h3>
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} sm={12} style={{textAlign: 'right'}}>
            <Grid container spacing={1} justify="flex-end">
              <Grid item>
                <Button
                  variant="outlined"
                  size="small"
                  disabled={loading}
                  color="secondary"
                  onClick={handleSubmit(onSuccess, onError)}>
                  {' '}
                  {loading ? <>Saving...</> : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} justify="space-between">
          <Grid item md={9}>
            <Paper
              variant="outlined"
              style={{
                backgroundColor: 'white',
                padding: 10,
                borderRadius: 10,
              }}>
              <Controller
                control={control}
                defaultValue=""
                name="slug"
                render={({onChange, onBlur, value}) => <></>}
              />
              <Controller
                control={control}
                defaultValue=""
                name="title"
                render={({onChange, onBlur, value}) => (
                  <TextField
                    id="standard-error-helper-text"
                    label="Title"
                    value={value}
                    placeholder="Judul Artikel"
                    onChange={(e) => {
                      const val = _.get(e, 'target.value', '');
                      onChange(val);
                      const slug = slugify(val, {
                        lower: true,
                        strict: true,
                      });
                      setPermalink(slug);
                      setValue('slug', slug);
                    }}
                    style={{width: '100%'}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={`URL: /articles/${permalink}`}
                  />
                )}
              />
            </Paper>
            <br />
            <Paper
              variant="outlined"
              style={{
                backgroundColor: 'white',
                padding: 10,
                borderRadius: 10,
              }}>
              <Controller
                control={control}
                defaultValue=""
                name="caption"
                render={({onChange, onBlur, value}) => (
                  <>
                    <InputLabel>Caption</InputLabel>
                    <TextareaAutosize
                      aria-label="Description"
                      rowsMin={5}
                      value={value}
                      onChange={onChange}
                      style={{
                        width: '100%',
                        borderColor: '1px solid rgba(0, 0, 0, 0.12)',

                        borderRadius: 8,
                        padding: 8,
                      }}
                      placeholder="Tulis caption di sini"
                    />
                  </>
                )}
              />
            </Paper>
            <div
              style={{
                border: '1px solid #3333',
                backgroundColor: 'white',
                // minHeight: 400,
                padding: 10,
                borderRadius: 10,
                marginTop: 10,
              }}>
              <Controller
                control={control}
                defaultValue={''}
                name="content"
                render={({onChange, onBlur, value}) => (
                  <Editor
                    apiKey="kywypkk174d5gwqjcptaofmc5mzy4l9gdyjgt0g73ue17yl6"
                    initialValue={value}
                    init={{
                      selector: 'textarea#full-featured-non-premium',
                      plugins:
                        'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                      imagetools_cors_hosts: ['picsum.photos'],
                      menubar:
                        'file edit view insert format tools table help',
                      toolbar:
                        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                      toolbar_sticky: true,
                      images_upload_handler: function (
                        blobInfo,
                        success,
                        failure,
                      ) {
                        const file = blobInfo.blob();
                        const fileRef = storageRef.child(
                          databaseRef.push().key,
                        );
                        resizeFile(file).then((image) => {
                          console.log('ingin upload:', image);
                          fileRef
                            .put(image)
                            .then((res) => {
                              res.ref.getDownloadURL().then((url) => {
                                success(url);
                              });
                            })
                            .catch((err) => {
                              failure(JSON.stringify(err));
                            });
                        });
                      },
                      autosave_ask_before_unload: true,
                      autosave_interval: '30s',
                      autosave_prefix: '{path}{query}-{id}-',
                      autosave_restore_when_empty: false,
                      autosave_retention: '2m',
                      image_advtab: true,
                      link_list: [
                        {
                          title: 'My page 1',
                          value: 'https://www.tiny.cloud',
                        },
                        {
                          title: 'My page 2',
                          value: 'http://www.moxiecode.com',
                        },
                      ],
                      image_list: [
                        {
                          title: 'My page 1',
                          value: 'https://www.tiny.cloud',
                        },
                        {
                          title: 'My page 2',
                          value: 'http://www.moxiecode.com',
                        },
                      ],
                      image_class_list: [
                        {title: 'None', value: ''},
                        {title: 'Some class', value: 'class-name'},
                      ],
                      importcss_append: true,
                      file_picker_callback: function (
                        callback,
                        value,
                        meta,
                      ) {
                        /* Provide file and text for the link dialog */
                        // if (meta.filetype === 'file') {
                        //   callback(
                        //     'https://www.google.com/logos/google.jpg',
                        //     {text: 'My text'},
                        //   );
                        // }
                        // /* Provide image and alt text for the image dialog */
                        // if (meta.filetype === 'image') {
                        //   callback(
                        //     'https://www.google.com/logos/google.jpg',
                        //     {alt: 'My alt text'},
                        //   );
                        // }
                        // /* Provide alternative source and posted for the media dialog */
                        // if (meta.filetype === 'media') {
                        //   callback('movie.mp4', {
                        //     source2: 'alt.ogg',
                        //     poster:
                        //       'https://www.google.com/logos/google.jpg',
                        //   });
                        // }
                      },
                      templates: [
                        {
                          title: 'New Table',
                          description: 'creates a new table',
                          content:
                            '<div className="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
                        },
                        {
                          title: 'Starting my story',
                          description: 'A cure for writers block',
                          content: 'Once upon a time...',
                        },
                        {
                          title: 'New list with dates',
                          description: 'New List with dates',
                          content:
                            '<div className="mceTmpl"><span className="cdate">cdate</span><br /><span className="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
                        },
                      ],
                      template_cdate_format:
                        '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                      template_mdate_format:
                        '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                      height: 600,
                      image_caption: true,
                      quickbars_selection_toolbar:
                        'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                      noneditable_noneditable_class: 'mceNonEditable',
                      toolbar_mode: 'sliding',
                      contextmenu: 'link image imagetools table',
                      skin: 'oxide',
                      content_css: 'default',
                      content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                    onChange={(e) => {
                      onChange(e.target.getContent());
                    }}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={3}>
            <Paper
              variant="outlined"
              style={{padding: 10, borderRadius: 10}}>
              <div>
                <Controller
                  control={control}
                  defaultValue={'draft'}
                  name="status"
                  render={({onChange, onBlur, value, name}) => (
                    <FormControl style={{width: '100%'}}>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Select
                        native
                        style={{width: '100%'}}
                        value={value}
                        onChange={onChange}
                        input={
                          <Input
                            id="status"
                            style={{width: '100%'}}
                          />
                        }>
                        {STATUSES.map((status, index) => (
                          <option
                            key={status.value}
                            value={status.value}>
                            {status.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>
              <br />
              <div>
                <Controller
                  control={control}
                  defaultValue={moment().valueOf()}
                  name="publishedAt"
                  render={({onChange, onBlur, value, name}) => (
                    <DateTimePicker
                      autoOk
                      ampm={false}
                      disableFuture
                      format="DD/MM/yyyy HH:mm"
                      style={{width: '100%'}}
                      value={moment(value)}
                      onChange={(val) => onChange(val.valueOf())}
                      label="Publish At"
                    />
                  )}
                />
              </div>
              <br />
              <div>
                <Controller
                  control={control}
                  defaultValue={_.get(categories, '[0].key', '')}
                  name="categoryId"
                  render={({onChange, onBlur, value, name}) => (
                    <FormControl style={{width: '100%'}}>
                      <InputLabel htmlFor="category">
                        Category
                      </InputLabel>
                      <Select
                        native
                        style={{width: '100%'}}
                        value={value}
                        onChange={onChange}
                        input={
                          <Input
                            id="category"
                            style={{width: '100%'}}
                          />
                        }>
                        {(categories || []).map((category, index) => (
                          <option
                            key={category.key}
                            value={category.key}>
                            {category.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>
            </Paper>
            <br />
            <Paper
              variant="outlined"
              style={{padding: 10, borderRadius: 10}}>
              <div>
                <Controller
                  control={control}
                  defaultValue={_.get(authors, '[0].key', '')}
                  name="authorId"
                  render={({onChange, onBlur, value, name}) => (
                    <FormControl style={{width: '100%'}}>
                      <InputLabel htmlFor="author">Author</InputLabel>
                      <Select
                        native
                        style={{width: '100%'}}
                        value={value}
                        onChange={onChange}
                        input={
                          <Input
                            id="author"
                            style={{width: '100%'}}
                          />
                        }>
                        {(authors || []).map((author, index) => (
                          <option key={author.key} value={author.key}>
                            {author.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>
            </Paper>
            <br />
            <Paper
              variant="outlined"
              style={{padding: 10, borderRadius: 10}}>
              <div>Cover Image</div>
              <br />

              <Controller
                control={control}
                defaultValue={true}
                name="isShowCoverInDetail"
                render={({onChange, onBlur, value}) => (
                  <>
                    <Checkbox
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      inputProps={{'aria-label': 'primary checkbox'}}
                    />
                    Show cover in detail page
                  </>
                )}
              />
              <Controller
                control={control}
                defaultValue={''}
                name="coverImageURL"
                render={({onChange, onBlur, value}) => {
                  if (value)
                    return (
                      <img src={value} style={{width: '100%'}} />
                    );
                  else return <></>;
                }}
              />

              <input
                type="file"
                onChange={handleFileChange}
                name="fileToUpload"
                id="fileToUpload"
              />
            </Paper>
          </Grid>
        </Grid>
      </FormProvider>
    </MuiPickersUtilsProvider>
  );
}
