import {
  all,
  take,
  takeEvery,
  put,
  call,
  fork,
  select,
  cancel,
} from 'redux-saga/effects';

import actions from './actions';
import _ from 'lodash';
import {loadToolkits, loadPublishedToolkits} from 'services/toolkits';

export function* LOAD_DATA({payload}) {
  yield put({
    type: 'toolkits/UPDATE_STATE',
    payload: {
      isLoading: true,
    },
  });

  const result = yield call(loadToolkits, payload);

  yield put({
    type: 'toolkits/UPDATE_STATE',
    payload: {
      data: _.get(result, 'data.data', {}),
      maxPageNumber: _.get(result, 'data.maxPageNumber', {}),
      error: result.error,
    },
  });
  yield put({
    type: 'toolkits/UPDATE_STATE',
    payload: {
      isLoading: false,
    },
  });
}
export function* LOAD_PUBLISHED({payload}) {
  yield put({
    type: 'toolkits/UPDATE_STATE',
    payload: {
      isLoading: true,
    },
  });

  const result = yield call(loadPublishedToolkits, {...payload});

  yield put({
    type: 'toolkits/UPDATE_STATE',
    payload: {
      data: result.data || {},
      error: result.error,
    },
  });
  yield put({
    type: 'toolkits/UPDATE_STATE',
    payload: {
      isLoading: false,
    },
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_DATA, LOAD_DATA),
    takeEvery(actions.LOAD_PUBLISHED, LOAD_PUBLISHED),
  ]);
}
