import React, {Fragment, useEffect, memo} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
// import NProgress from 'nprogress';
import {Helmet} from 'react-helmet';
import EmptyLayout from './Empty';
import MainLayout from './MainLayout';
import AuthLayout from './AuthLayout';
import AdminLayout from './AdminLayout';
import {useSelector, useDispatch} from 'react-redux';
import _ from 'lodash';

// import {Spin} from 'antd';

const Layout = memo(({children, layout}) => {
  // katanya sih mirip pureComponent?
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const isAdmin = useSelector(
    (state) => _.get(state, 'user.isAdmin'),
    false,
  );

  const BootstrappedLayout = () => {
    if (layout === 'main') return <MainLayout>{children}</MainLayout>;
    if (layout === 'auth') return <AuthLayout>{children}</AuthLayout>;
    else if (layout === 'admin') {
      if (isAdmin) return <AdminLayout>{children}</AdminLayout>;
      else return <Redirect to="/" />;
    } else return <EmptyLayout>{children}</EmptyLayout>;
  };

  return (
    <Fragment>
      <Helmet titleTemplate="PALS | %s" title="SMART171" />
      {BootstrappedLayout()}
    </Fragment>
  );
  // }
});

export default Layout;
