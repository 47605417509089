import React, {useState, useEffect} from 'react';
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Container,
  makeStyles,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {Pagination, PaginationItem, Skeleton} from '@material-ui/lab';
import {loadVideos, loadPublishedVideos} from 'services/videos';
import CustomTopNav from 'components/layout/TopNav/CutomTopNav';

const useStyle = makeStyles((theme) => ({
  root: {
    color: 'white',
    textAlign: 'center',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const renderSkeleton = () =>
  [1, 2, 3, 4, 5, 6].map((item, index) => (
    <Grid
      key={index}
      item
      md={4}
      sm={6}
      xs={6}
      style={{textAlign: 'center', alignItems: 'center'}}>
      <Skeleton variant="rect" width="100%" height={150} />
      <br />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
    </Grid>
  ));
export default function Videos() {
  const classes = useStyle();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    setIsLoading(true);
    loadPublishedVideos().then((res) => {
      if (res.isSuccess) {
        setVideos(res.data);
      }
      setIsLoading(false);
    });
  }, []);
  const [mode, setMode] = useState('serial');
  return (
    <>
      {isMobile && (
        <CustomTopNav title="Video" showBack={false} showAuth />
      )}
      <Container className={classes.root}>
        <div
          style={{
            marginTop: 80,
            marginBottom: 30,
            color: 'white',
            fontWeight: 'bold',
            fontSize: 60,
            display: !isMobile ? 'block' : 'none',
          }}>
          Video
        </div>

        <Container style={{marginTop: 50}}>
          <Grid container item spacing={3} alignItems="center">
            {isLoading
              ? renderSkeleton()
              : (videos || []).map((item, index) => (
                  <Grid
                    key={index}
                    onClick={() =>
                      history.push(`/videos/${item.slug}`)
                    }
                    item
                    md={4}
                    sm={6}
                    xs={6}
                    style={{textAlign: 'center'}}>
                    <Link
                      to={{
                        pathname: `/videos/${item.slug}`,
                        state: {data: item},
                      }}>
                      {' '}
                      <img
                        src={item.coverImageURL}
                        style={{maxWidth: '100%'}}
                      />
                      <p style={{color: 'white', marginTop: 20}}>
                        {item.categoryLabel}
                      </p>
                      <h5
                        style={{
                          color: 'white',
                          marginTop: 15,
                          marginBottom: 25,
                          // fontSize: 24,
                        }}>
                        {item.title}
                      </h5>{' '}
                    </Link>
                  </Grid>
                ))}
          </Grid>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}>
            {/* <Pagination
            count={4}
            size="small"
            color="secondary"
            itemProp={{style: {color: 'white'}}}></Pagination> */}
          </div>
        </Container>
      </Container>
    </>
  );
}
