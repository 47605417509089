import {getFirebaseIdToken} from './user';
import {functionUrl} from 'helper/functions';
import axios from 'axios';
import _ from 'lodash';
import {serverTimestamp} from './firebase';

export async function loadLikes({collectionId, collectionType}) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.get(
      `${functionUrl}/likes?` + collectionId &&
        `collectionId=${collectionId}` +
          `&collectionType=${collectionType}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const data = _.get(response, 'data', {});
    // console.log('artikel dari fn,', response);
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function getLikesCount({collectionId, collectionType}) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/likes/count`,
      {collectionId, collectionType},
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const data = _.get(response, 'data', {});
    // console.log('artikel dari fn,', response);
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}

export async function createLike(payload) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(`${functionUrl}/likes`, payload, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    const data = _.get(response, 'data', {});
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function deleteLike({collectionId, collectionType}) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/likes/delete`,
      {collectionId, collectionType},
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const data = _.get(response, 'data', {});
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
