import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import user from './user/reducers';
import articles from './articles/reducers';
import courses from './courses/reducers';
import categories from './categories/reducers';
import banners from './banners/reducers';
import authors from './authors/reducers';
import videos from './videos/reducers';
import papers from './papers/reducers';
import toolkits from './toolkits/reducers';
import users from './users/reducers';
import configs from './configs/reducers';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    articles,
    courses,
    categories,
    banners,
    authors,
    videos,
    papers,
    toolkits,
    users,
    configs,
  });
