import React, {useEffect, useState, useRef} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  loadSingleCourseId,
  updateCourse,
  loadCourses,
} from 'services/courses';
import moment from 'moment';
import _ from 'lodash';
import SearchInput from 'components/SearchInput';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Divider,
  Switch,
  TextField,
  Grid,
  Paper,
  Select,
  InputLabel,
  NativeSelect,
  Input,
  FormControl,
  Button,
  IconButton,
  TextareaAutosize,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import 'date-fns';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Controller, FormProvider} from 'react-hook-form';
import Swal from 'sweetalert2';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import slugify from 'slugify';
// import {IKImage, IKContext, IKUpload} from 'imagekitio-react';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers';
// import {CKEditor} from '@ckeditor/ckeditor5-react';
import {Editor} from '@tinymce/tinymce-react';
import {createCourse} from 'services/courses';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MomentUtils from '@date-io/moment';
import {storageRef, databaseRef} from 'services/firebase';
import Resizer from 'react-image-file-resizer';

const courseScheme = yup
  .object()
  .noUnknown(true)
  .required()
  .shape({
    title: yup
      .string()
      .typeError('Format title salah')
      .required('Judul artikel harus diisi')
      .max(200),
    slug: yup
      .string()
      .typeError('Format title salah')
      .required('Judul artikel harus diisi')
      .max(200),
    description: yup
      .string()
      .typeError('Format content salah')
      .required('Label Packaging Unit harus diisi'),
    coverImageURL: yup
      .string()
      .typeError('Format coverImageURL salah')
      .default('https://dummyimage.com/400x400/ffffff/595459')
      // .required('coverImageURL harus diisi')
      .max(500),
    videoURL: yup
      .string()
      .typeError('Format videoURL salah')
      .default('')
      // .required('coverImageURL harus diisi')
      .max(500),
    liveYoutubeURL: yup
      .string()
      .typeError('Format liveYoutubeURL salah')
      .default('')
      // .required('coverImageURL harus diisi')
      .max(500),
    liveZoomURL: yup
      .string()
      .typeError('Format liveZoomURL salah')
      .default('')
      // .required('coverImageURL harus diisi')
      .max(500),
    categoryId: yup
      .string()
      .typeError('Format categoryId salah')
      .max(200)
      .required('Kategori harus diisi'),
    isBundled: yup
      .boolean()
      .typeError('Format isBundled salah')
      .default(false),
    parentId: yup
      .string()
      .typeError('Format parentId salah')
      .max(200)
      .default('-'),
    order: yup.number().typeError('Format order salah').default(0),

    startAt: yup
      .number()
      .typeError('Format startAt salah')
      .required('waktu kelas dimulai wajib diisi'),
    status: yup
      .string()
      .oneOf(['published', 'draft'])
      .default('draft'),
  });

const styles = {
  dialogPaper: {
    minHeight: '90vh',
    maxHeight: '90vh',
    borderRadius: 10,
  },
  addStockPaper: {
    minHeight: 450,
    maxWidth: 300,
  },
};

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const STATUSES = [
  {label: 'Draft', value: 'draft'},
  {label: 'Published', value: 'published'},
];

export default function CourseEditor() {
  const serverTimeOffset = useSelector((state) =>
    _.get(state, 'user.serverTimeOffset', 0),
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const categories = useSelector((state) =>
    Object.entries(
      _.get(state, 'categories.data', {}),
    ).map(([key, value]) => ({key, ...value})),
  );
  const categoriesLoading = useSelector((state) =>
    _.get(state, 'categories.isLoading', false),
  );

  const uploadBtnRef = useRef(null);
  const [permalink, setPermalink] = useState('');
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(courseScheme),
  });

  const {
    control,
    watch,
    getValues,
    handleSubmit,
    errors,
    register,
    reset,
    setValue,
  } = methods;

  const {courseId} = useParams();
  const [mode, setMode] = useState('create');
  const [bundledCoursesList, setBundledCoursesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [featuredImageUrl, setFeaturedImageUrl] = useState('');
  useEffect(() => {
    // load semua kategori dan semua course yang bundled dan parentId nya ""
    setLoading(true);
    loadCourses({status: 'all', parentId: '', isBundled: true}).then(
      (res) => {
        if (res.isSuccess) {
          const data = _.get(res, 'data.data', []);
          console.log('course bundled', data);
          setBundledCoursesList(data);
        }
        setLoading(false);
      },
    );
  }, []);

  useEffect(() => {
    if (courseId && courseId !== 'create') {
      setMode('edit');
      loadSingleCourseId(courseId).then((res) => {
        if (res.isSuccess) {
          const selectedCourse = res.data;
          reset(selectedCourse);
          setPermalink(selectedCourse.slug);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId]);

  const onError = (data) => {
    console.log(data);
  };
  const onSuccess = (data) => {
    setLoading(true);
    console.log(data);
    if (mode === 'create')
      createCourse(data)
        .then((res) => {
          console.log('res', res);
          if (res && res.isSuccess) {
            Swal.fire('Yeay..', 'Course Saved!', 'success');
            history.goBack();
          }
          if (res && res.error) {
            Swal.fire('Upps..', _.get(res, 'error.message'), 'error');
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    else
      updateCourse({...data, courseId})
        .then((res) => {
          console.log('res', res);
          if (res && res.isSuccess) {
            Swal.fire('Yeay..', 'Course Saved!', 'success');
            history.goBack();
          }
          if (res && res.error) {
            Swal.fire('Upps..', _.get(res, 'error.message'), 'error');
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    // setValue('coverImageURL', data && data.filePath);
    // setFeaturedImageUrl(data && data.filePath);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        750,
        750,
        'webp',
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        'file',
      );
    });

  const handleFileChange = async (e) => {
    try {
      let file = e.target.files[0] || {};
      file = await resizeFile(file);
      const fileName = databaseRef.push().key;
      const fileRef = storageRef.child(fileName);
      fileRef.put(file).then((res) => {
        console.log('uploaded', res);
        res.ref.getDownloadURL().then((url) => {
          setFeaturedImageUrl(url);
          setValue('coverImageURL', url);
          console.log('uploaded', url);
        });
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="id">
      {!categoriesLoading && (
        <FormProvider {...methods}>
          <Grid container justify="space-between" className="mb-1">
            <Grid item md={6} sm={12}>
              <Grid container spacing={1} justify="space-between">
                <Grid item>
                  <span></span>
                  <span>
                    <h3>
                      <IconButton
                        // variant="outlined"
                        size="small"
                        disabled={loading}
                        style={{marginRight: 10}}
                        color="secondary"
                        onClick={() => history.goBack()}>
                        <i
                          className="fa fa-arrow-left"
                          style={{fontSize: 20}}
                        />
                      </IconButton>
                      Create Course
                    </h3>
                  </span>
                  <span>
                    <Controller
                      control={control}
                      defaultValue={false}
                      name="isBundled"
                      render={({onChange, onBlur, value, name}) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value}
                              onChange={(e) =>
                                onChange(e.target.checked)
                              }
                              name="checkedA"
                            />
                          }
                          label="Bundled Course"
                        />
                      )}
                    />
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} sm={12} style={{textAlign: 'right'}}>
              <Grid container spacing={1} justify="flex-end">
                <Grid item>
                  <Button
                    variant="outlined"
                    size="small"
                    disabled={loading}
                    color="secondary"
                    onClick={handleSubmit(onSuccess, onError)}>
                    {' '}
                    {loading ? <>Saving...</> : 'Save'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} justify="space-between">
            <Grid item md={9}>
              <Paper
                variant="outlined"
                style={{
                  backgroundColor: 'white',
                  padding: 10,
                  borderRadius: 10,
                }}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="slug"
                  render={({onChange, onBlur, value}) => <></>}
                />
                <Controller
                  control={control}
                  defaultValue=""
                  name="title"
                  render={({onChange, onBlur, value}) => (
                    <TextField
                      id="standard-error-helper-text"
                      label="Title"
                      value={value}
                      placeholder="Judul Kelas"
                      onChange={(e) => {
                        const val = _.get(e, 'target.value', '');
                        onChange(val);
                        const slug = slugify(val, {
                          lower: true,
                          strict: true,
                        });
                        setPermalink(slug);
                        setValue('slug', slug);
                      }}
                      style={{width: '100%'}}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={`URL: /courses/${permalink}`}
                    />
                  )}
                />
              </Paper>
              <Paper
                variant="outlined"
                style={{
                  backgroundColor: 'white',
                  padding: 10,
                  borderRadius: 10,
                  marginTop: 10,
                }}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="videoURL"
                  render={({onChange, onBlur, value}) => (
                    <TextField
                      id="standard-error-helper-text"
                      label="Video URL"
                      value={value}
                      placeholder="Masukkan Link Video disini"
                      onChange={(e) => {
                        const val = _.get(e, 'target.value', '');
                        onChange(val);
                      }}
                      style={{width: '100%'}}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Paper>
              <Paper
                variant="outlined"
                style={{
                  backgroundColor: 'white',
                  padding: 10,
                  borderRadius: 10,
                  marginTop: 10,
                }}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="liveZoomURL"
                  render={({onChange, onBlur, value}) => (
                    <TextField
                      id="standard-error-helper-text"
                      label="Live Zoom URL"
                      value={value}
                      placeholder="Masukkan Invitation Link Zoom disini"
                      onChange={(e) => {
                        const val = _.get(e, 'target.value', '');
                        onChange(val);
                      }}
                      style={{width: '100%'}}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Paper>
              <Paper
                variant="outlined"
                style={{
                  backgroundColor: 'white',
                  padding: 10,
                  borderRadius: 10,
                  marginTop: 10,
                }}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="liveYoutubeURL"
                  render={({onChange, onBlur, value}) => (
                    <TextField
                      id="standard-error-helper-text"
                      label="Youtube LIVE URL"
                      value={value}
                      placeholder="Masukkan Link LIVE Youtube disini"
                      onChange={(e) => {
                        const val = _.get(e, 'target.value', '');
                        onChange(val);
                      }}
                      style={{width: '100%'}}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Paper>
              <div
                style={{
                  border: '1px solid #3333',
                  backgroundColor: 'white',
                  // minHeight: 400,
                  padding: 10,
                  borderRadius: 10,
                  marginTop: 10,
                }}>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="description"
                  render={({onChange, onBlur, value}) => (
                    <>
                      <label
                        style={{
                          color: 'rgba(0, 0, 0, 0.54)',
                          fontSize: 12,
                        }}>
                        Course Description
                      </label>
                      <TextareaAutosize
                        value={value}
                        style={{
                          width: '100%',
                          border: 'none',
                        }}
                        onChange={onChange}
                        rowsMin={10}
                        placeholder="Tulis dekripsi kelas di sini"
                      />
                    </>
                  )}
                />
              </div>
            </Grid>
            <Grid item md={3}>
              <Paper
                variant="outlined"
                style={{padding: 10, borderRadius: 10}}>
                <div>
                  <Controller
                    control={control}
                    defaultValue={'draft'}
                    name="status"
                    render={({onChange, onBlur, value, name}) => (
                      <FormControl style={{width: '100%'}}>
                        <InputLabel htmlFor="status">
                          Status
                        </InputLabel>
                        <Select
                          native
                          style={{width: '100%'}}
                          value={value}
                          onChange={onChange}
                          input={
                            <Input
                              id="status"
                              style={{width: '100%'}}
                            />
                          }>
                          {STATUSES.map((status, index) => (
                            <option
                              key={status.value}
                              value={status.value}>
                              {status.label}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
                <br />
                {watch('isBundled') && (
                  <>
                    <div>
                      <Controller
                        control={control}
                        defaultValue={''}
                        name="parentId"
                        render={({onChange, onBlur, value, name}) => (
                          <FormControl style={{width: '100%'}}>
                            <InputLabel htmlFor="status">
                              Parent Course
                            </InputLabel>
                            <Select
                              native
                              style={{width: '100%'}}
                              value={value}
                              onChange={onChange}
                              input={
                                <Input
                                  id="type"
                                  style={{width: '100%'}}
                                />
                              }>
                              {(bundledCoursesList || []).map(
                                (courses, index) => (
                                  <option
                                    key={courses.courseId}
                                    value={courses.courseId}>
                                    {courses.title}
                                  </option>
                                ),
                              )}
                              <option value={''}></option>
                            </Select>
                          </FormControl>
                        )}
                      />
                    </div>
                    <br />
                    <div>
                      <Controller
                        control={control}
                        defaultValue={''}
                        name="order"
                        render={({onChange, onBlur, value, name}) => (
                          <FormControl style={{width: '100%'}}>
                            {/* <InputLabel htmlFor="status">
                              Course Order
                            </InputLabel> */}
                            <TextField
                              id="standard-error-helper-text"
                              label="Course Order"
                              value={value}
                              type="number"
                              // placeholder="Nomor Seri Kelas"
                              onChange={(e) => {
                                const val = _.get(
                                  e,
                                  'target.value',
                                  '',
                                );
                                onChange(val);
                              }}
                              style={{width: '100%'}}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </FormControl>
                        )}
                      />
                    </div>
                    <br />
                  </>
                )}

                <div>
                  <Controller
                    control={control}
                    defaultValue={moment().valueOf()}
                    name="startAt"
                    render={({onChange, onBlur, value, name}) => (
                      <DateTimePicker
                        autoOk
                        ampm={false}
                        // disableFuture
                        format="DD/MM/yyyy HH:mm"
                        style={{width: '100%'}}
                        value={moment(value)}
                        onChange={(val) => onChange(val.valueOf())}
                        label="Start At"
                      />
                    )}
                  />
                </div>
                <br />
                <div>
                  <Controller
                    control={control}
                    defaultValue={_.get(categories, '[0].key', '')}
                    name="categoryId"
                    render={({onChange, onBlur, value, name}) => (
                      <FormControl style={{width: '100%'}}>
                        <InputLabel htmlFor="category">
                          Category
                        </InputLabel>
                        <Select
                          native
                          style={{width: '100%'}}
                          value={value}
                          onChange={onChange}
                          input={
                            <Input
                              id="category"
                              style={{width: '100%'}}
                            />
                          }>
                          {(categories || []).map(
                            (category, index) => (
                              <option
                                key={category.key}
                                value={category.key}>
                                {category.label}
                              </option>
                            ),
                          )}
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
              </Paper>
              <br />
              <Paper
                variant="outlined"
                style={{padding: 10, borderRadius: 10}}>
                <div>Cover Image</div>
                <br />

                <Controller
                  control={control}
                  defaultValue={''}
                  name="coverImageURL"
                  render={({onChange, onBlur, value}) => {
                    if (value)
                      return (
                        <img src={value} style={{width: '100%'}} />
                      );
                    else return <></>;
                  }}
                />

                {/* <IKContext
                publicKey={publicKey}
                urlEndpoint={urlEndpoint}
                authenticationEndpoint={`${functionUrl}/auth/upload-auth`}>
                <IKUpload
                  fileName="course.jpg"
                  // tags={['sample-tag1', 'sample-tag2']}
                  ref={uploadBtnRef}
                  isPrivateFile={false}
                  useUniqueFileName={true}
                  responseFields={['tags']}
                  folder={'/PALS'}
                  onError={onError}
                  onSuccess={onSuccess}
                />
              </IKContext> */}
                {/* <Button
                onClick={() => {
                  // eslint-disable-next-line no-undef
                  const uploadWidget = myWidget;
                  if (uploadWidget && _.isFunction(uploadWidget.open))
                    uploadWidget.open();
                }}>
                Upload now
              </Button> */}
                <input
                  type="file"
                  onChange={handleFileChange}
                  name="fileToUpload"
                  id="fileToUpload"
                />
              </Paper>
            </Grid>
          </Grid>
        </FormProvider>
      )}
    </MuiPickersUtilsProvider>
  );
}
