import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Grid,
  makeStyles,
  Container,
  Button,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import MyTable from 'components/MyTable';
import axios from 'axios';
import {
  loadArticles,
  deleteArticle,
  restoreArticle,
} from 'services/articles';
import moment from 'moment';
import _ from 'lodash';
import SearchInput from 'components/SearchInput';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import Swal from 'sweetalert2';

export default function ArticlesPage() {
  let articleData = useSelector((state) =>
    Object.entries(
      _.get(state, 'articles.data', {}),
    ).map(([key, itemData]) => ({key, ...itemData})),
  );
  const dataLoading = useSelector((state) =>
    _.get(state, 'articles.loading', false),
  );
  const [editorVisibel, setEditorVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const loadArticles = () => {
    dispatch({
      type: 'articles/LOAD_DATA',
      payload: {
        status: 'all',
      },
    });
  };
  useEffect(() => {
    loadArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = useState(false);

  const handleDelete = (articleId, isPermanent) => {
    Swal.fire({
      html: 'Loading...',
      didOpen: () => {
        Swal.showLoading();
      },
    });
    deleteArticle(articleId, isPermanent).then((res) => {
      console.log(res);
      if (res.isSuccess) {
        Swal.fire('Berhasil', 'Artikel Berhasil dihapus', 'success');
        loadArticles();
      }
    });
  };
  const handleRestore = (articleId) => {
    Swal.fire({
      html: 'Loading...',
      didOpen: () => {
        Swal.showLoading();
      },
    });
    restoreArticle(articleId).then((res) => {
      setLoading(false);
      console.log(res);
      if (res.isSuccess) {
        Swal.fire(
          'Berhasil',
          'Artikel Berhasil dikembalikan',
          'success',
        );
        loadArticles();
      }
    });
  };

  const columns = [
    {
      dataKey: 'no',
      label: 'No.',
      maxWidth: 40,
      align: 'center',
      cellRender: (cellData, rowData, rowIndex) => rowIndex + 1,
    },
    {dataKey: 'title', label: 'Title', minWidth: 170},
    {
      dataKey: 'authorName',
      label: 'Author',
      align: 'center',
      minWidth: 100,
      cellRender: (cellData, rowData, rowIndex) =>
        cellData || 'Anonym',
    },
    {
      dataKey: 'publishedAt',
      label: 'Published At',
      minWidth: 100,
      align: 'center',
      cellRender: (cellData, rowData, rowIndex) => (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <div>{moment(cellData).format('DD/MM/YYYY')}</div>
          <div>{moment(cellData).format('LT')}</div>
        </div>
      ),
    },

    {
      dataKey: 'status',
      label: 'Status',
      minWidth: 100,
      align: 'center',
      cellRender: (cellData, rowData, rowIndex) => (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <span className="badge badge-primary">{cellData}</span>
        </div>
      ),
    },
    {
      dataKey: 'categoryLabel',
      label: 'Category',
      minWidth: 150,
      align: 'center',
      cellRender: (cellData, rowData, rowIndex) => (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <div>{cellData}</div>
        </div>
      ),
    },
    {
      dataKey: 'actions',
      label: 'Actions',
      minWidth: 150,
      align: 'center',
      format: (value) => value.toFixed(2),
      cellRender: (cellData, rowData, rowIndex) => (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              history.push(
                `/dashboard/articles/${rowData.articleId}`,
              );
            }}
            color="primary"
            style={{fontSize: 9, padding: 5}}>
            Edit
          </Button>

          {!Boolean(_.get(rowData, 'deletedAt', 0)) ? (
            <Grid item>
              <Tooltip title="Delete " arrow>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    Swal.fire({
                      title: 'yakin nih?',
                      text: 'Artikel akan dihapus?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, delete it!',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleDelete(rowData.articleId);
                      }
                    });
                  }}
                  style={{color: 'red'}}
                  size="small">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : (
            <Grid item>
              <Tooltip title="Restore " arrow>
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  onClick={() => handleRestore(rowData.articleId)}
                  // style={{color: 'red'}}
                  size="small">
                  <RestoreIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Hard Delete" arrow>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    Swal.fire({
                      title: 'yakin nih?',
                      text: 'Artikel akan dihapus?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, delete it!',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleDelete(rowData.articleId, true);
                      }
                    });
                  }}
                  style={{color: 'red'}}
                  size="small">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </div>
      ),
    },
  ];
  const [filterActive, setFilterActive] = useState('all');

  if (filterActive === 'all')
    articleData = articleData.filter((item) => !item.deletedAt);
  else articleData = articleData.filter((item) => item.deletedAt);

  return (
    <>
      <div>
        <Grid container justify="space-between" className="mb-1">
          <Grid item md={6} sm={12}>
            <Grid container spacing={1}>
              <Grid item>
                <h2>Articles</h2>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() =>
                    history.push('/dashboard/articles/create')
                  }
                  size="small"
                  color="secondary"
                  style={
                    {
                      // backgroundColor: '#fbad18',
                      // height: 30,
                      // padding: 10,
                    }
                  }>
                  Create New
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} sm={12}>
            <Grid container justify="flex-end">
              <Grid item>
                <SearchInput onSelectedFilter={setFilterActive} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <MyTable
          rows={articleData}
          columns={columns}
          loading={dataLoading}
        />
      </div>
    </>
  );
}
