import React, {useState, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Container,
  makeStyles,
  Button,
  Grid,
  Divider,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import renderHTML from 'react-render-html';
import moment from 'moment';
import _ from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';
import CustomTopNav from 'components/layout/TopNav/CutomTopNav';
import {loadPublishedArticles} from 'services/articles';
import {Pagination} from '@material-ui/lab';

const useStyle = makeStyles((theme) => ({
  root: {
    color: 'white',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function Articles() {
  const classes = useStyle();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [activePage, setActivePage] = useState(1); //start from 1
  const [maxPageNumber, setMaxPageNumber] = useState(0); //start from 0
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [articles, setArticles] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    loadPublishedArticles({page: activePage - 1}).then((res) => {
      if (res.isSuccess) {
        setArticles(_.get(res, 'data.data', []));
        setMaxPageNumber(_.get(res, 'data.maxPageNumber', 0));
      }
      setIsLoading(false);
    });
  }, [activePage]);
  const renderSkeleton = () =>
    [1, 2, 3, 4, 5, 6].map((item, index) => (
      <Grid item key={index} xs={12}>
        <Grid container spacing={3} justify="center">
          <Grid
            item
            md={4}
            xs={12}
            style={{
              textAlign: 'center',
              width: '100%',
              overflow: 'hidden',
              padding: 20,
            }}>
            <Skeleton variant="rect" width="100%">
              <div style={{paddingTop: '90%'}} />
            </Skeleton>
          </Grid>
          <Grid item md={8} xs={12}>
            <h5 style={{color: 'white', marginTop: 20}}>
              <Skeleton width="100%" />
            </h5>
            <h3
              style={{
                color: 'white',
                marginTop: 15,
                marginBottom: 25,
                fontSize: 26,
              }}>
              <Skeleton width="100%" />
            </h3>
            <div className="abstract-text">
              {' '}
              <Skeleton width="100%">
                <div style={{paddingTop: '30%'}} />
              </Skeleton>
            </div>
            <br />
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Skeleton width="100%" />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    ));
  const [mode, setMode] = useState('serial');
  return (
    <>
      {isMobile && (
        <CustomTopNav title="Cerita" showBack={false} showAuth />
      )}
      <Container className={classes.root}>
        <div
          style={{
            marginTop: !isMobile ? 80 : 30,
            marginBottom: 30,
            color: 'white',
            fontWeight: 'bold',
            fontSize: !isMobile ? 60 : 30,
            textAlign: 'center',
            display: !isMobile ? 'block' : 'none',
          }}>
          Cerita
        </div>

        <Divider style={{display: !isMobile ? 'block' : 'none'}} />
        <Container style={{marginTop: 50, paddingBottom: 80}}>
          <Grid
            container
            spacing={5}
            alignItems="center"
            justify="center"
            style={{marginBottom: 20}}>
            {isLoading
              ? renderSkeleton()
              : (articles || []).map((item, index) => (
                  <Grid item key={index} xs={12}>
                    <Grid container spacing={3} justify="center">
                      <Grid
                        item
                        md={4}
                        style={{
                          textAlign: 'center',
                          width: '100%',
                          maxHeight: '',
                          overflow: 'hidden',
                          padding: 20,
                        }}>
                        <img
                          src={item.coverImageURL}
                          style={{width: '100%', objectFit: 'cover'}}
                        />
                      </Grid>
                      <Grid item md={8}>
                        <div className="badge badge-warning mt-3">
                          <h5
                            style={{marginBottom: 0, color: '#333'}}>
                            {item.categoryLabel}
                          </h5>
                        </div>
                        <h3
                          style={{
                            color: 'white',
                            marginTop: 15,
                            marginBottom: 25,
                            fontSize: 26,
                          }}>
                          {item.title}
                        </h3>
                        <div
                          className="abstract-text"
                          style={{
                            fontSize: isMobile ? 14 : 16,
                          }}>
                          {' '}
                          {renderHTML(item.caption || 'no caption')}
                        </div>
                        <br />
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() =>
                            history.push(`/articles/${item.slug}`)
                          }>
                          Read more...
                        </Button>
                        <div>
                          <Divider
                            variant="middle"
                            style={{
                              backgroundColor: 'white',
                              margin: '16px 0',
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}>
                            <div>
                              By:{' '}
                              {_.get(item, 'authorName', 'Anonym')}
                            </div>
                            <div>
                              {moment(item.publishedAt).format('ll')}
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
          </Grid>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Pagination
              count={maxPageNumber + 1}
              onChange={(e, val) => setActivePage(val)}
              size="small"
              color="secondary"
              itemProp={{style: {color: 'white'}}}></Pagination>
          </div>
        </Container>
      </Container>
    </>
  );
}
