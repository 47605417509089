import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import {Button, TextField, MenuItem} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import {ToggleButtonGroup, ToggleButton} from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    // width: 400,
    maxWidth: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const defaultStatuses = [
  {value: 'all', label: 'All'},
  // {value: 'published', label: 'Published'},
  {value: 'trash', label: 'Trash'},
];
export default function SearchInput({
  onSelectedFilter = () => {},
  statuses = defaultStatuses,
  onSearch = () => {},
}) {
  const classes = useStyles();
  const [status, setStatus] = useState(
    (statuses[0] && statuses[0].value) || '',
  );

  const handleChange = (event) => {
    const data = event.target.value;
    // console.log(data);
    setStatus(data);
    onSelectedFilter(data);
  };
  const [searchText, setSearchText] = useState('');
  return (
    <Paper
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSearch(searchText);
      }}
      className={classes.root}>
      <IconButton className={classes.iconButton} aria-label="menu">
        <FilterListIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder="Search Here"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSearch(searchText);
          }
        }}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <IconButton
        // type="submit"
        onClick={() => onSearch(searchText)}
        className={classes.iconButton}>
        <SearchIcon />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      {statuses && statuses.length > 0 && (
        <TextField
          id="standard-select-currency"
          select
          variant="outlined"
          style={{
            width: 100,
            paddingRight: 5,
            fontSize: 12,
            border: 'none',
            height: 30,
          }}
          InputProps={{style: {fontSize: 12, padding: 0, height: 30}}}
          size="small"
          value={status}
          onChange={handleChange}>
          {statuses.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              style={{fontSize: 12}}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    </Paper>
  );
}
