import actions from './actions';

const initialState = {
  maintenance: false,
};

const defaultObj = {};
export default function configReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return {...state, ...action.payload};
    }
    case actions.CLEAR_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
