import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Controller, FormProvider} from 'react-hook-form';
import {createCategory, updateCategory} from 'services/categories';
import Swal from 'sweetalert2';

const categoryScheme = yup
  .object()
  .noUnknown(true)
  .required()
  .shape({
    label: yup
      .string()
      .typeError('Format label salah')
      .required('label kategori harus diisi')
      .max(200),
  });

export default function FormDialog({
  visible = false,
  onDismiss = () => {},
  onSubmitted = () => {},
  categoryData = null,
}) {
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(categoryScheme),
  });
  const {
    control,
    watch,
    getValues,
    handleSubmit,
    errors,
    register,
    reset,
    setValue,
  } = methods;

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (visible) reset(categoryData || {});
  }, [categoryData, visible, reset]);
  const handleClose = () => {
    onDismiss();
  };
  const onSubmit = (data) => {
    setIsLoading(true);
    console.log(data);
    if (!Boolean(categoryData && categoryData.key)) {
      createCategory(data)
        .then((res) => {
          setIsLoading(false);
          if (res.isSuccess) {
            console.log('berhasil');
            onSubmitted();
            Swal.fire(
              'Yeay',
              'Berhasil menambah kategori',
              'success',
            );
          } else console.log('gagal');
        })
        .catch((error) => {
          console.error(error);
          Swal.fire('Upps...', 'Gagal buat kategory', 'error');
          setIsLoading(false);
        });
    } else {
      updateCategory(data, categoryData.key)
        .then((res) => {
          setIsLoading(false);
          if (res.isSuccess) {
            console.log('berhasil');
            onSubmitted();
            Swal.fire(
              'Yeay',
              'Berhasil mengubah kategori',
              'success',
            );
          } else console.log('gagal');
        })
        .catch((error) => {
          console.error(error);
          Swal.fire('Upps...', 'Gagal update kategory', 'error');
          setIsLoading(false);
        });
    }
  };
  return (
    <Dialog
      open={visible}
      onClose={onDismiss}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">New Category</DialogTitle>
      <DialogContent>
        <Controller
          control={control}
          defaultValue=""
          name="label"
          render={({onChange, onBlur, value}) => (
            <TextField
              autoFocus
              value={value}
              onChange={onChange}
              margin="dense"
              label="Label"
              type="text"
              fullWidth
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isLoading}
          onClick={handleClose}
          color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
          color="primary">
          {isLoading ? (
            <i className="fas fa-spinner fa-pulse"></i>
          ) : (
            'Simpan'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
