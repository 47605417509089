import React, {useState, useEffect} from 'react';
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Container,
  makeStyles,
  Button,
  Grid,
  Divider,
  Checkbox,
  Input,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import _ from 'lodash';
import Swal from 'sweetalert2';
import {
  signInWithFacebook,
  signInWithGoogle,
  signUpWithEmailAndPassword,
  loginWithToken,
  sendEmailVerification,
} from 'services/user';
import CustomTopNav from 'components/layout/TopNav/CutomTopNav';
import * as yup from 'yup';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {firebaseAuth} from 'services/firebase';

const registrationSchema = yup.object().shape({
  firstName: yup
    .string()
    .typeError('penulisan nama salah')
    .required('nama harus diisi'),
  lastName: yup
    .string()
    .typeError('penulisan nama salah')
    .default(''),
  phone: yup
    .string()
    .min(10, 'minimal nomor telepon 10 karakter')
    .max(14, 'maskimal nomor telepon 14 karakter')
    .typeError('penulisan nomor telepon salah')
    .required('nomor handphone harus diisi')
    .test(
      'is-phone-valid',
      'phone tidak valid, gunakan kode negara misal +6281234123412',
      function (value) {
        // pastikan isinya angka semua
        return /^\+?[1-9]\d{1,14}$/.test(value);
      },
    ),
  email: yup
    .string()
    .typeError('penulisan email salah')
    .email('format email salah')
    .required('email harus diisi')
    .transform(function (value, originalvalue) {
      if (this.isType(value)) return value.trim();
      return value;
    })
    .test('is-email-valid', 'email tidak valid', function (value) {
      // tidak boleh mengandung .apotekdigital.com
      return !`${value}`.includes('.apotekdigital.com');
    }),
  password: yup
    .string()
    .typeError('penulisan password salah')
    .min(6, 'minimal password 6 karakter')
    .required('password harus diisi'),
  confirmPassword: yup
    .string()
    .typeError('penulisan password salah')
    .min(6, 'minimal password 6 karakter')
    .required('password harus dikonfirmasi')
    .test(
      'test_password_equal',
      'password tidak sama',
      function (value) {
        return this.parent.password === value;
      },
    ),
});

const useStyle = makeStyles((theme) => ({
  root: {
    color: 'white',
    textAlign: 'center',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function SignUp() {
  const classes = useStyle();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const isLoggedIn = useSelector(
    (state) => _.get(state, 'user.authorized'),
    false,
  );
  const isVerified = useSelector(
    (state) => _.get(state, 'user.emailVerified'),
    false,
  );

  const formRegistration = useForm({
    resolver: yupResolver(registrationSchema),
  });

  const handleLogin = (provider) => {
    dispatch({
      type: 'user/LOGIN',
      payload: {
        email,
        password,
        provider,
      },
    });
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setError,
  } = useForm();
  const onSubmit = (data) => {
    let user;
    Swal.fire({
      title: 'Sedang membuat akun!',
      didOpen: () => {
        Swal.showLoading();
      },
    });
    signUpWithEmailAndPassword(data)
      .then(({isSuccess, data, error}) => {
        if (isSuccess) {
          Swal.fire('Yeay', 'Berhasil daftar', 'success');
          setIsRegistered(true);
          dispatch({
            type: 'user/LOGIN_WITH_TOKEN',
            payload: {
              token: data.token,
            },
          });
        } else {
          Swal.fire(
            'Upps.. Gagal Login',
            error.message || '',
            'error',
          );
        }
      })
      .catch((e) => {
        console.error(e);
        Swal.fire('Upps.. Gagal Login', e.message || '', 'error');
      });
  };
  const onError = (err) => {
    Swal.fire(
      'Terjadi Kesalahan',
      Object.values(err)[0].message || '',
      'error',
    );
    console.log(err);
  };
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [redirectToReferrer, setRedirectToReferrer] = React.useState(
    false,
  );
  React.useEffect(() => {
    if (isLoggedIn && isVerified) {
      setRedirectToReferrer(true);
    }
    if (isLoggedIn && !isVerified && isRegistered) {
      sendEmailVerification().then(() => {
        history.push('/email-verify');
      });
    }
  }, [history, isLoggedIn, isRegistered, isVerified]);

  let {from} = location.state || {from: {pathname: '/'}};
  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }
  return (
    <>
      {isMobile && (
        <CustomTopNav
          title="Sign Up"
          showBack
          showAuth={false}
          backURL={(location.state && location.state.from) || '/'}
        />
      )}
      <Container className={classes.root}>
        <Grid container justify="center" style={{marginTop: 50}}>
          <Grid item md={4} xs={10}>
            <div
              style={{
                marginTop: 20,
                marginBottom: 30,
                color: 'white',
                fontWeight: 600,
                fontSize: 36,
                textAlign: 'center',
                display: !isMobile ? 'block' : 'none',
              }}>
              Sign Up
            </div>

            <Grid container style={{width: '100%'}}>
              {/* <Grid item xs={6}>
                <Button
                  onClick={() => handleLogin('facebook')}
                  variant="contained"
                  startIcon={<i className="fa fa-facebook" />}
                  style={{width: '100%', marginRight: 5}}>
                  Facebook
                </Button>
              </Grid> */}
              <Grid item xs={12}>
                <Button
                  onClick={() => handleLogin('google')}
                  variant="contained"
                  startIcon={<i className="fa fa-google" />}
                  style={{width: '100%', marginLeft: 5}}>
                  Google
                </Button>
              </Grid>
            </Grid>
            <br />
            <hr style={{backgroundColor: 'white'}} />

            <div
              style={{
                marginTop: -18,
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                width: '100%',
                // borderBottom: 'solid 1px',
              }}>
              <div
                style={{
                  backgroundColor: '#2E4462',
                  width: 40,
                  marginTop: -5,
                }}>
                or
              </div>
            </div>
            <br />

            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container justify="center" spacing={2}>
                <Grid
                  item
                  xs={6}
                  style={{width: '100%', textAlign: 'left'}}>
                  <Controller
                    name="firstName"
                    control={formRegistration.control}
                    defaultValue=""
                    render={({onChange, value}) => (
                      <>
                        <label style={{marginBottom: 3}}>
                          First Name
                        </label>
                        <input
                          className="form-control"
                          name="firstname"
                          value={value}
                          onChange={onChange}
                          size="large"
                          placeholder="First Name"
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{width: '100%', textAlign: 'left'}}>
                  <Controller
                    name="lastName"
                    control={formRegistration.control}
                    defaultValue=""
                    render={({onChange, value}) => (
                      <>
                        <label style={{marginBottom: 3}}>
                          Last Name
                        </label>
                        <input
                          className="form-control"
                          name="lastName"
                          value={value}
                          onChange={onChange}
                          size="large"
                          placeholder="Last Name"
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{width: '100%', textAlign: 'left'}}>
                  <Controller
                    name="email"
                    control={formRegistration.control}
                    defaultValue=""
                    render={({onChange, value}) => (
                      <>
                        <label style={{marginBottom: 3}}>Email</label>
                        <input
                          className="form-control"
                          name="email"
                          value={value}
                          onChange={onChange}
                          size="large"
                          placeholder="Email"
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{width: '100%', textAlign: 'left'}}>
                  <Controller
                    name="phone"
                    control={formRegistration.control}
                    defaultValue=""
                    render={({onChange, value}) => (
                      <>
                        <label style={{marginBottom: 3}}>
                          Phone Number
                        </label>
                        <input
                          className="form-control"
                          name="phone"
                          value={value}
                          onChange={(e) => {
                            let val = e.target.value;
                            if (val.slice(0, 2) === '08')
                              val = '+628' + val.slice(2, 18);
                            onChange(val);
                          }}
                          size="large"
                          placeholder="Phone Number"
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{width: '100%', textAlign: 'left'}}>
                  <Controller
                    name="password"
                    control={formRegistration.control}
                    defaultValue=""
                    render={({onChange, value}) => (
                      <>
                        <label style={{marginBottom: 3}}>
                          Password
                        </label>
                        <input
                          className="form-control"
                          name="password"
                          value={value}
                          type="password"
                          onChange={onChange}
                          size="large"
                          placeholder="Password"
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{width: '100%', textAlign: 'left'}}>
                  <Controller
                    name="confirmPassword"
                    control={formRegistration.control}
                    defaultValue=""
                    render={({onChange, value}) => (
                      <>
                        <label style={{marginBottom: 3}}>
                          Confirm Password
                        </label>
                        <input
                          className="form-control"
                          name="confirmPassword"
                          value={value}
                          onChange={onChange}
                          type="password"
                          size="large"
                          placeholder="Confirm Password"
                        />
                      </>
                    )}
                  />
                </Grid>
              </Grid>

              <br />
              <div style={{marginBottom: '50px'}}>
                By signing up, you agree to the Term of Service
              </div>
              <Button
                color="secondary"
                variant="contained"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  formRegistration.handleSubmit(onSubmit, onError)();
                }}
                style={{width: '100%'}}>
                Create an account
              </Button>
            </form>
            <br />
            <Link style={{color: 'white'}} to="/signin">
              Already have an account
            </Link>
            <br />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
