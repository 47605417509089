import React, {useState} from 'react';
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Container,
  makeStyles,
  Button,
  Grid,
  Divider,
  useTheme,
  useMediaQuery,
  Typography,
  IconButton,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {Pagination, PaginationItem} from '@material-ui/lab';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import CustomTopNav from 'components/layout/TopNav/CutomTopNav';

const useStyle = makeStyles((theme) => ({
  root: {
    color: 'white',
    textAlign: 'center',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const CrewsData = [
  {
    role: 'Pembimbing',
    name: 'Maimon Herawati',
    ig: 'maimonherawati1',
    photoURL: '/images/crews/Maimon H.webp',
  },
  {
    role: 'Pimpinan Proyek',
    name: 'Farah Qoonita',
    ig: 'qooonit',
    photoURL: '/images/crews/Farah Q.webp',
  },
  {
    role: 'Sekretaris dan HRD',
    name: 'Shofuro Fathinatu S.',
    ig: 'furoskii',
    photoURL: '/images/crews/Shofuro.webp',
  },
  {
    role: 'Riset',
    name: 'Dina Aqmarina Yanuary',
    ig: '',
    photoURL: '/images/crews/Dina Aq.webp',
  },
  {
    role: 'IT',
    name: 'Mentary Sonaya',
    ig: 'mentaryso',
    photoURL: '/images/crews/Riri.webp',
  },
  {
    role: 'Pemimpin Redaksi',
    name: 'Dian Chairunisa',
    ig: 'dianchsa',
    photoURL: '/images/crews/Dian C.webp',
  },
  {
    role: 'PJ Video',
    name: 'Dewi Rifani',
    ig: 'dewirafani',
    photoURL: '/images/crews/Dewi R.webp',
  },
  {
    role: 'PJ Motion Graphics',
    name: 'Nurul Jamilatun',
    ig: 'oohjejey',
    photoURL: '/images/crews/Jeje.webp',
  },
  {
    role: 'PJ Grafis',
    name: 'Rifa Fauziyah',
    ig: 'rifafam',
    photoURL: '/images/crews/Rifa F.webp',
  },
  {
    role: 'Content Writer',
    name: 'Andi Muhammad Haekal',
    ig: 'haekalandi',
    photoURL: '/images/crews/Andi.webp',
  },
  {
    role: 'Content Writer',
    name: 'Vania Rahman',
    ig: 'uni.aaa',
    photoURL: '/images/crews/Vania.webp',
  },
  {
    role: 'Content Writer',
    name: 'Fairuz Fildzah',
    ig: 'ffildzah18',
    photoURL: '/images/crews/Fairuz.webp',
  },
  {
    role: 'Content Writer',
    name: 'Midrar Yusya',
    ig: 'midraryusya',
    photoURL: '/images/crews/Midrar Y.webp',
  },
  {
    role: 'Marketing dan fundraiser',
    name: 'Khalida Yusria',
    ig: 'khalidayusria',
    photoURL: '/images/crews/Khal.webp',
  },
  {
    role: 'Partner Development',
    name: 'Herlin Herliansyah',
    ig: 'herlinherliansah',
    photoURL: '/images/crews/Herlin H.webp',
  },
  {
    role: 'Partner Development',
    name: 'Alvi Rahmawati',
    ig: 'alvidaple',
    photoURL: '/images/crews/Alvi.webp',
  },
];

export default function About() {
  const classes = useStyle();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      {isMobile && (
        <CustomTopNav
          title="About Us"
          showBack
          shadow={false}
          // backURL="/"
          // showAuth={false}
        />
      )}
      <Grid
        container
        justify="center"
        style={{backgroundColor: 'white'}}>
        <Grid
          item
          xs={9}
          md={5}
          xl={3}
          style={{
            textAlign: 'center',
            marginTop: 60,
            marginBottom: 40,
          }}>
          <Typography variant="h1" color="primary">
            About Palestine Learning Source (PALS)
          </Typography>
          <br />
          <br />
          <Typography variant="body1" color="primary">
            Tim Solidarity of Muslim for Al-Quds Retaken (SMART) 171
            menyampaikan bantuan musim dingin pada pengungsi Suriah.
          </Typography>
          <br />
          <br />
          <div>
            <Button
              onClick={() => {
                window.open(
                  'https://smart171.org/campaign/bantu-wujudkan-pals-palestine-learning-source/',
                  '_blank',
                );
              }}
              variant="contained"
              color="primary"
              size="small">
              Donate
            </Button>
          </div>
        </Grid>
      </Grid>
      <Container className={classes.root}>
        <Grid
          container
          justify="center"
          style={{marginTop: 50, textAlign: 'center'}}>
          <Grid item md={9}>
            <div
              style={{
                marginBottom: 20,
                color: 'white',
                fontWeight: 600,
                fontSize: 36,
              }}>
              Our Awesome Crew
            </div>
            <div
              style={{
                color: 'white',
                fontSize: 16,
                marginBottom: 50,
              }}>
              Tim Solidarity of Muslim for Al-Quds Retaken (SMART) 171
            </div>
            <br />
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          style={{
            marginTop: 50,
            textAlign: 'left',
            marginBottom: 20,
          }}>
          <Grid item md={12}>
            <Grid container spacing={5}>
              {(CrewsData || []).map((itemData, index) => (
                <Grid key={index} item md={4} xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <img
                        src={itemData.photoURL || ''}
                        width="100%"
                        style={{borderRadius: 20}}
                      />
                    </Grid>
                    <Grid item xs={8} style={{paddingTop: 20}}>
                      <div>{itemData.role}</div>
                      <div style={{fontSize: 22, marginBottom: 10}}>
                        {itemData.name}
                      </div>

                      <div style={{marginTop: 20}}>
                        <IconButton
                          color="white"
                          component="span"
                          onClick={() => {
                            window.open(
                              'https://instagram.com/' + itemData.ig,
                              '_blank',
                            );
                          }}>
                          <InstagramIcon />
                        </IconButton>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
