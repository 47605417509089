import React, {useState, useEffect} from 'react';
import {
  Button,
  Grid,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Menu,
} from '@material-ui/core';
import {useHistory, useLocation} from 'react-router';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import {useSelector, useDispatch} from 'react-redux';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import DashboardIcon from '@material-ui/icons/Dashboard';
import {firebaseApp, firebaseAuth} from 'services/firebase';
import Swal from 'sweetalert2';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreVertTwoToneIcon from '@material-ui/icons/MoreVertTwoTone';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  mainMenu: {
    color: 'white',
    margin: 10,
    padding: 10,
    borderRadius: 5,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: '#fffa',
    },
  },
  activeMenu: {
    color: theme.palette.primary.main,
    backgroundColor: '#fffa',
    margin: 10,
    padding: 10,
    borderRadius: 5,
  },
}));
export default function TopNav({drawerTrigger = () => {}}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoggegIn = useSelector(
    (state) => _.get(state, 'user.authorized'),
    false,
  );
  const isAdmin = useSelector(
    (state) => _.get(state, 'user.isAdmin'),
    false,
  );
  const isVerified = useSelector(
    (state) => _.get(state, 'user.emailVerified'),
    false,
  );
  const location = useLocation();
  const [user, setUser] = React.useState(null);

  const classes = useStyles();

  const anchorRef = React.useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElMore, setAnchorElMore] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuMore = (event) => {
    setAnchorElMore(event.currentTarget);
  };
  const openMore = Boolean(anchorElMore);

  const handleCloseMore = () => {
    setAnchorElMore(null);
  };

  return (
    <>
      <AppBar position="fixed">
        <Grid container justify="center">
          <Grid item md={11} lg={10} xl={8} sm={12} xs={12}>
            <Toolbar>
              {/* <IconButton
                edge="start"
                onClick={() => drawerTrigger()}
                className={classes.sectionMobile}
                color="inherit"
                aria-label="menu">
                <MenuOpenIcon />
              </IconButton> */}
              <Link
                // href="#"
                // component="button"
                to="/"
                edge="start"
                className={classes.menuButton}
                color="inherit">
                <img src="/images/logo.png" style={{maxHeight: 60}} />
              </Link>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                <Link
                  className={
                    location.pathname === '/courses'
                      ? classes.activeMenu
                      : classes.mainMenu
                  }
                  to="/courses">
                  Ruang Kelas
                </Link>
                <div>
                  <Link
                    className={
                      location.pathname === '/articles'
                        ? classes.activeMenu
                        : classes.mainMenu
                    }
                    ref={anchorRef}
                    to="/articles">
                    Cerita
                  </Link>
                </div>

                <div>
                  <Link
                    className={
                      location.pathname === '/papers'
                        ? classes.activeMenu
                        : classes.mainMenu
                    }
                    to="/papers">
                    Jerat Data
                  </Link>
                </div>

                {/* <Link
                  className={
                    location.pathname === '/videos'
                      ? classes.activeMenu
                      : classes.mainMenu
                  }
                  to="/videos">
                  Video
                </Link> */}
                <Link
                  className={
                    location.pathname === '/toolkits'
                      ? classes.activeMenu
                      : classes.mainMenu
                  }
                  to="/toolkits">
                  Amunisi
                </Link>
              </div>
              {!isLoggegIn && (
                <Button
                  onClick={() => history.push('/signin')}
                  variant="contained"
                  color="secondary">
                  Sign In
                </Button>
              )}
              {isLoggegIn && isAdmin && (
                <IconButton
                  // color="primary"
                  onClick={() => history.push('/dashboard/analytics')}
                  aria-label="Dashboard Admin"
                  component="span">
                  <DashboardIcon />
                </IconButton>
              )}
              {isLoggegIn && (
                <div>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => {
                      history.push('/profile');
                    }}
                    color="inherit">
                    <AccountCircle />
                  </IconButton>
                </div>
              )}
              <div>
                <IconButton
                  className={classes.sectionMobile}
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenuMore}
                  color="inherit">
                  <MoreVertTwoToneIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElMore}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={openMore}
                  onClose={() => {
                    handleCloseMore();
                  }}>
                  <MenuItem
                    onClick={() => {
                      handleCloseMore();
                      history.push('/about');
                    }}>
                    About Us
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseMore();
                      history.push('/contact-us');
                    }}>
                    Contact Us
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseMore();
                      window.open(
                        'https://smart171.org/campaign/bantu-wujudkan-pals-palestine-learning-source/',
                        '_blank',
                      );
                    }}>
                    Donate
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
}
