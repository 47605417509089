import React, {useState, useEffect} from 'react';
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Container,
  makeStyles,
  Button,
  Grid,
  Divider,
  useTheme,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {Pagination, PaginationItem} from '@material-ui/lab';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import CustomTopNav from 'components/layout/TopNav/CutomTopNav';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import {firebaseAuth} from 'services/firebase';
import Swal from 'sweetalert2';
import _ from 'lodash';
import {sendEmailVerification, currentAccount} from 'services/user';

const useStyle = makeStyles((theme) => ({
  root: {
    color: 'white',
    textAlign: 'center',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function EmailVerify() {
  const classes = useStyle();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();

  // const [isVerified, setIsVerified] = useState(false);
  const isLoggegIn = useSelector((state) =>
    _.get(state, 'user.authorized', false),
  );
  const isVerified = useSelector((state) =>
    _.get(state, 'user.emailVerified', false),
  );
  const userEmail = useSelector((state) =>
    _.get(state, 'user.email', ''),
  );
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    if (isLoggegIn && isVerified) {
      setRedirectToReferrer(true);
    } else {
      // history.replace('/');
    }
  }, [history, isLoggegIn, isVerified]);

  const [redirectToReferrer, setRedirectToReferrer] = React.useState(
    false,
  );
  let {from} = location.state || {from: {pathname: '/'}};
  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }
  return (
    <>
      {isMobile && (
        <CustomTopNav
          title="Verifikasi Email"
          showBack
          shadow={false}
          backURL={(location.state && location.state.from) || '/'}
          // backURL="/"
          // showAuth={false}
        />
      )}

      <Container className={classes.root}>
        <Grid
          container
          justify="center"
          style={{marginTop: 50, textAlign: 'left'}}>
          <Grid
            item
            md={6}
            xs={10}
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <div
              style={{
                width: 200,
                height: 200,
                backgroundColor: '#3333',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <MailOutlineIcon
                style={{color: 'white', fontSize: 90}}
              />
            </div>
            <br />
            <br />
            <Typography variant="h2" color="textPrimary">
              Verifikasi Email Anda!
            </Typography>
            <Typography variant="h5" color="textPrimary">
              {userEmail}
            </Typography>
            <br />
            <Typography
              variant="body1"
              color="textPrimary"
              style={{textAlign: 'center'}}>
              Mohon lakukan verifikasi untuk mendapatkan pengalaman
              belajar terbaik bersama kami. Kami telah mengirimkan
              link verifikasi ke email Anda. Silahkan klik tombol/link
              verfikasi pada email yang telah kami kirimkan.
              <br />
              <br />
              Jika sudah diverifikasi silahkan reload halaman ini.
              <br />
              <br />
              Terima Kasih!
            </Typography>
            <br />
            <br />
            <Button
              disabled={!isLoggegIn}
              onClick={sendEmailVerification}
              variant="outlined"
              color="secondary">
              Kirim Ulang
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
