import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Grid,
  makeStyles,
  Container,
  Button,
  IconButton,
  Tooltip,
  Collapse,
  Box,
  Typography,
  Table,
  TableHead,
  Badge,
} from '@material-ui/core';
import MyTable from 'components/MyTable';
import axios from 'axios';
import {deleteCourse, restoreCourse} from 'services/courses';
import moment from 'moment';
import _ from 'lodash';
import SearchInput from 'components/SearchInput';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import Swal from 'sweetalert2';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import GroupIcon from '@material-ui/icons/Group';

export default function CoursesPage() {
  let courseData = useSelector((state) => {
    const objCourses = _.get(state, 'courses.data', {});
    let objGroupedCourses = _.filter(objCourses, {order: 0});
    let childCourses = objCourses;

    // masukin child ke parent
    for (let index = 0; index < childCourses.length; index++) {
      const course = childCourses[index];
      const parentIndex = _.findIndex(
        objGroupedCourses,
        (lcourse) => lcourse.courseId === course.parentId,
      );
      if (parentIndex >= 0) {
        objGroupedCourses[parentIndex] = {
          ...objGroupedCourses[parentIndex],
          childrens: [
            ...(objGroupedCourses[parentIndex].childrens || []),
            course,
          ],
        };
      }
    }
    const allCourses = Object.entries(objGroupedCourses).map(
      ([key, itemData]) => {
        return {key, ...itemData};
      },
    );
    return allCourses;
  });
  const dataLoading = useSelector((state) =>
    _.get(state, 'courses.loading', false),
  );
  const [editorVisibel, setEditorVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const loadAllCourses = () => {
    dispatch({
      type: 'courses/LOAD_DATA',
      payload: {
        status: 'all',
        withTrash: 1,
        size: 200,
      },
    });
  };

  useEffect(() => {
    loadAllCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (loading || dataLoading)
  //     Swal.fire({
  //       html: 'Loading...',
  //       didOpen: () => {
  //         Swal.showLoading();
  //       },
  //     });
  // else Swal.hideLoading();
  // }, [loading, dataLoading]);

  const handleDelete = (courseId, isPermanent) => {
    Swal.fire({
      title: 'yakin nih?',
      text: 'Kategori akan dihapus?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Sedang menghapus kategory',
          // html: 'I will close in <b></b> milliseconds.',
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => Swal.showLoading(),
        });
        setLoading(true);
        deleteCourse(courseId, isPermanent).then((res) => {
          setLoading(false);
          console.log(res);
          if (res.isSuccess) {
            Swal.fire(
              'Berhasil',
              'Artikel Berhasil dihapus',
              'success',
            );
            loadAllCourses();
          }
        });
      }
    });
  };
  const handleRestore = (courseId) => {
    Swal.fire({
      title: 'yakin nih?',
      text: 'Kelas akan dikembalikan?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, restore it!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Sedang menghapus kelas',
          // html: 'I will close in <b></b> milliseconds.',
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => Swal.showLoading(),
        });
        setLoading(true);
        restoreCourse(courseId).then((res) => {
          setLoading(false);
          console.log(res);
          if (res.isSuccess) {
            Swal.fire(
              'Berhasil',
              'kelas Berhasil dikembalikan',
              'success',
            );
            loadAllCourses();
          }
        });
      }
    });
  };

  const [open, setOpen] = React.useState(false);

  const columns = [
    {
      dataKey: 'no',
      label: 'No.',
      maxWidth: 40,
      align: 'center',
      cellRender: (cellData, rowData, rowIndex) => rowIndex + 1,
    },
    {dataKey: 'title', label: 'Title', minWidth: 170},
    {
      dataKey: 'createdBy',
      label: 'Author',
      align: 'center',
      minWidth: 100,
      cellRender: (cellData, rowData, rowIndex) => {
        return _.get(cellData, 'name', '-');
      },
    },
    {
      dataKey: 'publishedAt',
      label: 'Published At',
      minWidth: 100,
      align: 'center',
      cellRender: (cellData, rowData, rowIndex) => (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <div>{moment(cellData).format('DD/MM/YYYY')}</div>
          <div>{moment(cellData).format('LT')}</div>
        </div>
      ),
    },

    {
      dataKey: 'status',
      label: 'Status',
      minWidth: 100,
      align: 'center',
      cellRender: (cellData, rowData, rowIndex) => (
        <div
          className={`badge ${
            cellData === 'published'
              ? 'badge-success'
              : 'badge-secondary'
          }`}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {cellData}
        </div>
      ),
    },
    {
      dataKey: 'isBundled',
      label: 'Jenis',
      minWidth: 150,
      align: 'center',
      cellRender: (cellData, rowData, rowIndex) => (
        <div
          className={`badge ${
            cellData ? 'badge-primary' : 'badge-warning'
          }`}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {cellData
            ? 'Bundled ' + _.get(rowData, 'childrens', []).length
            : ''}
          {cellData ? ' Courses' : 'Single'}
        </div>
      ),
    },
    {
      dataKey: 'actions',
      label: 'Actions',
      minWidth: 150,
      align: 'center',
      format: (value) => value.toFixed(2),
      cellRender: (cellData, rowData, rowIndex) => (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              history.push(`/dashboard/courses/${rowData.courseId}`);
            }}
            color="primary"
            style={{fontSize: 9, padding: 5}}>
            Edit
          </Button>

          {!Boolean(_.get(rowData, 'deletedAt', 0)) ? (
            <Grid item>
              <Tooltip title="Delete " arrow>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDelete(rowData.courseId)}
                  style={{color: 'red'}}
                  size="small">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : (
            <Grid item>
              <Tooltip title="Restore " arrow>
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  onClick={() => handleRestore(rowData.courseId)}
                  // style={{color: 'red'}}
                  size="small">
                  <RestoreIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Hard Delete" arrow>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDelete(rowData.courseId, true)}
                  style={{color: 'red'}}
                  size="small">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          <IconButton
            size="small"
            onClick={() => {
              setSelectedBundled(rowData);
              setBundledExpanded(true);
            }}
            disabled={!rowData.isBundled || rowData.order !== 0}
            color="primary"
            aria-label="childs">
            <CollectionsBookmarkIcon />
          </IconButton>
          {/* <Badge badgeContent={4} color="error">
            <IconButton
              size="small"
              onClick={() => {}}
              // disabled={!rowData.isBundled || rowData.order !== 0}
              color="primary"
              aria-label="childs">
              <GroupIcon />
            </IconButton>
          </Badge> */}
        </div>
      ),
    },
  ];
  const [filterActive, setFilterActive] = useState('all');

  if (filterActive === 'all')
    courseData = courseData.filter((item) => !item.deletedAt);
  else courseData = courseData.filter((item) => item.deletedAt);

  const [bundledExpanded, setBundledExpanded] = useState(false);
  const [selectedBundled, setSelectedBundled] = useState(false);
  // const groupedData = _.groupBy(courseData, 'parentId');
  return (
    <>
      <div>
        <Grid container justify="space-between" className="mb-1">
          <Grid item md={6} sm={12}>
            <Grid container spacing={1}>
              <Grid item>
                <h2>Courses</h2>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() =>
                    history.push('/dashboard/courses/create')
                  }
                  size="small"
                  color="secondary"
                  style={
                    {
                      // backgroundColor: '#fbad18',
                      // height: 30,
                      // padding: 10,
                    }
                  }>
                  Create New
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={6} sm={12}>
            <Grid container justify="flex-end">
              <Grid item>
                <SearchInput onSelectedFilter={setFilterActive} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {bundledExpanded && (
          <h4>
            <IconButton
              // variant="outlined"
              size="small"
              disabled={loading}
              style={{marginRight: 10}}
              color="secondary"
              onClick={() => {
                setSelectedBundled({});
                setBundledExpanded(false);
              }}>
              <i
                className="fa fa-arrow-left"
                style={{fontSize: 20}}
              />
            </IconButton>
            {bundledExpanded
              ? `Serial: ${selectedBundled.title}`
              : ''}
          </h4>
        )}
        <MyTable
          rows={
            bundledExpanded ? selectedBundled.childrens : courseData
          }
          columns={columns}
        />
      </div>
    </>
  );
}
