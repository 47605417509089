import {
  all,
  take,
  takeEvery,
  takeLeading,
  put,
  call,
  fork,
  select,
  cancel,
} from 'redux-saga/effects';
import {buffers, eventChannel} from 'redux-saga';
import {useSnackbar} from 'notistack';

import actions from './actions';
import Swal from 'sweetalert2';
import _ from 'lodash';
import {getAppConfig} from 'services/configs';
import {Redirect} from 'react-router-dom';

// const defaultTransform = (data) => data.value;

export function* CHECK_MODE({}) {
  const oldAppMode = yield select((state) =>
    _.get(state, 'configs.maintenance'),
  );
  const appConfig = yield call(getAppConfig);

  yield put({
    type: 'configs/SET_STATE',
    payload: appConfig.data,
  });
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CHECK_MODE, CHECK_MODE)]);
}
