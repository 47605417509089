import React, {useState, useEffect} from 'react';
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Container,
  makeStyles,
  Button,
  Grid,
  Divider,
  Avatar,
  TextareaAutosize,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {Pagination, PaginationItem, Skeleton} from '@material-ui/lab';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import {loadSinglePaper} from 'services/papers';
import {
  loadSingleToolkit,
  loadSingleToolkitId,
} from 'services/toolkits';
import CustomTopNav from 'components/layout/TopNav/CutomTopNav';
import CloudDownloadTwoToneIcon from '@material-ui/icons/CloudDownloadTwoTone';
import LikesAndComments from 'components/LikesAndComments';
import ShareButtons from 'components/ShareButtons';
import Swal from 'sweetalert2';
const useStyle = makeStyles((theme) => ({
  root: {
    color: 'white',
    width: '100%',
    paddingTop: 50,
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function VideosDetail() {
  const classes = useStyle();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {slug} = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (slug) {
      setIsLoading(true);
      loadSingleToolkit(slug).then((res) => {
        if (res.isSuccess) {
          if (res.data) {
            setData(res.data);
          } else {
            loadSingleToolkitId(slug).then((res) => {
              if (res.isSuccess) {
                setData(res.data);
              } else {
                Swal.fire(
                  'Ups',
                  'Maaf, link tidak ditemukan',
                  'error',
                ).then(() => {
                  history.replace('/toolkits');
                });
              }
            });
          }
        } else {
          Swal.fire(
            'Ups',
            'Maaf, link tidak ditemukan',
            'error',
          ).then(() => {
            history.replace('/toolkits');
          });
        }
        setIsLoading(false);
      });
    }
  }, [history, slug]);
  return (
    <>
      {isMobile && (
        <CustomTopNav
          title=""
          showBack
          shadow={false}
          backURL="/toolkits"
          // showAuth={false}
        />
      )}
      <Container className={classes.root}>
        <Grid container justify="center">
          <Grid item xl={3} md={5} xs={11}>
            {isLoading ? (
              <Skeleton
                variant="rect"
                width="100%"
                height={300}
                // width={200}
                style={{borderRadius: 16, overflow: 'hidden'}}
              />
            ) : (
              <Paper
                elevation={4}
                style={{
                  borderRadius: 16,
                  overflow: 'hidden',
                  minHeight: 300,
                  width: '100%',
                }}>
                <img
                  src={data.coverImageURL}
                  style={{width: '100%'}}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{textAlign: 'center'}}>
          <Grid item md={8} xs={11}>
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <Skeleton variant="text" height={30} width="30%" />
              </div>
            ) : (
              <div style={{textAlign: 'center'}}>
                <div className="badge badge-warning mt-3">
                  <h5 style={{marginBottom: 0, color: '#333'}}>
                    {data.categoryLabel}
                  </h5>
                </div>
              </div>
            )}
            <h1
              style={{
                color: 'white',
                marginTop: 15,
                marginBottom: 25,
                fontSize: 36,
              }}>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                  <Skeleton variant="text" height={50} width="70%" />
                </div>
              ) : (
                data.title
              )}
            </h1>

            <div
              style={{
                display: 'flex',
                justifyContent: isMobile
                  ? 'space-between'
                  : 'flex-start',
                alignItems: 'center',
                marginTop: 20,
              }}>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: isMobile
                      ? 'center'
                      : 'flex-start',
                    width: '100%',
                  }}>
                  <Skeleton variant="text" height={30} width="50%" />
                </div>
              ) : (
                <>
                  <div style={{marginRight: 10}}>
                    <LikesAndComments
                      collectionId={data.toolkitId}
                      collectionType="toolkits"
                    />
                  </div>
                  <div style={{flexGrow: 10}}></div>
                  <div>
                    <ShareButtons
                      title={data.title}
                      url={window.location.href}
                    />
                  </div>
                </>
              )}
            </div>
            <Divider />
            <br />
            {isLoading ? (
              <div style={{width: '100%'}}>
                <Skeleton variant="text" height={50} width="100%" />
                <Skeleton variant="text" height={50} width="100%" />
                <Skeleton variant="text" height={50} width="30%" />
              </div>
            ) : (
              <p>{data.description}</p>
            )}
            <br />
            <Button
              download
              href={data.toolkitURL}
              disabled={isLoading}
              variant="contained"
              color="secondary"
              startIcon={<CloudDownloadTwoToneIcon />}>
              Download
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
