import React, {useState, useEffect} from 'react';
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Container,
  makeStyles,
  Button,
  Grid,
  Divider,
  Checkbox,
  Input,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import _ from 'lodash';
import Swal from 'sweetalert2';
import {updateProfile, loginWithToken} from 'services/user';
import CustomTopNav from 'components/layout/TopNav/CutomTopNav';
import * as yup from 'yup';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  firebaseAuth,
  storageRef,
  databaseRef,
} from 'services/firebase';
import Resizer from 'react-image-file-resizer';

const registrationSchema = yup.object().shape({
  fullName: yup
    .string()
    .typeError('penulisan nama salah')
    .default(''),
  phoneNumber: yup
    .string()
    .max(14, 'maskimal nomor telepon 14 karakter')
    .typeError('penulisan nomor telepon salah')
    .nullable()
    .test(
      'is-phone-valid',
      'phone tidak valid, gunakan kode negara misal +6281234123412',
      function (value) {
        // pastikan isinya angka semua
        if (value) return /^\+?[1-9]\d{1,14}$/.test(value);
        else return true;
      },
    ),
  photoURL: yup.string().typeError('photoURL salah').nullable(),
});

const useStyle = makeStyles((theme) => ({
  root: {
    color: 'white',
    textAlign: 'center',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function Profile() {
  const classes = useStyle();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const isLoggedIn = useSelector(
    (state) => _.get(state, 'user.authorized', false),
    false,
  );
  const userData = useSelector(
    (state) => _.get(state, 'user', {}),
    false,
  );
  const isVerified = useSelector(
    (state) => _.get(state, 'user.emailVerified'),
    false,
  );

  const formRegistration = useForm({
    resolver: yupResolver(registrationSchema),
  });

  useEffect(() => {
    if (!isLoggedIn) {
      history.push({
        pathname: '/signin',
        state: {from: location.pathname},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const [photoURL, setPhotoURL] = useState(userData.photoURL);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        'webp',
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        'file',
      );
    });

  const handleFileChange = async (e) => {
    try {
      let file = e.target.files[0] || {};
      file = await resizeFile(file);
      if (file.size > 1024000) {
        Swal.fire('Upps', 'Ukuran File Max 1MB', 'error');
      } else {
        const fileRef = storageRef.child(
          `${userData.id}_${databaseRef.push().key}`,
        );
        fileRef.put(file).then((res) => {
          res.ref.getDownloadURL().then((url) => {
            setPhotoURL(url);
            formRegistration.setValue('photoURL', url);
          });
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setError,
  } = useForm();
  const onSubmit = (dataReq) => {
    let user;
    setIsLoading(true);
    updateProfile(dataReq)
      .then(({isSuccess, data, error}) => {
        if (isSuccess) {
          Swal.fire(
            'Berhasil',
            'Profil berhasil disimpan',
            'success',
          );
          setIsLoading(false);
          loginWithToken(data.token).then((res) => {
            // console.log('berhasil login ulang');
          });
          dispatch({
            type: 'user/SET_STATE',
            payload: {
              phoneNumber: dataReq.phoneNumber,
              photoURL: dataReq.photoURL,
              name: dataReq.fullName,
            },
          });
        } else {
          Swal.fire('Gagal Disimpan', error.message || '', 'error');
          Swal.hideLoading();
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        Swal.fire('Gagal Disimpan', e.message || '', 'error');
        setIsLoading(false);
      });
  };
  const onError = (err) => {
    Swal.fire(
      'Terjadi Kesalahan',
      Object.values(err)[0].message || '',
      'error',
    );
    console.log(err);
  };
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [redirectToReferrer, setRedirectToReferrer] = React.useState(
    false,
  );
  const hiddenFileInput = React.useRef(null);

  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      {isMobile && (
        <CustomTopNav
          title="Profile"
          showBack
          showAuth={false}
          // backURL={(location.state && location.state.from) || '/'}
        />
      )}
      <Container className={classes.root}>
        <Grid container justify="center" style={{marginTop: 50}}>
          <Grid item md={4} xs={10}>
            <div
              style={{
                marginTop: 20,
                marginBottom: 30,
                color: 'white',
                fontWeight: 600,
                fontSize: 36,
                textAlign: 'center',
                display: !isMobile ? 'block' : 'none',
              }}>
              Profile
            </div>

            <Grid
              container
              justify="center"
              style={{
                width: '100%',
                marginBottom: 20,
              }}>
              <Controller
                control={formRegistration.control}
                defaultValue={userData.photoURL}
                name="photoURL"
                render={({onChange, onBlur, value}) => {
                  if (value)
                    return (
                      <Grid
                        item
                        xs={8}
                        style={{
                          textAlign: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                        }}>
                        <div
                          style={{
                            textAlign: 'center',
                            width: '150px',
                            height: '150px',
                            borderRadius: '50%',
                            border: 'solid 3px white',
                            overflow: 'hidden',
                          }}>
                          <img
                            src={value}
                            style={{
                              height: '100%',
                              width: 'auto',
                              overflow: 'hidden',
                            }}
                          />
                        </div>
                      </Grid>
                    );
                  else return <></>;
                }}
              />
            </Grid>

            <input
              type="file"
              ref={hiddenFileInput}
              style={{display: 'none'}}
              onChange={handleFileChange}
              name="fileToUpload"
              id="fileToUpload"
            />
            <div>
              <Button
                variant="outlined"
                size="small"
                onClick={handleClick}>
                Upload
              </Button>
            </div>
            <br />
            <br />
            <h5>{userData.email}</h5>
            <hr style={{backgroundColor: 'white'}} />

            <div
              style={{
                marginTop: -18,
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'left',
                width: '100%',
                // borderBottom: 'solid 1px',
              }}></div>
            <br />

            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container justify="center" spacing={2}>
                <Grid
                  item
                  xs={12}
                  style={{width: '100%', textAlign: 'left'}}>
                  <Controller
                    name="fullName"
                    control={formRegistration.control}
                    defaultValue={userData.name || ''}
                    render={({onChange, value}) => (
                      <>
                        <label style={{marginBottom: 3}}>Name</label>
                        <input
                          className="form-control"
                          name="fullName"
                          value={value}
                          onChange={onChange}
                          size="large"
                          placeholder="First Name"
                        />
                      </>
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{width: '100%', textAlign: 'left'}}>
                  <Controller
                    name="phoneNumber"
                    control={formRegistration.control}
                    defaultValue={userData.phoneNumber || ''}
                    render={({onChange, value}) => (
                      <>
                        <label style={{marginBottom: 3}}>
                          Phone Number
                        </label>
                        <input
                          className="form-control"
                          name="phoneNumber"
                          value={value}
                          onChange={(e) => {
                            let val = e.target.value;
                            if (val.slice(0, 2) === '08')
                              val = '+628' + val.slice(2, 18);
                            onChange(val);
                          }}
                          size="large"
                          placeholder="Phone Number"
                        />
                      </>
                    )}
                  />
                </Grid>
              </Grid>

              <br />
              <br />
              <Button
                color="secondary"
                variant="contained"
                type="submit"
                disabled={isLoading}
                onClick={(e) => {
                  e.preventDefault();
                  formRegistration.handleSubmit(onSubmit, onError)();
                }}
                style={{width: '100%'}}>
                {isLoading ? 'Saving..' : 'Save Profile'}
              </Button>
              <br />
              <br />
              <Button
                color="secondary"
                // variant="contained"
                onClick={(e) => {
                  dispatch({
                    type: 'user/LOGOUT',
                  });
                }}
                style={{width: '100%'}}>
                Sign Out
              </Button>
            </form>
            <br />
            <br />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
