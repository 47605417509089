import React, {useState, useEffect} from 'react';
import {fade, makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {useDispatch, useSelector} from 'react-redux';
import {Avatar} from '@material-ui/core';
import {useHistory, Redirect} from 'react-router-dom';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import _ from 'lodash';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {firebaseAuth} from 'services/firebase';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: 'white',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appbar: {
    root: {
      marginBottom: 20,
    },
  },
}));

export default function TopBar(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(
    null,
  );
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const userData = useSelector(
    (state) => (state.user && state.user) || {},
  );

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const isAdmin = useSelector(
    (state) => _.get(state, 'user.isAdmin'),
    false,
  );
  const isLoading = useSelector(
    (state) => _.get(state, 'user.loading'),
    false,
  );
  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const menuId = 'primary-search-account-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={menuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      <MenuItem
        onClick={() => {
          dispatch({
            type: 'user/LOGOUT',
          });
        }}>
        Logout
      </MenuItem>
    </Menu>
  );

  const [count, setCount] = useState(0);
  if (!isAdmin && !isLoading) {
    return <Redirect to={'/'} />;
  }
  // if (isLoading) return <div>Loading</div>;
  return (
    <>
      <AppBar position="sticky" className={classes.appbar}>
        <Toolbar
          style={{
            minHeight: 50,
            height: 50,
            // backgroundColor: props.sidebarOpen ? 'white' : null,
          }}>
          <IconButton
            onClick={() => props.onMenuToggle()}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer">
            <MenuOpenIcon />
          </IconButton>
          <div className={classes.grow} />
          <div>
            <span>{userData.name}</span>
            {/* <span className="badge badge-warning">
              {userData.email}
            </span> */}
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit">
              <AccountCircle />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </>
  );
}
