import React, {useState, useEffect} from 'react';
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Container,
  makeStyles,
  Button,
  Grid,
  useTheme,
  useMediaQuery,
  Paper,
  Typography,
  Divider,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {Pagination, PaginationItem, Skeleton} from '@material-ui/lab';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import CustomTopNav from 'components/layout/TopNav/CutomTopNav';
import {loadSinglePaper, loadSinglePaperId} from 'services/papers';
import Swal from 'sweetalert2';
import moment from 'moment';
import _ from 'lodash';
import LikesAndComments from 'components/LikesAndComments';
import ShareButtons from 'components/ShareButtons';

const useStyle = makeStyles((theme) => ({
  root: {
    color: 'white',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));
export default function CourseDetail() {
  const classes = useStyle();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {slug} = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (slug) {
      setIsLoading(true);
      loadSinglePaper(slug).then((res) => {
        if (res.isSuccess) {
          if (res.data) {
            setData(res.data);
          } else {
            loadSinglePaperId(slug).then((res) => {
              if (res.isSuccess) {
                setData(res.data);
              } else {
                Swal.fire(
                  'Ups',
                  'Maaf, link tidak ditemukan',
                  'error',
                ).then(() => {
                  history.push('/papers');
                });
              }
            });
          }
        } else {
          Swal.fire(
            'Ups',
            'Maaf, link tidak ditemukan',
            'error',
          ).then(() => {
            history.push('/papers');
          });
        }
        setIsLoading(false);
      });
    }
  }, [history, slug]);
  return (
    <>
      {isMobile && (
        <CustomTopNav
          title=""
          showBack
          // showAuth={false}
        />
      )}
      <Container className={classes.root}>
        <Grid
          container
          spacing={2}
          style={{marginTop: isMobile ? 30 : 100}}>
          <Grid
            item
            md={3}
            sm={12}
            xs={12}
            style={{textAlign: 'center', marginBottom: 20}}>
            {isLoading ? (
              <Skeleton
                variant="rect"
                width="100%"
                height={300}
                // width={200}
                style={{borderRadius: 16, overflow: 'hidden'}}
              />
            ) : (
              <Paper
                elevation={4}
                style={{
                  borderRadius: 16,
                  overflow: 'hidden',
                  minHeight: 300,
                  width: '100%',
                }}>
                <img
                  src={data.coverImageURL}
                  style={{maxWidth: '100%'}}
                />
              </Paper>
            )}

            <br />
            <Button
              variant="contained"
              color="secondary"
              href={data.paperURL}
              disabled={isLoading}>
              PDF File
            </Button>
          </Grid>
          <Grid item md={9} xs={12}>
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: isMobile ? 'center' : 'flex-start',
                }}>
                <Skeleton variant="text" height={30} width="30%" />
              </div>
            ) : (
              <div style={{textAlign: isMobile ? 'center' : 'left'}}>
                <div className="badge badge-warning mt-3">
                  <h5 style={{marginBottom: 0, color: '#333'}}>
                    {data.categoryLabel}
                  </h5>
                </div>
              </div>
            )}
            <br />
            <Typography
              variant="h1"
              color="textPrimary"
              style={{
                lineHeight: 1.3,
                textAlign: isMobile ? 'center' : 'left',
              }}>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: isMobile
                      ? 'center'
                      : 'flex-start',
                  }}>
                  <Skeleton variant="text" height={30} width="50%" />
                </div>
              ) : (
                data.title
              )}
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: isMobile
                  ? 'space-between'
                  : 'flex-start',
                alignItems: 'center',
                marginTop: 20,
              }}>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: isMobile
                      ? 'center'
                      : 'flex-start',
                    width: '100%',
                  }}>
                  <Skeleton variant="text" height={30} width="50%" />
                </div>
              ) : (
                <>
                  <div style={{marginRight: 10}}>
                    <LikesAndComments
                      collectionId={data.paperId}
                      collectionType="papers"
                    />
                  </div>
                  <div style={{marginRight: 10}}></div>
                  <div>
                    <ShareButtons
                      title={data.title}
                      url={window.location.href}
                    />
                  </div>
                </>
              )}
            </div>
            <Divider />
            <br />
            <p>
              {isLoading ? (
                <>
                  <Skeleton variant="text" height={30} width="100%" />
                  <Skeleton variant="text" height={30} width="100%" />
                  <Skeleton variant="text" height={30} width="30%" />
                </>
              ) : (
                data.description
              )}
            </p>
          </Grid>
        </Grid>
        <Grid container></Grid>
      </Container>
    </>
  );
}
