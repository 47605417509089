import {getFirebaseIdToken} from './user';
import {functionUrl} from 'helper/functions';
import axios from 'axios';
import _ from 'lodash';

export async function loadBanners(payload) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.get(
      `${functionUrl}/banners`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const data = _.get(response, 'data', {});
    // console.log('kategori dari fn,', response);
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}

export async function createBanner(payload) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/banners/create`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    return {
      data: response,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function updateBanner(payload, bannerId) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.put(
      `${functionUrl}/banners/${bannerId}`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    return {
      data: response,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}

export async function deleteBanner(bannerId, isPermanent = false) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/banners/delete`,
      {isPermanent, bannerId},
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    return {
      data: response,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function restoreBanner(bannerId) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/banners/restore`,
      {bannerId},
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    return {
      data: response,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
