import React, {Fragment} from 'react';

const Page404 = () => {
  const renderTopContent = () => {
    return (
      <div
        className="position-relative overflow-hidden py-5"
        style={{backgroundColor: 'white', height: '100vh'}}>
        <div className="container">
          <div className="row py-2">
            <div className="col-lg-12 col-sm-12 py-5 text-center order-2 order-md-1 ">
              <div>
                <img src="/images/404.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <Fragment>{renderTopContent()}</Fragment>;
};

export default Page404;
