import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Button,
  Container,
  Grid,
  Paper,
  Divider,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import {useHistory} from 'react-router';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    paddingTop: 70,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: 'white',
  },
  margin: {
    margin: theme.spacing(1),
  },
  buttonWhite: {
    color: 'white',
    marginLeft: 10,
    marginRight: 10,
  },
}));
export default function Footer() {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const matches = useMediaQuery((theme) =>
    theme.breakpoints.up('md'),
  );

  return (
    <div className={classes.root}>
      <Container>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{padding: `20px 0px`}}>
          <Grid item md={1}>
            <img src="/images/logo.png" height="100px" />
          </Grid>
          <Grid
            item
            md={8}
            sm={12}
            xs={12}
            style={{display: 'flex', alignItems: 'center'}}>
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                md={2}
                sm={6}
                xs={4}
                style={{textAlign: 'center'}}>
                <Button
                  className={classes.buttonWhite}
                  onClick={() => history.push('/about')}>
                  ABOUT us
                </Button>
              </Grid>
              <Grid
                item
                md={2}
                sm={6}
                xs={4}
                style={{textAlign: 'center'}}>
                <Button
                  className={classes.buttonWhite}
                  onClick={() => {
                    window.open(
                      'https://smart171.org/campaign/bantu-wujudkan-pals-palestine-learning-source/',
                      '_blank',
                    );
                  }}>
                  Donate
                </Button>
              </Grid>

              <Grid
                item
                md={2}
                sm={6}
                xs={4}
                style={{textAlign: 'center'}}>
                <Button
                  className={classes.buttonWhite}
                  onClick={() => history.push('/contact-us')}>
                  Contact Us
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item md={3}>
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                xs={8}
                style={{display: 'flex', alignItems: 'center'}}>
                <input
                  className="form-control"
                  style={{maxWidth: '90%'}}
                />
              </Grid>
              <Grid
                item
                xs={4}
                style={{display: 'flex', alignItems: 'center'}}>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary">
                  Subscribe
                </Button>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
        <Divider style={{backgroundColor: 'white'}} />
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{padding: `20px 0px`}}>
          <Grid item sm={6} xs={12}>
            <div
              style={{
                color: 'white',
                textAlign: matches ? 'left' : 'center',
                width: '100%',
              }}>
              Copyright | SMART 171 | 2021
            </div>
          </Grid>
          <Grid item sm={6} xs={12}>
            <div
              style={{
                color: 'white',
                textAlign: matches ? 'right' : 'center',
                width: '100%',
              }}>
              <IconButton aria-label="delete" size="medium">
                <WhatsAppIcon fontSize="inherit" />
              </IconButton>
              <IconButton aria-label="delete" size="medium">
                <InstagramIcon fontSize="inherit" />
              </IconButton>
              <IconButton aria-label="delete" size="medium">
                <YouTubeIcon fontSize="inherit" />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
