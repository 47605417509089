import React, {useState, useEffect} from 'react';
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Container,
  makeStyles,
  Button,
  Grid,
  useTheme,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {Pagination, PaginationItem, Skeleton} from '@material-ui/lab';
import CustomTopNav from 'components/layout/TopNav/CutomTopNav';
import {loadPapers, loadPublishedPapers} from 'services/papers';
import _ from 'lodash';
const useStyle = makeStyles((theme) => ({
  root: {
    color: 'white',
    textAlign: 'center',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function Papers() {
  const classes = useStyle();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [loading, setLoading] = useState(true);
  const [papersData, setPapersData] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activePage, setActivePage] = useState(1); //start from 1
  const [maxPageNumber, setMaxPageNumber] = useState(0); //start from 0

  useEffect(() => {
    setLoading(true);
    loadPublishedPapers({page: activePage - 1, size: 6}).then(
      (res) => {
        if (res.isSuccess) {
          const data = _.get(res, 'data.data', []);
          const totalPages = _.get(res, 'data.maxPageNumber', []);
          if (_.isArray(data)) {
            setPapersData(data);
            setMaxPageNumber(totalPages);
          }
        }
        setLoading(false);
      },
    );
  }, [activePage]);

  return (
    <>
      {isMobile && (
        <CustomTopNav
          title="Jerat Data"
          showBack={false}
          // showAuth={false}
        />
      )}
      <Container className={classes.root}>
        <div
          style={{
            marginTop: 80,
            marginBottom: 30,
            color: 'white',
            fontWeight: 'bold',
            fontSize: 60,
            textAlign: 'center',
            display: !isMobile ? 'block' : 'none',
          }}>
          Jerat Data
        </div>

        <Container style={{marginTop: 30, paddingBottom: 50}}>
          <Grid container spacing={3}>
            {(loading
              ? [1, 2, 3, 4, 5, 6]
              : Object.values(papersData || {}) || []
            ).map((item, index) => (
              <Grid
                key={index}
                item
                md={4}
                xs={6}
                style={{textAlign: 'left'}}>
                {loading ? (
                  <>
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={150}
                    />
                    <br />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </>
                ) : (
                  <Link to={`/papers/${item.slug}`}>
                    <img
                      src={item.coverImageURL || ''}
                      style={{
                        maxWidth: '100%',
                        borderRadius: 5,
                        // width: '100%',
                      }}
                    />
                    <div className="badge badge-warning mt-2">
                      <Typography
                        style={{color: '#333'}}
                        variant="h5">
                        {item.categoryLabel}
                      </Typography>
                    </div>
                    <br />
                    <br />
                    <Typography variant="h5">{item.title}</Typography>
                  </Link>
                )}
              </Grid>
            ))}
          </Grid>

          <div
            style={{
              width: '100%',
              display: 'flex',
              marginBottom: 30,
              justifyContent: 'center',
            }}>
            <Pagination
              count={maxPageNumber + 1}
              onChange={(e, val) => setActivePage(val)}
              size="small"
              color="secondary"
              itemProp={{style: {color: 'white'}}}></Pagination>
          </div>
        </Container>
      </Container>
    </>
  );
}
