import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import _ from 'lodash';

const useStyles = makeStyles({
  root: {
    width: '100%',
    fontSize: 12,
  },
  container: {
    height: 'calc(100vh - 186px)',
    fontSize: 10,
  },
});

export default function MyTable({
  columns = [],
  rows = [],
  loading = false,
  height = `calc(100vh - 186px)`,
  withFooter = true,
}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container} style={{height}}>
        <Table size="small" stickyHeader aria-label="a dense table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column.dataKey || index}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                    flex: column.flex,
                  }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{fontSize: 10}}>
            {loading ? (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell
                  colSpan={columns.length}
                  rowSpan={3}
                  align={'center'}>
                  <div>
                    <i
                      className="fa fa-spin fa-sync"
                      style={{fontSize: 28}}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              rows
                .slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                )
                .map((row, rowIndex) => {
                  const rowCount = page * rowsPerPage + rowIndex;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.key || rowIndex}>
                      {columns.map(
                        (
                          {
                            dataKey,
                            headerRender,
                            cellRender,
                            width,
                            minWidth,
                            maxWidth,
                            align,
                            justify,
                          },
                          index,
                        ) => {
                          return (
                            <TableCell
                              style={{minWidth, maxWidth, width}}
                              key={dataKey}
                              align={align || 'left'}>
                              <div>
                                {_.isFunction(cellRender)
                                  ? cellRender(
                                      row[dataKey],
                                      row,
                                      rowCount,
                                    )
                                  : row[dataKey]}
                              </div>
                            </TableCell>
                          );
                        },
                      )}
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {withFooter && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
}
