import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import {createBrowserHistory} from 'history';
import {createStore, applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux';
// import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga';
import {routerMiddleware} from 'connected-react-router';
import {isLocalhost} from 'helper/functions';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {PersistGate} from 'redux-persist/integration/react';

import reducers from './redux/reducers';
import sagas from './redux/sagas';
import Router from './router';
import * as serviceWorker from './serviceWorker';

import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

// import {AbilityContext} from './helper/can';
// import {ability} from './helper/ability';

import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');
// biar semua moment pake bahasa indonesia
import {SnackbarProvider} from 'notistack';

// app styles
import './styles/main.scss';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import {useMediaQuery} from '@material-ui/core';
import {loadReCaptcha} from 'react-recaptcha-v3';
const lisLocalhost = isLocalhost();

// import {offline} from '@redux-offline/redux-offline';
// import offlineConfig from '@redux-offline/redux-offline/lib/defaults';

Bugsnag.start({
  appVersion: process.env.REACT_APP_CURRENT_GIT_SHA,
  apiKey: 'ccfc8bed94fb896af21ee734623bcb30',
  releaseStage: process.env.REACT_APP_CUSTOM_ENV,
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    if (lisLocalhost) {
      return false;
    }
    return true;
  },
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(
  React,
);

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [sagaMiddleware, routeMiddleware];

const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage: storage,
    whitelist: [
      'settings',
      'search',
      'user',
      'articles',
      'categories',
      'videos',
      'papers',
      'toolkits',
      'courses',
    ],
  },
  reducers(history),
);

const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(...middlewares),
    // offline(offlineConfig),
  ),
);
sagaMiddleware.run(sagas);

const persistor = persistStore(store);

const theme = createMuiTheme({
  typography: {
    fontFamily: ['"Fira Sans"', 'Arial'].join(','),
  },
  palette: {
    primary: {
      main: '#2E4462',
      contrastText: '#fff',
    },
    secondary: {
      main: '#EB5757',
      contrastText: '#fff',
    },
    info: {
      main: '#65C1CA',
      contrastText: '#fff',
    },
    type: 'dark',
  },
  '.MuiIconButton-root': {
    outline: 'none',
  },
});

theme.typography.h1 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};
theme.typography.h2 = {
  fontSize: '1.1rem',
  '@media (min-width:600px)': {
    fontSize: '1.4rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};
theme.typography.h3 = {
  fontSize: '1rem',
  '@media (min-width:600px)': {
    fontSize: '1.3rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.6rem',
  },
};
theme.typography.h4 = {
  fontSize: '0.8rem',
  color: 'white',
  '@media (min-width:600px)': {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.4rem',
  },
};
theme.typography.h5 = {
  fontSize: '0.7rem',
  color: 'white',
  lineHeight: 1.3,
  '@media (min-width:600px)': {
    fontSize: '1.1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.3rem',
  },
};
theme.typography.body1 = {
  fontSize: '0.7rem',
  color: 'white',
  lineHeight: 1.3,
  '@media (min-width:600px)': {
    fontSize: '0.9rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
};
theme.typography.body2 = {
  fontSize: '0.7rem',
  color: 'white',
  lineHeight: 1.3,
  '@media (min-width:600px)': {
    fontSize: '0.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.9rem',
  },
};

ReactDOM.render(
  <ErrorBoundary>
    <SnackbarProvider maxSnack={3}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="id">
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Router history={history} />
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </SnackbarProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
export {store, persistor, history};
