import React, {useState, useEffect} from 'react';
import {
  Link,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {
  Container,
  makeStyles,
  Button,
  Grid,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {
  loadSingleCourseId,
  loadCourses,
  isJoined,
  joinCourse,
} from 'services/courses';
import ReactPlayer from 'react-player/youtube';
import moment from 'moment';
import CustomTopNav from 'components/layout/TopNav/CutomTopNav';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Swal from 'sweetalert2';
import LikesAndComments from 'components/LikesAndComments';
import ShareButtons from 'components/ShareButtons';
const useStyle = makeStyles((theme) => ({
  root: {
    color: 'white',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function CourseRoom() {
  const classes = useStyle();
  let history = useHistory();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [duration, setDuration] = useState(moment.duration());
  const {courseId} = useParams();
  const [data, setData] = useState({});
  const [coursesList, setCoursesList] = useState([]);
  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    if (courseId) {
      setIsLoading(true);
      loadSingleCourseId(courseId).then((res) => {
        if (res.isSuccess) {
          if (res.data) {
            setData(res.data);
            setDuration(
              moment.duration(res.data.startAt - moment().valueOf()),
            );
          } else {
            Swal.fire(
              'Ups',
              'Maaf, link tidak ditemukan',
              'error',
            ).then(() => {
              history.replace('/courses');
            });
          }
        } else {
          Swal.fire(
            'Ups',
            'Maaf, link tidak ditemukan',
            'error',
          ).then(() => {
            history.replace('/courses');
          });
        }
        setIsLoading(false);
      });
      isJoined({courseId}).then((res) => {
        if (res.isSuccess) {
          if (res.data.isJoined) {
            console.log('user sudah terdaftar di kelas');
          } else {
            history.replace(`/courses/${courseId}`);
          }
        }
      });
    }
  }, [history, courseId]);

  useEffect(() => {
    if (data.isBundled && data.order === 0 && courseId) {
      setIsLoading(true);
      loadCourses({isBundled: true, parentId: courseId})
        .then((res) => {
          if (res.isSuccess) {
            setCoursesList(res.data);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setCoursesList([]);
    }
  }, [
    data.isBundled,
    data.parentId,
    data.courseId,
    data.order,
    courseId,
  ]);

  return (
    <>
      {isMobile && (
        <CustomTopNav
          title=""
          showBack
          backURL={
            data.parentId && data.parentId !== '-'
              ? `/courses/${data.parentId}/rooms`
              : `/courses/${courseId}`
          }
          // showAuth={false}
        />
      )}
      <Container className={classes.root}>
        <Typography
          variant="h2"
          color="textPrimary"
          className="mt-3 text-center">
          {isLoading ? (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Skeleton variant="text" height={30} width="50%" />
            </div>
          ) : (
            data.title || ''
          )}
        </Typography>
        <div className="text-center">
          {isLoading ? (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Skeleton variant="text" height={30} width="30%" />
            </div>
          ) : (
            <p className="badge badge-warning mt-2 mb-2 ">
              {` ${data.categoryLabel}`}
            </p>
          )}
        </div>
        {/* </div> */}
        <Grid container justify="center">
          <Grid item xs={12} md={8}>
            <Paper
              elevation={3}
              className=" my-3 "
              style={{
                borderRadius: 5,
                overflow: 'hidden',
                backgroundColor: '#3333',
                // padding: isMobile ? 8 : 16,
              }}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center">
                <Grid item xs={12}>
                  {data.videoURL &&
                  moment(data.startAt) < moment() ? (
                    <div className="player-wrapper">
                      <ReactPlayer
                        className="react-player"
                        url={data.videoURL}
                        width="100%"
                        height="100%"
                        controls={true}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        textAlign: 'center',
                        paddingTop: 60,
                        paddingBottom: 60,
                      }}>
                      <Grid container justify="center">
                        <Grid item xs={6} md={4}>
                          {isLoading ? (
                            <Skeleton
                              variant="rect"
                              width="100%"
                              height={170}
                              // width={200}
                              style={{
                                borderRadius: 16,
                                overflow: 'hidden',
                              }}
                            />
                          ) : (
                            <Paper
                              elevation={4}
                              style={{
                                borderRadius: 16,
                                overflow: 'hidden',
                                minHeight: 170,
                              }}>
                              <img
                                src={data.coverImageURL}
                                style={{width: '100%'}}
                              />
                            </Paper>
                          )}
                        </Grid>
                      </Grid>
                      <br />
                      {isLoading ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}>
                          <Skeleton
                            variant="text"
                            height={50}
                            width="30%"
                          />
                        </div>
                      ) : (
                        <Typography variant="h1" color="textPrimary">
                          {moment(data.startAt).subtract(
                            10,
                            'minutes',
                          ) > moment()
                            ? 'Coming Soon!'
                            : 'Live NOW!'}
                        </Typography>
                      )}
                      <br />
                      <br />
                      {moment(data.startAt).subtract(10, 'minutes') >
                        moment() && (
                        <>
                          <Typography variant="h1">
                            {duration.days()} hari, {duration.hours()}{' '}
                            jam, {duration.minutes()} menit
                          </Typography>
                          <br />
                          <br />
                        </>
                      )}
                      {(Boolean(data.zoomURL) ||
                        Boolean(data.youtubeURL)) &&
                        moment(data.startAt).subtract(10, 'minutes') <
                          moment() && (
                          <div>
                            <Typography
                              variant="h4"
                              color="textPrimary">
                              Join Here:
                            </Typography>
                            <br />
                            <Grid container spacing={2}>
                              {Boolean(data.zoomURL) && (
                                <Grid item xs={12}>
                                  <Button
                                    variant="outlined"
                                    href={data.zoomURL || '#'}
                                    style={{
                                      color: 'white',
                                      fontSize: 20,
                                      width: '50%',
                                    }}>
                                    <img
                                      src="\images\zoom.png"
                                      style={{
                                        width: 30,
                                        marginRight: 10,
                                      }}
                                    />{' '}
                                    Zoom
                                  </Button>
                                </Grid>
                              )}
                              {data.youtubeURL && (
                                <Grid item xs={12}>
                                  <Button
                                    variant="outlined"
                                    href={data.youtubeURL}
                                    style={{
                                      color: 'white',
                                      fontSize: 20,
                                      width: '50%',
                                    }}>
                                    <YouTubeIcon
                                      color="secondary"
                                      style={{
                                        fontSize: 30,
                                        marginRight: 10,
                                      }}
                                    />{' '}
                                    Youtube
                                  </Button>
                                </Grid>
                              )}
                            </Grid>
                            <br />
                          </div>
                        )}
                    </div>
                  )}
                  <Divider />
                </Grid>
              </Grid>
              <br />
              <Grid container alignItems="center" justify="center">
                <Grid
                  item
                  xs={11}
                  style={{
                    fontSize: isMobile ? 16 : 18,
                    overflow: 'hidden',
                  }}>
                  {isLoading ? (
                    <Skeleton variant="text" height={30} />
                  ) : (
                    <Grid container justify="space-between">
                      <LikesAndComments
                        collectionType="courses"
                        collectionId={courseId}
                      />
                      <ShareButtons
                        title={data.title}
                        url={window.location.href}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <br />
              <Grid
                container
                // direction="row"
                justify="center"
                alignItems="center">
                <Grid item xs={11}>
                  {isLoading ? (
                    <>
                      <Skeleton variant="text" height={30} />
                      <Skeleton variant="text" height={30} />
                      <Skeleton variant="text" height={30} />
                      <Skeleton variant="text" height={30} />
                      <br />
                    </>
                  ) : (
                    <p>{data.description}</p>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Divider />

        <br />
        {data.isBundled && data.order === 0 && (
          <Grid container spacing={2} justify="center">
            <Grid item md={8} xs={12}>
              <Typography
                style={{color: 'white', textAlign: 'center'}}
                variant="h3">
                Seri Kelas
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Grid container item spacing={2} justify="flex-start">
                {(coursesList || []).map((item, index) => (
                  <Grid
                    key={index}
                    item
                    md={4}
                    sm={6}
                    xs={6}
                    style={{textAlign: 'left'}}>
                    <Link
                      to="#"
                      onClick={() => {
                        setIsLoading(true);
                        joinCourse({courseId: item.courseId}).then(
                          ({isSuccess}) => {
                            if (isSuccess)
                              history.push(
                                `/courses/${item.courseId}/rooms`,
                              );
                            setIsLoading(false);
                          },
                        );
                      }}>
                      <img
                        src={item.coverImageURL || ''}
                        style={{maxWidth: '100%', width: '100%'}}
                      />
                    </Link>
                    <br />
                    <p className="badge badge-warning mt-2 mb-2">
                      {` ${item.categoryLabel}`}
                    </p>
                    <Typography variant="h5">{item.title}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
        <br />
        {data.order === 0 ? (
          <Grid container justify="center" alignItems="center">
            <Grid
              item
              md={8}
              sm={8}
              xs={10}
              style={{textAlign: 'center'}}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => history.push(`/courses`)}>
                Lihat Kelas Lainnya
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container justify="center" alignItems="center">
            <Grid
              item
              md={8}
              sm={8}
              xs={10}
              style={{textAlign: 'center'}}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  history.push(`/courses/${data.parentId}/rooms`)
                }>
                Lihat Seri Lainnya
              </Button>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
}
