import React, {useState, useEffect} from 'react';
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Container,
  makeStyles,
  Button,
  Grid,
  Divider,
  Avatar,
  TextareaAutosize,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {Pagination, PaginationItem, Skeleton} from '@material-ui/lab';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ReactPlayer from 'react-player';
import {loadSingleVideo} from 'services/videos';
import CustomTopNav from 'components/layout/TopNav/CutomTopNav';
const useStyle = makeStyles((theme) => ({
  root: {
    color: 'white',
    marginTop: 10,
    width: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function VideosDetail() {
  const classes = useStyle();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const {slug} = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    // get single video
    setIsLoading(true);
    loadSingleVideo(slug).then((res) => {
      if (res && res.isSuccess) {
        setData(res.data);
      }
      setIsLoading(false);
    });
  }, [slug]);
  const [mode, setMode] = useState('serial');
  if (isLoading)
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center">
        <Grid item xs={12} md={8}>
          <Grid container spacing={3} justify="center">
            <Grid
              item
              md={12}
              style={{
                textAlign: 'center',
                width: '100%',
                overflow: 'hidden',
                padding: 20,
              }}>
              <Skeleton variant="rect" width="100%">
                <div style={{paddingTop: '50%'}} />
              </Skeleton>
            </Grid>
            <Grid item md={12} xs={10} sm={10}>
              {/* <h5 style={{color: 'white', marginTop: 20}}> */}
              <Skeleton width="100%" />
              {/* </h5> */}
              {/* <h3 */}
              {/* style={{
                  color: 'white',
                  marginTop: 15,
                  marginBottom: 25,
                  fontSize: 26,
                }}> */}
              <Skeleton width="100%" />
              {/* </h3> */}
              <div className="abstract-text">
                {' '}
                <Skeleton width="100%">
                  <div style={{paddingTop: '30%'}} />
                </Skeleton>
              </div>
              <br />
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <Skeleton width="100%" />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

  return (
    <>
      {isMobile && (
        <CustomTopNav
          title=""
          showBack
          // showAuth={false}
        />
      )}
      <Container className={classes.root}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center">
          <Grid item md={8} xs={12}>
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url={data.videoURL}
                width="100%"
                height="100%"
                controls={true}
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center">
          <Grid item md={8} xs={12}>
            <h2 style={{color: 'white', marginTop: 20}}>
              {data.title}
            </h2>
            <p>{data.description}</p>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={5}>
          <Grid item md={8} xs={12}>
            <Divider
              variant="middle"
              style={{
                margin: '20px 0px',
                backgroundColor: 'white',
                height: 0.5,
              }}
            />
          </Grid>
        </Grid>

        <div style={{alignText: 'left'}}>
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={5}>
            <Grid item md={8}>
              2 comments
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={5}>
            {[1, 2, 3].map((comment) => (
              <Grid key={comment} item md={8}>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center">
                  <Grid item md={6}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        <Avatar>H</Avatar>
                      </Grid>
                      <Grid item md={6}>
                        <div>User {comment}</div>
                        <div>Kota {comment}</div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <div>{comment} Februari 2020</div>
                  </Grid>
                </Grid>
                <br />
                <Grid container>
                  <Grid item md={12}>
                    You are filled to the brim with energy. You also
                    feel that the energy in you has changed your body
                    and bones. All aches and pain of old age has
                    disappeared.
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{color: '#333'}}
            spacing={5}>
            <Grid item md={8} xs={12}>
              <TextareaAutosize
                aria-label="minimum height"
                style={{
                  width: '100%',
                  resize: 'none',
                  borderRadius: 6,
                  padding: 5,
                }}
                rowsMin={5}
                placeholder="Minimum 3 rows"
              />
              <div style={{textAlign: 'right'}}>
                <Button variant="contained" color="secondary">
                  Send
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={5}>
          <Grid item md={8} xs={12}>
            <Divider
              variant="middle"
              style={{
                margin: '20px 0px',
                backgroundColor: 'white',
                height: 0.5,
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center">
          <Grid item md={8}>
            <h2 style={{color: 'white', marginBottom: 20}}>
              Video Selanjutnya
            </h2>
            <Grid container spacing={3}>
              {[1, 2, 3].map((item) => (
                <Grid key={item} item style={{marginBottom: 10}}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justify="center">
                    <Grid item md={3} sm={12}>
                      <img src="https://via.placeholder.com/200x200" />
                    </Grid>
                    <Grid item md={9} sm={12}>
                      <h3 style={{color: 'white', marginBottom: 10}}>
                        Sejarah Palestina
                      </h3>
                      <p>
                        Esok, tim SMART 171 dan Khairu Ummah juga akan
                        menyampaikan bantuan selimut dan makanan untuk
                        pengungsi di Idlib, Suriah.
                      </p>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          marginTop: 20,
                        }}>
                        <FavoriteIcon />{' '}
                        <span
                          className={{
                            fontSize: 16,
                            weight: 600,
                            marginLeft: 10,
                          }}>
                          13
                        </span>
                        <ChatBubbleIcon style={{marginLeft: 15}} />{' '}
                        <span
                          className={{
                            fontSize: 16,
                            weight: 600,
                            marginLeft: 10,
                          }}>
                          13
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
