import React, {useState, useEffect} from 'react';
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {
  Container,
  makeStyles,
  Button,
  Grid,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import {Pagination, Skeleton} from '@material-ui/lab';
import {loadPublishedCourses} from 'services/courses';
import CustomTopNav from 'components/layout/TopNav/CutomTopNav';
import _ from 'lodash';
const useStyle = makeStyles((theme) => ({
  root: {
    color: 'white',
    textAlign: 'center',
    width: '100%',
    paddingBottom: 30,
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function Courses() {
  const classes = useStyle();

  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('sm'),
  );
  const [loading, setloading] = useState(false);
  const [coursesList, setCoursesList] = useState([]);

  const [activePage, setActivePage] = useState(1); //start from 1
  const [maxPageNumber, setMaxPageNumber] = useState(0); //start from 0

  useEffect(() => {
    setloading(true);
    loadPublishedCourses({page: activePage - 1, size: 8}).then(
      (res) => {
        if (res.isSuccess) {
          const data = _.get(res, 'data.data', []);
          const maxPage = _.get(res, 'data.maxPageNumber', []);
          if (_.isArray(data)) {
            setCoursesList(data);
            setMaxPageNumber(maxPage);
          }
        }
        setloading(false);
      },
    );
  }, [activePage]);

  return (
    <>
      {isMobile && (
        <CustomTopNav title="Ruang Kelas" showBack={false} showAuth />
      )}
      <Container className={classes.root}>
        <div
          style={{
            marginTop: !isMobile ? 80 : 30,
            marginBottom: 30,
            color: 'white',
            fontWeight: 'bold',
            fontSize: !isMobile ? 60 : 30,
            textAlign: 'center',
            display: !isMobile ? 'block' : 'none',
          }}>
          Ruang Kelas
        </div>

        <Container style={{marginTop: 50, paddingBottom: 80}}>
          {loading ? (
            <Grid
              container
              style={{paddingBottom: 50}}
              alignItems="center"
              justify="center">
              {[1, 2, 3, 4, 5, 6].map((item, index) => (
                <Grid
                  key={index}
                  item
                  md={4}
                  xs={6}
                  style={{
                    textAlign: 'center',
                    alignItems: 'center',
                  }}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={150}
                  />
                  <br />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid item>
              <Grid
                container
                justify="flex-start"
                spacing={!isMobile ? 5 : 3}>
                {(coursesList || []).map((item, index) => (
                  <Grid
                    key={index}
                    item
                    md={3}
                    xs={6}
                    style={{textAlign: 'left'}}>
                    <Link to={`/courses/${item.courseId}`}>
                      <img
                        src={item.coverImageURL || ''}
                        style={{
                          maxWidth: '100%',
                          borderRadius: 5,
                          width: '100%',
                        }}
                      />
                      <br />
                      <br />
                      <Typography variant="h5">
                        {item.title}
                      </Typography>

                      <div className="badge badge-danger mt-2">
                        <Typography variant="h5">
                          {Boolean(item.liveYoutubeURL) ||
                          Boolean(item.liveZoomURL) ? (
                            <>
                              LIVE <i className="fab fa-youtube"></i>
                            </>
                          ) : (
                            <>
                              Recorded{' '}
                              <i className="fab fa-youtube"></i>
                            </>
                          )}
                        </Typography>
                      </div>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
          <div
            style={{
              width: '100%',
              display: 'flex',
              marginTop: 20,
              justifyContent: 'center',
            }}>
            <Pagination
              count={maxPageNumber + 1}
              onChange={(e, val) => setActivePage(val)}
              size="small"
              color="secondary"
              itemProp={{style: {color: 'white'}}}></Pagination>
          </div>
        </Container>
      </Container>
    </>
  );
}
