import _ from 'lodash';
import actions from './actions';

const initialState = {
  data: {},
  error: null,
  isLoading: false,
};

const defaultObj = {};
export default function categoriesReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case actions.SET_STATE: {
      return {...action.payload};
    }
    case actions.UPDATE_STATE: {
      return {...state, ...action.payload};
    }
    case actions.CLEAR_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
