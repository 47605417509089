import React, {useState, useEffect} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Container,
  makeStyles,
  Grid,
  Avatar,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  loadSingleArticle,
  loadSingleArticleId,
} from 'services/articles';
import _ from 'lodash';
import moment from 'moment';
import renderHTML from 'react-render-html';
import {Helmet} from 'react-helmet';

import CustomTopNav from 'components/layout/TopNav/CutomTopNav';
import Swal from 'sweetalert2';
import LikesAndComments from 'components/LikesAndComments';
import ShareButtons from 'components/ShareButtons';

const useStyle = makeStyles((theme) => ({
  root: {
    color: '#fff',
    width: '100%',
    paddingBottom: 60,
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function CourseDetail() {
  const classes = useStyle();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLoggedIn = useSelector(
    (state) => _.get(state, 'user.authorized'),
    false,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [timeEstimation, setTimeEstimation] = useState(0);
  const {slug} = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    if (slug) {
      setIsLoading(true);
      loadSingleArticle(slug).then((res) => {
        if (res.isSuccess) {
          if (res.data) {
            setData(res.data);
            let cont = _.get(res, 'data.content', '');
            cont = cont.replace(/<[^>]*>/g, ' ');
            cont = cont.replace(/\s+/g, ' ');
            cont = cont.trim();

            setTimeEstimation(
              parseInt(String(cont).split(' ').length / 150 + 1),
            );
            // console.log('wordcount', String(cont).split(' ').length);
          } else {
            loadSingleArticleId(slug).then((res) => {
              if (res.data) {
                setData(res.data);
                let cont = _.get(res, 'data.content', '');
                cont = cont.replace(/<[^>]*>/g, ' ');
                cont = cont.replace(/\s+/g, ' ');
                cont = cont.trim();

                setTimeEstimation(
                  parseInt(String(cont).split(' ').length / 150 + 1),
                );
                // console.log('wordcount', String(cont).split(' ').length);
              } else {
                Swal.fire(
                  'Ups',
                  'Maaf, link tidak ditemukan',
                  'error',
                ).then(() => {
                  history.replace('/articles');
                });
              }
            });
          }
        } else {
          Swal.fire(
            'Ups',
            'Maaf, link tidak ditemukan',
            'error',
          ).then(() => {
            history.replace('/articles');
          });
        }
        setIsLoading(false);
      });
    }
  }, [history, slug]);
  const [mode, setMode] = useState('serial');

  return (
    <>
      {isMobile && (
        <CustomTopNav
          title=""
          showBack
          shadow={false}
          backURL="/articles"
          // showAuth={false}
        />
      )}
      <Container className={classes.root}>
        <Helmet
          titleTemplate="PALS | %s"
          title={(data && data.title) || 'ooo'}
        />
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{textAlign: 'center'}}>
          <Grid item md={7}>
            {isLoading ? (
              <Skeleton />
            ) : (
              <div className="badge badge-warning mt-3">
                <Typography
                  variant="h5"
                  style={{marginBottom: 0, color: '#333'}}>
                  {data.categoryLabel}
                </Typography>
              </div>
            )}
            <br />
            <br />
            <Typography variant="h1" color="textPrimary">
              {isLoading ? <Skeleton /> : data.title}
            </Typography>
            <br />
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={2}
              style={{textAlign: 'left', fontSize: 14}}>
              <Grid item>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    {isLoading ? (
                      <Skeleton variant="circle" />
                    ) : (
                      <Avatar
                        src={_.get(data, 'authorPhotoURL', '')}
                        style={{width: 28, height: 28}}></Avatar>
                    )}
                  </Grid>
                  <Grid item>
                    <div>
                      {isLoading ? (
                        <Skeleton width="100px" />
                      ) : (
                        _.get(data, 'authorName', '')
                      )}
                      <br />
                      {moment(data.publisedAt).format('LL')}
                      <span style={{margin: '0 7px'}}>.</span>{' '}
                      {timeEstimation + ' min read'}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <ShareButtons
                title={data.title}
                url={window.location.href}
              />
            </Grid>
            <Grid
              item
              style={{
                textAlign: 'center',
                width: '100%',
                overflow: 'hidden',
                paddingTop: 20,
                paddingBottom: 20,
              }}>
              {data.isShowCoverInDetail && (
                <img
                  src={data.coverImageURL}
                  style={{width: '100%'}}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="center">
          <Grid
            item
            md={7}
            sm={8}
            xs={12}
            style={{
              fontSize: isMobile ? 16 : 18,
              overflow: 'hidden',
            }}>
            <div>
              {isLoading ? (
                <Box>
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} width="60%" />
                  <br />
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} width="60%" />
                  <br />
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} width="60%" />
                </Box>
              ) : (
                renderHTML(data.content || '')
              )}
            </div>
            <br />
            <Grid
              container
              justify="space-between"
              style={
                isMobile
                  ? {
                      position: 'fixed',
                      bottom: 0,
                      left: 0,
                      padding: 10,
                      backgroundColor: '#424242',
                      // borderTop: 'solid 1px',
                      boxShadow:
                        '4px 2px 4px -1px rgb(0 0 0 / 20%), 4px 4px 5px 4px rgb(0 0 0 / 14%), 4px 1px 10px 4px rgb(0 0 0 / 12%)',
                    }
                  : {}
              }>
              <Grid
                item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <LikesAndComments
                  collectionId={data.articleId}
                  collectionType="articles"
                />
              </Grid>
              <ShareButtons
                title={data.title}
                url={window.location.href}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
