import React, {memo, useState, useEffect} from 'react';
import {
  useMediaQuery,
  Container,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core';
import _ from 'lodash';
import Sidebar from 'components/layout/Sidebar';
import TopBar from 'components/layout/TopBar';
import {useSelector, useDispatch} from 'react-redux';

const theme = createMuiTheme({
  typography: {
    fontFamily: ['"Fira Sans"', 'Arial'].join(','),
  },
  palette: {
    primary: {
      main: '#2E4462',
      contrastText: '#fff',
    },
    secondary: {
      main: '#EB5757',
      contrastText: '#fff',
    },
    info: {
      main: '#65C1CA',
      contrastText: '#fff',
    },
  },
  '.MuiIconButton-root': {
    outline: 'none',
  },
});
const AdminLayout = memo(({children = ''}) => {
  const [sidebarOpen, setsidebarOpen] = useState(true);
  const dispatch = useDispatch();
  // const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          // height: '100%',
          display: 'flex',
          backgroundColor: 'whitesmoke',
        }}>
        <Sidebar
          open={sidebarOpen}
          setOpen={setsidebarOpen}></Sidebar>
        <div
          className="page-container"
          style={{paddingLeft: sidebarOpen && matches ? 230 : 0}}>
          <TopBar
            onMenuToggle={() => setsidebarOpen(!sidebarOpen)}
            sidebarOpen={sidebarOpen}
          />
          <Container
            style={{
              margin: 0,
              padding: 16,
              width: '100%',

              maxWidth: 'none',
            }}>
            {children}
          </Container>
        </div>
      </div>
    </ThemeProvider>
  );
});

export default AdminLayout;
