import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Grid,
  makeStyles,
  Container,
  Button,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import MyTable from 'components/MyTable';
import axios from 'axios';
import {deletePaper, restorePaper} from 'services/papers';
import moment from 'moment';
import _ from 'lodash';
import SearchInput from 'components/SearchInput';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import Swal from 'sweetalert2';
import ModalPaperEditor from './ModalPaperEditor';

export default function PapersAdminPage() {
  let paperData = useSelector((state) =>
    Object.entries(
      _.get(state, 'papers.data', {}),
    ).map(([key, itemData]) => ({key, ...itemData})),
  );
  const dataLoading = useSelector((state) =>
    _.get(state, 'papers.loading', false),
  );
  const [editorVisibel, setEditorVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const loadPapers = () => {
    dispatch({
      type: 'papers/LOAD_DATA',
      payload: {
        status: 'all',
      },
    });
  };
  useEffect(() => {
    loadPapers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = useState(false);

  const handleDelete = (paperId, isPermanent) => {
    Swal.fire({
      html: 'Loading...',
      didOpen: () => {
        Swal.showLoading();
      },
    });
    deletePaper(paperId, isPermanent).then((res) => {
      console.log(res);
      if (res.isSuccess) {
        Swal.fire('Berhasil', 'Artikel Berhasil dihapus', 'success');
        loadPapers();
      }
    });
  };
  const handleRestore = (paperId) => {
    Swal.fire({
      html: 'Loading...',
      didOpen: () => {
        Swal.showLoading();
      },
    });
    restorePaper(paperId).then((res) => {
      setLoading(false);
      console.log(res);
      if (res.isSuccess) {
        Swal.fire(
          'Berhasil',
          'Artikel Berhasil dikembalikan',
          'success',
        );
        loadPapers();
      }
    });
  };

  const columns = [
    {
      dataKey: 'no',
      label: 'No.',
      maxWidth: 40,
      align: 'center',
      cellRender: (cellData, rowData, rowIndex) => rowIndex + 1,
    },
    {dataKey: 'title', label: 'Title', minWidth: 270},
    {
      dataKey: 'status',
      label: 'Status',
      minWidth: 100,
      align: 'center',
      cellRender: (cellData, rowData, rowIndex) => (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <span className="badge badge-primary">{cellData}</span>
        </div>
      ),
    },
    {
      dataKey: 'categoryLabel',
      label: 'Category',
      minWidth: 150,
      align: 'center',
      cellRender: (cellData, rowData, rowIndex) => (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <div>{cellData}</div>
        </div>
      ),
    },
    {
      dataKey: 'actions',
      label: 'Actions',
      minWidth: 150,
      align: 'center',
      format: (value) => value.toFixed(2),
      cellRender: (cellData, rowData, rowIndex) => (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setSelectedPaper(rowData);
              setEditorVisible(true);
            }}
            color="primary"
            style={{fontSize: 9, padding: 5}}>
            Edit
          </Button>

          {!Boolean(_.get(rowData, 'deletedAt', 0)) ? (
            <Grid item>
              <Tooltip title="Delete " arrow>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    Swal.fire({
                      title: 'yakin nih?',
                      text: 'Artikel akan dihapus?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, delete it!',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleDelete(rowData.paperId);
                      }
                    });
                  }}
                  style={{color: 'red'}}
                  size="small">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : (
            <Grid item>
              <Tooltip title="Restore " arrow>
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  onClick={() => handleRestore(rowData.paperId)}
                  // style={{color: 'red'}}
                  size="small">
                  <RestoreIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Hard Delete" arrow>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    Swal.fire({
                      title: 'yakin nih?',
                      text: 'Artikel akan dihapus?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, delete it!',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleDelete(rowData.paperId, true);
                      }
                    });
                  }}
                  style={{color: 'red'}}
                  size="small">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </div>
      ),
    },
  ];
  const [searchText, setSearchText] = useState('');
  const [filterActive, setFilterActive] = useState('all');
  const [selectedPaper, setSelectedPaper] = useState({});
  if (filterActive === 'all')
    paperData = paperData.filter((item) => !item.deletedAt);
  else paperData = paperData.filter((item) => item.deletedAt);

  paperData = paperData.filter((paper) =>
    String(paper.title)
      .toLowerCase()
      .includes(searchText.toLowerCase()),
  );
  return (
    <>
      <div>
        <Grid container justify="space-between" className="mb-1">
          <Grid item md={6} sm={12}>
            <Grid container spacing={1}>
              <Grid item>
                <h2>Papers</h2>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setSelectedPaper({});
                    setEditorVisible(true);
                  }}
                  size="small"
                  color="secondary"
                  style={
                    {
                      // backgroundColor: '#fbad18',
                      // height: 30,
                      // padding: 10,
                    }
                  }>
                  Create New
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} sm={12}>
            <Grid container justify="flex-end">
              <Grid item>
                <SearchInput
                  onSelectedFilter={(val) => {
                    setFilterActive(val);
                  }}
                  onSearch={(val) => setSearchText(val)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <MyTable
          rows={paperData}
          columns={columns}
          loading={dataLoading}
        />
      </div>
      <ModalPaperEditor
        visible={editorVisibel}
        onDismiss={() => setEditorVisible(false)}
        onSubmitted={() => {
          setEditorVisible(false);
          loadPapers();
        }}
        paperData={selectedPaper}
      />
    </>
  );
}
