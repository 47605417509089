import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Controller, FormProvider} from 'react-hook-form';
import {createBanner, updateBanner} from 'services/banners';
import Swal from 'sweetalert2';
import Resizer from 'react-image-file-resizer';
import {databaseRef, storageRef} from 'services/firebase';
import {Paper} from '@material-ui/core';

const bannerScheme = yup
  .object()
  .noUnknown(true)
  .required()
  .shape({
    imageURL: yup
      .string()
      .typeError('Format label salah')
      .required('label kategori harus diisi')
      .max(1000),
    targetLink: yup
      .string()
      .typeError('Format label salah')
      .required('label kategori harus diisi')
      .max(1000),
  });

export default function FormDialog({
  visible = false,
  onDismiss = () => {},
  onSubmitted = () => {},
  bannerData = null,
}) {
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(bannerScheme),
  });
  const {
    control,
    watch,
    getValues,
    handleSubmit,
    errors,
    register,
    reset,
    setValue,
  } = methods;

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (visible) reset(bannerData || {});
  }, [bannerData, visible, reset]);
  const handleClose = () => {
    onDismiss();
  };
  const onSubmit = (data) => {
    setIsLoading(true);
    console.log(data);
    if (!Boolean(bannerData && bannerData.key)) {
      createBanner(data)
        .then((res) => {
          setIsLoading(false);
          if (res.isSuccess) {
            console.log('berhasil');
            onSubmitted();
            Swal.fire(
              'Yeay',
              'Berhasil menambah kategori',
              'success',
            );
          } else console.log('gagal');
        })
        .catch((error) => {
          console.error(error);
          Swal.fire('Upps...', 'Gagal buat kategory', 'error');
          setIsLoading(false);
        });
    } else {
      updateBanner(data, bannerData.key)
        .then((res) => {
          setIsLoading(false);
          if (res.isSuccess) {
            console.log('berhasil');
            onSubmitted();
            Swal.fire(
              'Yeay',
              'Berhasil mengubah kategori',
              'success',
            );
          } else console.log('gagal');
        })
        .catch((error) => {
          console.error(error);
          Swal.fire('Upps...', 'Gagal update kategory', 'error');
          setIsLoading(false);
        });
    }
  };
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1500,
        562,
        'webp',
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        'file',
      );
    });

  const handleFileChange = async (e) => {
    try {
      let file = e.target.files[0] || {};
      file = await resizeFile(file);

      const fileRef = storageRef.child(databaseRef.push().key);
      fileRef.put(file).then((res) => {
        res.ref.getDownloadURL().then((url) => {
          setValue('imageURL', url);
          console.log('uploaded', url);
        });
      });
    } catch (err) {
      console.error(err);
    }
  };
  const [permalink, setPermalink] = useState('');
  const hiddenFileInput = React.useRef(null);

  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };
  return (
    <Dialog
      open={visible}
      maxWidth="md"
      fullWidth={true}
      onClose={onDismiss}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">New Banner</DialogTitle>
      <DialogContent>
        <Paper
          variant="outlined"
          style={{
            padding: 10,
            borderRadius: 10,
            textAlign: 'center',
          }}>
          <div>Banner Image</div>
          <br />

          <Controller
            control={control}
            defaultValue={''}
            name="imageURL"
            render={({onChange, onBlur, value}) => {
              if (value)
                return (
                  <img
                    src={value}
                    width="100%"
                    // style={{width: 200, height: 200}}
                  />
                );
              else return <></>;
            }}
          />

          <input
            type="file"
            ref={hiddenFileInput}
            style={{display: 'none'}}
            onChange={handleFileChange}
            name="fileToUpload"
            id="fileToUpload"
          />
          <div>
            <Button onClick={handleClick}>Upload</Button>
          </div>
        </Paper>

        <Controller
          control={control}
          defaultValue=""
          name="targetLink"
          render={({onChange, onBlur, value}) => (
            <TextField
              autoFocus
              value={value}
              onChange={onChange}
              margin="dense"
              label="Target Link ketika diklik"
              type="text"
              fullWidth
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isLoading}
          onClick={handleClose}
          color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
          color="primary">
          {isLoading ? (
            <i className="fas fa-spinner fa-pulse"></i>
          ) : (
            'Simpan'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
