// import firebaseAuth from '@react-native-firebase/auth';
// import database from '@react-native-firebase/database';
import {functionUrl} from 'helper/functions';
import axios from 'axios';
import {
  firebaseAuth,
  databaseRef,
  storageRef,
  serverTimestamp,
} from 'services/firebase';
import _ from 'lodash';
import Swal from 'sweetalert2';

export async function login(email, password) {
  try {
    const data = await firebaseAuth().signInWithEmailAndPassword(
      email,
      password,
    );
    return {
      status: 'success',
      data,
    };
  } catch (error) {
    // console.log('error dari user services', error);
    return {status: 'error', error};
  }
}

export async function loginWithToken(token) {
  try {
    const data = await firebaseAuth().signInWithCustomToken(token);
    return {
      status: 'success',
      data,
    };
  } catch (error) {
    return {status: 'error', error};
  }
}

export async function currentAccount() {
  let userLoaded = false;
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser);
      }
      const unsubscribe = auth.onAuthStateChanged((user) => {
        userLoaded = true;
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }
  return getCurrentUser(firebaseAuth());
}
export async function sendPasswordResetEmail(email) {
  try {
    await firebaseAuth().sendPasswordResetEmail(email);

    Swal.fire(
      'Berhasil',
      'Email reset password telah dikirim ke ' + email,
      'success',
    );
  } catch (error) {
    Swal.fire(
      'Upps',
      'Email reset password gagal dikirim ke ' + email,
      'error',
    );
  }
}
export async function adminCheck() {
  try {
    const idTokenResult = await firebaseAuth().currentUser.getIdTokenResult();
    // console.log('idTokenResult', idTokenResult);
    // Confirm the user is an Admin.
    return Boolean(_.get(idTokenResult, 'claims.admin', false));
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getFirebaseIdToken() {
  try {
    let currentUser = firebaseAuth().currentUser;
    if (currentUser) {
      const token = await currentUser.getIdToken();
      return token || null;
    } else {
      //attempt to load user
      let userLoaded = false;
      function getCurrentUser(auth) {
        return new Promise((resolve, reject) => {
          if (userLoaded) {
            resolve(firebaseAuth().currentUser);
          }
          const unsubscribe = auth.onAuthStateChanged((user) => {
            userLoaded = true;
            unsubscribe();
            resolve(user);
          }, reject);
        });
      }

      currentUser = await getCurrentUser(firebaseAuth());
      if (currentUser) {
        const token = await currentUser.getIdToken();
        return token || null;
      } else {
        return null;
      }
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getServerTimeOffset() {
  try {
    const snapshot = await databaseRef
      .child('/.info/serverTimeOffset')
      .once('value');
    return snapshot.val() || 1; // kalau 0 anggap aja error
  } catch (error) {
    return 1; // biar ada angka nya aja
  }
}

export async function logout() {
  return firebaseAuth()
    .signOut()
    .then(() => true); // kalau 0 anggap aja error
}

export const googleProvider = new firebaseAuth.GoogleAuthProvider();
googleProvider.setCustomParameters({prompt: 'select_account'});
export const facebookProvider = new firebaseAuth.FacebookAuthProvider();
export const signInWithGoogle = () =>
  firebaseAuth()
    .signInWithPopup(googleProvider)
    .then((res) => {
      // console.log(res);
      return {status: 'success', data: res, error: null};
    })
    .catch(function (error) {
      // Handle Errors here.
      if (
        error.code === 'auth/account-exists-with-different-credential'
      )
        Swal.fire(
          'Upps, terjadi kesalahan',
          'Alamat email telah terdaftar dengan metode login selain facebook',
          'error',
        );
      else
        Swal.fire('Upps, terjadi kesalahan', error.message, 'error');
      console.error(error);
      return {error: error, status: 'error', data: {}};
      // ...
    });
export const signInWithFacebook = () =>
  firebaseAuth()
    .signInWithPopup(facebookProvider)
    .then(function (data) {
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      return {status: 'success', data, error: null};
      // ...
    })
    .catch(function (error) {
      // Handle Errors here.
      if (
        error.code === 'auth/account-exists-with-different-credential'
      )
        Swal.fire(
          'Upps, terjadi kesalahan',
          'Alamat email telah terdaftar dengan metode login selain facebook',
          'error',
        );
      else
        Swal.fire('Upps, terjadi kesalahan', error.message, 'error');
      console.error(error);
      return {error: error, status: 'error', data: {}};
      // ...
    });

export async function signUpWithEmailAndPassword(payload) {
  try {
    let response = await axios.post(
      `${functionUrl}/auth/registration`,
      payload,
    );

    return {
      data: _.get(response, 'data', {}),
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}

export async function updateProfile(payload) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/auth/update-profile`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );

    return {
      data: _.get(response, 'data', {}),
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}

export async function sendEmailVerification() {
  const user = firebaseAuth().currentUser;
  try {
    const response = user
      .sendEmailVerification()
      .then(function () {
        // Email sent.
        Swal.fire({
          title: 'Email Verifikasi Berhasil Dikirim!',
          icon: 'info',
          html:
            'Silahkan cek inbox email Anda dan klik link verifikasi pada email ',
          showCloseButton: true,
          focusConfirm: false,
          cancelButtonText: 'Cancel',
        });
        console.log('email verifikasi terkirim');
      })
      .catch(function (error) {
        // An error happened.
        console.error(error);
        Swal.fire({
          title: 'Email Verifikasi Berhasil Dikirim!',
          icon: 'error',
          html: 'Email verifikasi gagal dirkirim, coba lagi nanti',
          showCloseButton: true,
          focusConfirm: false,
          cancelButtonText: 'Cancel',
        });
      });
    return {
      data: _.get(response, 'data', {}),
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    Swal.fire({
      title: 'Upps!',
      icon: 'error',
      html: 'Email verifikasi gagal dirkirim, coba lagi nanti',
      showCloseButton: true,
      focusConfirm: false,
      cancelButtonText: 'Cancel',
    });
    return {error};
  }
}
