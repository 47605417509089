import React, {memo, useState, useEffect} from 'react';
import useEventListener from 'helper/useEventListener';
import TopNav from 'components/layout/TopNav';
import MobileDrawer from 'components/layout/MobileDrawer';
import Footer from 'components/layout/Footer';
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core/styles';
import AppNavigation from 'components/AppNavigation';
import {useMediaQuery} from '@material-ui/core';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {useHistory} from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    minHeight: '100vh',
    overflow: 'hidden',
    paddingTop: 60,
  },
}));

const AuthLayout = memo(({children = ''}) => {
  const classes = useStyles();
  const history = useHistory();

  const matches = useMediaQuery((theme) =>
    theme.breakpoints.up('md'),
  );

  return (
    <div className={classes.root}>
      {matches && <TopNav />}
      {children}
      {matches && <Footer />}
    </div>
  );
});

export default AuthLayout;
