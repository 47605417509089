import {
  all,
  take,
  takeEvery,
  takeLeading,
  put,
  call,
  fork,
  select,
  cancel,
} from 'redux-saga/effects';
import {buffers, eventChannel} from 'redux-saga';
import {useSnackbar} from 'notistack';

import {
  login,
  loginWithToken,
  logout,
  getServerTimeOffset,
  getUserDataandRoles,
  signInWithFacebook,
  signInWithGoogle,
  adminCheck,
} from '../../services/user';

import actions from './actions';
import {
  firebaseAuth,
  databaseRef,
  storageRef,
} from 'services/firebase';
import {history} from 'index';

import {syncChannel} from '../syncChannel';
import Swal from 'sweetalert2';
import _ from 'lodash';

export const getRef = (rsf, pathOrRef) =>
  typeof pathOrRef === 'string' ? rsf.child(pathOrRef) : pathOrRef;

// const defaultTransform = (data) => data.value;

function authChannel() {
  const lchannel = eventChannel((emit) => {
    const unsubscribe = firebaseAuth().onAuthStateChanged(
      (user) => emit({user}),
      (error) => emit({error}),
    );

    return unsubscribe;
  });

  return lchannel;
}

export function* LOGIN({payload}) {
  const {email, password, provider} = payload;

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      loginError: '',
    },
  });
  if (provider === 'facebook') {
    const res = yield call(signInWithFacebook);
    if (res && res.status === 'success') {
      Swal.fire('Yeay', 'Berhasil login', 'success');
    } else {
      Swal.fire('Yeay', 'Gagal login', 'error');
    }
    const isAdmin = yield call(adminCheck);

    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        loginError: _.get(res, 'error.message', ''),
        email: _.get(res, 'data.user.email', ''),
        phoneNumber: _.get(res, 'data.user.phoneNumber', ''),
        name: _.get(res, 'data.user.displayName', ''),
        emailVerified: _.get(res, 'data.user.emailVerified', false),
        photoURL: _.get(res, 'data.user.photoURL', false),
        id: _.get(res, 'data.user.uid', ''),
        authorized: Boolean(_.get(res, 'data.user.uid', '')),
        isAdmin: isAdmin,
      },
    });
  } else if (provider === 'google') {
    const res = yield call(signInWithGoogle);

    if (res.status === 'success') {
      Swal.fire('Yeay', 'Berhasil login', 'success');
    } else {
      Swal.fire('Upps', 'Gagal login', 'error');
    }
    const isAdmin = yield call(adminCheck);
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        loginError: _.get(res, 'error.message', ''),
        email: _.get(res, 'data.user.email', ''),
        name: _.get(res, 'data.user.displayName', ''),
        phoneNumber: _.get(res, 'data.user.phoneNumber', ''),
        emailVerified: _.get(res, 'data.user.emailVerified', false),
        photoURL: _.get(res, 'data.user.photoURL', false),
        id: _.get(res, 'data.user.uid', ''),
        authorized: Boolean(_.get(res, 'data.user.uid', '')),
        isAdmin: isAdmin,
      },
    });
  } else {
    const res = yield call(login, email, password);
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        loginError: res.error || '',
      },
    });

    if (res.status === 'success') {
      const isAdmin = yield call(adminCheck);
      yield put({
        type: 'user/SET_STATE',
        payload: {
          emailVerified: _.get(res, 'data.user.emailVerified', false),
          email: _.get(res, 'data.user.email'),
          name: _.get(res, 'data.user.displayName'),
          phoneNumber: _.get(res, 'data.user.phoneNumber', ''),
          photoURL: _.get(res, 'data.user.photoURL', false),
          id: _.get(res, 'data.user.uid', ''),
          authorized: Boolean(_.get(res, 'data.user.uid', '')),
          isAdmin: isAdmin,
        },
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: res.error && res.error.message,
      });
    }
  }
}

export function* LOGIN_WITH_TOKEN({payload}) {
  const {token} = payload;
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const res = yield call(loginWithToken, token);
  if (res.status === 'success') {
    // TODO: set bugsnag user
    const isAdmin = yield call(adminCheck);
    yield put({
      type: 'user/SET_STATE',
      payload: {
        emailVerified: _.get(res, 'data.user.emailVerified', false),
        email: _.get(res, 'data.user.email'),
        name: _.get(res, 'data.user.displayName'),
        phoneNumber: _.get(res, 'data.user.phoneNumber', ''),
        photoURL: _.get(res, 'data.user.photoURL', false),
        id: _.get(res, 'data.user.uid', ''),
        authorized: Boolean(_.get(res, 'data.user.uid', '')),
        isAdmin: isAdmin,
      },
    });
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    });
  }
}

export function* LOGOUT() {
  const sessionId = yield select(
    (state) => state.session && state.session.key,
  );
  yield put({
    type: 'session/CLOSE_SESSION',
  });

  yield call(logout);
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  });
  // notifikasi ke semua listener untuk berhenti
  yield put({
    type: 'user/LOGGED_OUT',
  });
}

export function* LOAD_CURRENT_ACCOUNT(user) {
  // ini dipanggil ketika user nya sudah logged in, misalnya ketika tab baru dll

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const isAdmin = yield call(adminCheck);

  try {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: user.uid,
        emailVerified: _.get(user, 'emailVerified', false),
        name: user.displayName,
        photoURL: user.photoURL,
        phoneNumber: user.phoneNumber,
        email: user.email,
        authorized: true,
        isAdmin: isAdmin,
      },
    });

    const serverTimeOffset = yield call(getServerTimeOffset);

    yield put({
      type: 'user/SET_STATE',
      payload: {
        serverTimeOffset: serverTimeOffset || 1,
      },
    });
  } catch (error) {
    console.error(error);
    // logout aja?
  }
  // jadi kalaupun error, loading nya tetap false

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

function* syncUserSaga() {
  const lchannel = yield call(authChannel);

  while (true) {
    try {
      const {error, user} = yield take(lchannel);
      // ambil data user nya di sini?
      // kalau logout sebelum data user nya keambil gimana?
      console.log('saga error : ', error);
      // if (user) yield put(syncUser(user));
      // else yield put(syncError(error));
      if (error) {
        console.log('ada error');
        // kalau error
        // hitungannya ke logout?
        yield put({
          type: 'user/LOGGED_OUT',
        });
      } else if (user) {
        // console.log('ada user');
        // kalau log in? kalau user nya ada?
        yield call(LOAD_CURRENT_ACCOUNT, user);
      } else {
        console.log('nggak ada user');

        yield put({
          type: 'user/SET_STATE',
          payload: {
            id: '',
            email: '',
            role: '',
            authorized: false, // kalau nggak ada role nya, balikin ke login
            emailVerified: false,
            loading: false,
          },
        });
        yield put({
          type: 'user/LOGGED_OUT',
        });
      }
    } catch (error) {
      // TODO: handle error nya
    }
  }
}

const defaultTransform = (data) => data.value;

export function* LOGGED_OUT({}) {
  // history.push('/login');

  yield put({
    type: 'user/CLEAR_STATE',
  });
}

export function* PROFILE_CHANGE_DATA({payload}) {
  const userId = yield select((state) => state.user && state.user.id);
  if (userId) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        profileLoading: true,
      },
    });
    if (payload.name) {
      yield call(async () => {
        await databaseRef
          .child(`/users/${userId}/name`)
          .set(payload.name);
      });
    }
    if (payload.newProfpic) {
      const filename = `${databaseRef.push().key}.${getFileExtension(
        payload.newProfpic.filename,
      )}`;

      yield call(async () => {
        const uploadPromise = new Promise((resolve, reject) => {
          const uploadtask = storageRef
            .child(`/user-uploads/${userId}/${filename}`)
            .putString(payload.newProfpic.data, 'base64');
          uploadtask.on(
            'state_changed',
            (data) => {
              console.log(
                'sedang upload : ',
                Math.ceil(
                  (data.bytesTransferred / data.totalBytes) * 100,
                ),
              );
            },
            (error) => {
              console.log('upload gagal', error);
              reject(error);
            },
            (data) => {
              resolve(data);
            },
          );
        });
        await uploadPromise;
      });

      const newUrl = yield call(async () =>
        storageRef
          .child(`/user-uploads/${userId}/${filename}`)
          .getDownloadURL(),
      );

      yield call(async () => {
        await databaseRef
          .child(`/users/${userId}/photoURL`)
          .set(newUrl);
      });
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        profileLoading: false,
      },
    });
  }
}
const getFileExtension = (filename) => {
  const ext = /^.+\.([^.]+)$/.exec(filename);
  return ext === null ? '' : ext[1];
};

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN_WITH_TOKEN, LOGIN_WITH_TOKEN),
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGOUT, LOGOUT),

    // takeLeading(actions.LISTEN_USER_DATA, LISTEN_USER_DATA),
    takeEvery(actions.PROFILE_CHANGE_DATA, PROFILE_CHANGE_DATA),
    // takeEvery(actions.UPDATE_TOKEN, UPDATE_TOKEN),
    takeEvery(actions.LOGGED_OUT, LOGGED_OUT),

    syncUserSaga(),
    // syncTokenSaga(),
  ]);
}
