import React, {useState} from 'react';
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Container,
  makeStyles,
  Button,
  Grid,
  Divider,
  input,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import _ from 'lodash';
import {
  signInWithGoogle,
  signInWithFacebook,
  sendPasswordResetEmail,
} from 'services/user';
import FacebookIcon from '@material-ui/icons/Facebook';
import CustomTopNav from 'components/layout/TopNav/CutomTopNav';
import Swal from 'sweetalert2';

const useStyle = makeStyles((theme) => ({
  root: {
    color: 'white',
    textAlign: 'center',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function SignIn() {
  const classes = useStyle();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(
    (state) => _.get(state, 'user.authorized'),
    false,
  );
  const isVerified = useSelector(
    (state) => _.get(state, 'user.emailVerified'),
    false,
  );
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const isLoading = useSelector((state) =>
    _.get(state, 'user.loading', false),
  );

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLogin = (provider) => {
    dispatch({
      type: 'user/LOGIN',
      payload: {
        email,
        password,
        provider,
      },
    });
  };

  const [redirectToReferrer, setRedirectToReferrer] = React.useState(
    false,
  );
  React.useEffect(() => {
    if (isLoggedIn && isVerified) {
      setRedirectToReferrer(true);
    } else if (isLoggedIn && !isVerified) {
      history.push({
        pathname: '/email-verify',
        state: {from: location.state},
      });
    }
  }, [history, isLoggedIn, isVerified, location.state]);

  let {from} = location.state || {from: {pathname: '/'}};
  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }
  if (from === '/profile') from = '/';
  return (
    <>
      {isMobile && (
        <CustomTopNav
          title="Sign In"
          showBack
          showAuth={false}
          backURL={from || '/'}
        />
      )}
      <Container className={classes.root}>
        <Grid container justify="center" style={{marginTop: 50}}>
          <Grid item md={4} xs={10}>
            <div
              style={{
                marginTop: 20,
                marginBottom: 30,
                color: 'white',
                fontWeight: 600,
                fontSize: 36,
                textAlign: 'center',
                display: !isMobile ? 'block' : 'none',
              }}>
              Sign In
            </div>
            <form onSubmit={() => handleLogin()}>
              <div style={{textAlign: 'left'}}>
                <label>Email</label>
                <input
                  className="form-control"
                  name="email"
                  size="large"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <br />
              <div style={{marginBottom: 10, textAlign: 'left'}}>
                <label>Password</label>
                <input
                  className="form-control"
                  name="password"
                  type="password"
                  size="large"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="text-right">
                <Button
                  style={{textTransform: 'capitalize'}}
                  onClick={() => {
                    Swal.fire({
                      title: 'Masukkan alamat email Anda',
                      input: 'text',
                      inputAttributes: {
                        autocapitalize: 'off',
                      },
                      showCancelButton: true,
                      confirmButtonText: 'Reset',
                      showLoaderOnConfirm: true,
                      preConfirm: (email) => {
                        return sendPasswordResetEmail(email).then(
                          (res) => {
                            // console.log(res);
                          },
                        );
                      },
                      allowOutsideClick: () => !Swal.isLoading(),
                    });
                  }}>
                  <small>Lupa Password?</small>
                </Button>
              </div>
              <br />
              <Button
                // onClick={handleLogin}
                onClick={(e) => {
                  e.preventDefault();
                  handleLogin();
                }}
                color="secondary"
                type="submit"
                variant="contained"
                style={{width: '100%', height: 36}}>
                {!isLoading ? (
                  'Masuk'
                ) : (
                  <i className="fa fa-refresh fa-spin"></i>
                )}
              </Button>
            </form>
            <br />
            <hr />
            <div
              style={{
                marginTop: -18,
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                width: '100%',
              }}>
              <div style={{backgroundColor: '#2E4462', width: 20}}>
                or
              </div>
            </div>
            <br />
            <Grid container style={{width: '100%'}}>
              {/* <Grid item xs={6}>
                <Button
                  onClick={() => handleLogin('facebook')}
                  variant="contained"
                  startIcon={<i className="fa fa-facebook" />}
                  style={{width: '100%', marginRight: 5}}>
                  Facebook
                </Button>
              </Grid> */}
              <Grid item xs={12}>
                <Button
                  onClick={() => handleLogin('google')}
                  variant="contained"
                  startIcon={<i className="fa fa-google" />}
                  style={{width: '100%', marginLeft: 5}}>
                  Google
                </Button>
              </Grid>
            </Grid>
            <br />
            <Link
              style={{color: 'white'}}
              to={{pathname: 'signup', state: location.state}}>
              Create an account
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
