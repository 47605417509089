import {hot} from 'react-hot-loader/root';
import React, {useEffect, useState, Suspense} from 'react';
import {BrowserRouter, Route, Redirect} from 'react-router-dom';
import Switch from 'react-router-transition-switch';

import Layout from './layouts';
import Loader from 'components/layout/Loader';
import NotFoundPage from 'pages/system/404';
import HomePage from 'pages/Home';
import CoursesPage from 'pages/Courses';
import ArticlesPage from 'pages/Articles';
import ArticleDetailPage from 'pages/Articles/detail';
import CourseDetailPage from 'pages/Courses/detail';
import CourseRoomPage from 'pages/Courses/room';
import PapersPage from 'pages/Papers';
import PaperDetailPage from 'pages/Papers/detail';
import Videos from 'pages/Videos';
import VideoDetailPage from 'pages/Videos/detail';
import Toolkits from 'pages/Toolkits';
import ToolkitDetail from 'pages/Toolkits/detail';
import AboutPage from 'pages/About';
import EmailVerifyPage from 'pages/EmailVerify';
import ContactUsPage from 'pages/ContactUs';
import SignInPage from 'pages/SignIn';
import SignUpPage from 'pages/SignUp';
import ProfilePage from 'pages/Profile';

import DashboardPage from 'pages/AdminPages/Dashboard';
import ArticlesAdminPage from 'pages/AdminPages/Articles';
import ArticleEditorAdminPage from 'pages/AdminPages/Articles/editor';
import CoursesAdminPage from 'pages/AdminPages/Courses';
import CourseEditorAdminPage from 'pages/AdminPages/Courses/editor';
import UsersAdminPage from 'pages/AdminPages/Users';
import AuthorsAdminPage from 'pages/AdminPages/Authors';
import VideosAdminPage from 'pages/AdminPages/Videos';
import PapersAdminPage from 'pages/AdminPages/Papers';
import ToolkitsAdminPage from 'pages/AdminPages/Toolkits';
import CategoriesAdminPage from 'pages/AdminPages/Categories';
import BannersAdminPage from 'pages/AdminPages/Banners';
import {checkWebsiteVersion, getAppConfig} from 'services/configs';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import ComingSoonPage from 'pages/ComingSoon';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';

const routes = [
  {
    path: '/',
    Component: HomePage,
    exact: true,
    layout: 'main',
  },
  {
    path: '/coming-soon',
    Component: ComingSoonPage,
    exact: true,
    layout: '',
  },

  {
    path: '/courses',
    Component: CoursesPage,
    exact: true,
    layout: 'main',
  },
  {
    path: '/courses/:courseId',
    Component: CourseDetailPage,
    exact: true,
    layout: 'main',
  },
  {
    path: '/courses/:courseId/rooms',
    Component: CourseRoomPage,
    exact: true,
    layout: 'main',
  },
  {
    path: '/articles',
    Component: ArticlesPage,
    exact: true,
    layout: 'main',
  },
  {
    path: '/articles/:slug',
    Component: ArticleDetailPage,
    exact: true,
    layout: 'main',
  },
  {
    path: '/papers',
    Component: PapersPage,
    exact: true,
    layout: 'main',
  },
  {
    path: '/papers/:slug',
    Component: PaperDetailPage,
    exact: true,
    layout: 'main',
  },
  {
    path: '/videos',
    Component: Videos,
    exact: true,
    layout: 'main',
  },
  {
    path: '/videos/:slug',
    Component: VideoDetailPage,
    exact: true,
    layout: 'main',
  },
  {
    path: '/toolkits',
    Component: Toolkits,
    exact: true,
    layout: 'main',
  },
  {
    path: '/toolkits/:slug',
    Component: ToolkitDetail,
    exact: true,
    layout: 'main',
  },
  {
    path: '/about',
    Component: AboutPage,
    exact: true,
    layout: 'main',
  },
  {
    path: '/contact-us',
    Component: ContactUsPage,
    exact: true,
    layout: 'main',
  },
  {
    path: '/profile',
    Component: ProfilePage,
    exact: true,
    layout: 'main',
  },
  {
    path: '/email-verify',
    Component: EmailVerifyPage,
    exact: true,
    layout: 'auth',
  },
  {
    path: '/signin',
    Component: SignInPage,
    exact: true,
    layout: 'auth',
  },
  {
    path: '/signup',
    Component: SignUpPage,
    exact: true,
    layout: 'auth',
  },
  {
    path: '/dashboard/analytics',
    Component: DashboardPage,
    exact: true,
    layout: 'admin',
  },
  {
    path: '/dashboard/users',
    Component: UsersAdminPage,
    exact: true,
    layout: 'admin',
  },
  {
    path: '/dashboard/authors',
    Component: AuthorsAdminPage,
    exact: true,
    layout: 'admin',
  },
  {
    path: '/dashboard/courses',
    Component: CoursesAdminPage,
    exact: true,
    layout: 'admin',
  },
  {
    path: '/dashboard/courses/:courseId',
    Component: CourseEditorAdminPage,
    exact: true,
    layout: 'admin',
  },
  {
    path: '/dashboard/courses/create',
    Component: CourseEditorAdminPage,
    exact: true,
    layout: 'admin',
  },
  {
    path: '/dashboard/articles',
    Component: ArticlesAdminPage,
    exact: true,
    layout: 'admin',
  },
  {
    path: '/dashboard/articles/:articleId',
    Component: ArticleEditorAdminPage,
    exact: true,
    layout: 'admin',
  },
  {
    path: '/dashboard/articles/create',
    Component: ArticleEditorAdminPage,
    exact: true,
    layout: 'admin',
  },
  {
    path: '/dashboard/papers',
    Component: PapersAdminPage,
    exact: true,
    layout: 'admin',
  },
  {
    path: '/dashboard/videos',
    Component: VideosAdminPage,
    exact: true,
    layout: 'admin',
  },
  {
    path: '/dashboard/toolkits',
    Component: ToolkitsAdminPage,
    exact: true,
    layout: 'admin',
  },
  {
    path: '/dashboard/categories',
    Component: CategoriesAdminPage,
    exact: true,
    layout: 'admin',
  },
  {
    path: '/dashboard/banners',
    Component: BannersAdminPage,
    exact: true,
    layout: 'admin',
  },
];

const UpdateDialog = ({visible = false}) => {
  const [loading, setLoading] = useState(false);
  return (
    <Dialog
      open={visible}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        PALS has updated!
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Hai PALS, Kami telah melakukan bebarapa pembaharuan. Yuk
          terapkan sekarang!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={() => {
            setLoading(true);
            window.location.reload();
          }}
          // color="primary"
          autoFocus>
          Terapkan
        </Button>
      </DialogActions>
    </Dialog>
  );
};
function Router({history = null}) {
  // const isMaintenance = useSelector((state) =>
  //   _.get(state, 'configs.maintenance', false),
  // );

  const [isnew, setIsNew] = useState(false);
  useEffect(() => {
    checkWebsiteVersion()
      .then((val) => {
        if (val) {
          console.log('ada update');
        }
        setIsNew(val);
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Switch>
          {routes.map(({path, Component, exact, layout}) => (
            <Route path={path} key={path} exact={exact}>
              <Layout layout={layout}>
                <Component layout={layout} />
              </Layout>
            </Route>
          ))}
          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
      <UpdateDialog visible={isnew} />
    </BrowserRouter>
  );
}

export default hot(Router);
