import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import _ from 'lodash';
import moment from 'moment';
import {loadComments, createComment} from '../../services/comments';
import {
  useMediaQuery,
  Grid,
  TextareaAutosize,
  ListItemAvatar,
  Avatar,
  Icon,
} from '@material-ui/core';
import {useSelector} from 'react-redux';
import Swal from 'sweetalert2';
import {Link, useHistory} from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    '& .MuiDrawer-paperAnchorBottom': {
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
    },
  },
  list: {
    width: 400,
    maxWidth: '100%',
    maxHeight: 'calc(100vh - 176px)',
    overflow: 'auto',
  },
  fullList: {
    width: 'auto',
  },
  inline: {
    display: 'inline',
  },
});

export default function CommentDrawer({
  onDismiss = () => {},
  collectionId = '',
  onCount = () => {},
  collectionType = '',
  onOpen = () => {},
  visible = false,
}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const matches = useMediaQuery((theme) =>
    theme.breakpoints.up('md'),
  );

  const history = useHistory();
  const isLoggedIn = useSelector(
    (state) => _.get(state, 'user.authorized'),
    false,
  );
  const [commentsData, setCommentsData] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    if (collectionId)
      loadComments({collectionId}).then((res) => {
        if (res.isSuccess) {
          setCommentsData(res.data || []);
          onCount((res.data || []).length);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({...state, [anchor]: open});
  };

  const commentList = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <List>
        {commentsData.map((comment, index) => (
          <ListItem alignItems="flex-start" key={index}>
            <ListItemAvatar>
              <Avatar
                alt="PALS"
                src={_.get(comment, 'createdBy.photoURL', '')}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Grid container justify="space-between">
                  <Grid item>
                    {_.get(comment, 'createdBy.name', '')}
                  </Grid>
                  <Grid item>
                    <small>
                      {moment(
                        _.get(comment, 'createdAt', Date.now()),
                      ).format('DD/MM/YYYY')}
                    </small>
                  </Grid>
                </Grid>
              }
              secondary={comment.text}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          classes={{root: classes.root}}
          // style={
          //   !matches
          //     ? {minWidth: 100, borderRadius: 10}
          //     : {minWidth: 400}
          // }
          anchor={!matches ? 'bottom' : 'right'}
          open={visible}
          onOpen={onOpen}
          onClose={onDismiss}>
          <h4
            style={{
              textAlign: 'center',
              marginTop: 20,
              marginBottom: 10,
            }}>
            Comments
          </h4>
          <Divider />
          {commentsData.length === 0 && (
            <div
              style={{
                height: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              Belum ada komentar nih, yuk berikan komentarmu!
            </div>
          )}
          {commentList('right')}
          <Divider />

          <Grid
            container
            justify="center"
            alignItems="center"
            style={{color: '#333', padding: 10, width: '100%'}}>
            <Grid item md={12} xs={12}>
              <TextareaAutosize
                aria-label="minimum height"
                style={{
                  width: '100%',
                  resize: 'none',
                  borderRadius: 6,
                  padding: 5,
                }}
                value={commentText}
                disabled={submitting || !isLoggedIn}
                onChange={(e) => setCommentText(e.target.value)}
                rowsMin={5}
                placeholder="Tulis komentar disini"
              />

              <div style={{textAlign: 'right'}}>
                {/* <span style={{color: 'white'}}>
                  Silakan login terlebih dahulu.
                </span> */}
                {isLoggedIn ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={submitting || !isLoggedIn}
                    endIcon={
                      submitting ? (
                        <i className="fa fa-spin fa-spinner" />
                      ) : (
                        <Icon>send</Icon>
                      )
                    }
                    onClick={() => {
                      setSubmitting(true);
                      createComment({
                        text: commentText,
                        collectionId,
                        collectionType,
                      })
                        .then((res) => {
                          if (res.isSuccess) {
                            loadComments({collectionId}).then(
                              (res2) => {
                                if (res2.isSuccess) {
                                  setCommentsData(res2.data || []);
                                  setCommentText('');
                                }
                                setSubmitting(false);
                              },
                            );
                          } else {
                            setSubmitting(false);
                            Swal.fire(
                              'Upps',
                              'Komentar gagal dibuat',
                              'error',
                            );
                          }
                        })
                        .catch((error) => {
                          setSubmitting(false);
                          Swal.fire(
                            'Upps',
                            'Komentar gagal dibuat',
                            error.message || '',
                          );
                        });
                    }}>
                    Send
                  </Button>
                ) : (
                  <Button
                    style={{textTransform: 'capitalize'}}
                    variant="contained"
                    onClick={() => {
                      history.push({
                        pathname: '/signin',
                        state: {from: window.location.pathname},
                      });
                    }}
                    color="secondary"
                    disabled={isLoggedIn}>
                    Login Disini!
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
