import {getFirebaseIdToken} from './user';
import {functionUrl} from 'helper/functions';
import axios from 'axios';
import _ from 'lodash';
import {serverTimestamp} from './firebase';

export async function loadVideos(payload) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.get(
      `${functionUrl}/videos?all=${payload.status === 'all'}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const data = _.get(response, 'data', {});
    // console.log('artikel dari fn,', response);
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function loadPublishedVideos() {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.get(`${functionUrl}/videos`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    const data = _.get(response, 'data', {});
    // console.log('artikel dari fn,', response);
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function loadSingleVideo(slug) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.get(
      `${functionUrl}/videos/single?slug=${slug}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const data = _.get(response, 'data', {});
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function loadSingleVideoId(videoId) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.get(
      `${functionUrl}/videos/single?id=${videoId}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const data = _.get(response, 'data', {});
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function createVideo(payload) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/videos`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const data = _.get(response, 'data', {});
    console.log('create artikel dari fn,', response);
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function deleteVideo(videoId, isPermanent = false) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/videos/delete`,
      {isPermanent, videoId},
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const data = _.get(response, 'data', {});
    console.log('create artikel dari fn,', response);
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function restoreVideo(videoId) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/videos/restore`,
      {videoId},
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const data = _.get(response, 'data', {});
    console.log('create artikel dari fn,', response);
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function updateVideo(payload) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.put(
      `${functionUrl}/videos/${payload.videoId}`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const data = _.get(response, 'data', {});
    console.log('update artikel dari fn,', response);
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
