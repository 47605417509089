import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Grid,
  makeStyles,
  Container,
  Button,
  Paper,
  Typography,
  Divider,
  Input,
  InputLabel,
  FormControl,
  Select,
  Avatar,
  IconButton,
} from '@material-ui/core';
import {Line} from 'react-chartjs-2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {databaseRef} from 'services/firebase';
import moment from 'moment';
import {
  getCollectionsCount,
  getFavoriteCollections,
} from 'services/dashboard';
import {loadLatestComments} from 'services/comments';
import MyTable from 'components/MyTable';
import _ from 'lodash';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const options = {
  responsive: true,
  maintainAspectRatio: false,

  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};
const getDaysArray = ({month, year}) => {
  var monthIndex = month - 1; // 0..11 instead of 1..12
  var names = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  var date = new Date(year, monthIndex, 1);
  var result = [];
  while (date.getMonth() === monthIndex) {
    result.push(date.getDate() + '-' + names[date.getDay()]);
    date.setDate(date.getDate() + 1);
  }
  return result;
};
const commentsColumns = [
  {
    dataKey: 'createdBy',
    label: 'User',
    width: 230,
    cellRender: (cellData, rowData, rowIndex) => (
      <Grid container spacing={1}>
        <Grid item>
          <Avatar alt={cellData.name} src={cellData.photoURL} />
        </Grid>
        <Grid item>
          {cellData.name} <br />{' '}
          <small>
            {moment(
              _.get(rowData, `createdAt._seconds`, 0) * 1000,
            ).format('lll')}
          </small>
        </Grid>
      </Grid>
    ),
  },
  {
    dataKey: 'text',
    label: 'Comment',
    minWidth: 170,
    justify: 'flex-end',
    flex: 2,
    cellRender: (cellData, rowData, rowIndex) => (
      <small>{cellData} </small>
    ),
  },
  {
    dataKey: 'action',
    label: '',
    width: 30,
    align: 'right',
    cellRender: (cellData, rowData, rowIndex) => (
      <IconButton
        onClick={() =>
          window.open(
            `http://${window.location.host}/${rowData.collectionType}/${rowData.collectionId}`,
            '_blank',
          )
        }
        size="small"
        color="primary"
        variant="outlined">
        <OpenInNewIcon />
      </IconButton>
    ),
  },
];
const favColumns = [
  {
    dataKey: 'title',
    label: 'Content',
    width: 280,
    cellRender: (cellData, rowData, rowIndex) => (
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Avatar alt={cellData} src={rowData.coverImageURL} />
        </Grid>
        <Grid item xs={10}>
          {cellData} <br />{' '}
          <small className="badge badge-warning">
            {rowData.collectionType}
          </small>
        </Grid>
      </Grid>
    ),
  },
  {
    dataKey: 'count',
    label: 'Likes',
    width: 15,
    align: 'center',
    cellRender: (cellData, rowData, rowIndex) => (
      <div className="badge badge-danger">{cellData} </div>
    ),
  },
  {
    dataKey: 'action',
    label: '',
    width: 15,
    align: 'right',
    cellRender: (cellData, rowData, rowIndex) => (
      <IconButton
        onClick={() =>
          window.open(
            `http://${window.location.host}/${rowData.collectionType}/${rowData.collectionId}`,
            '_blank',
          )
        }
        size="small"
        color="primary"
        variant="outlined">
        <OpenInNewIcon />
      </IconButton>
    ),
  },
];
const dataset = ({statsData, month, year}) => {
  const arrLikes = getDaysArray({month, year}).map((day, index) =>
    moment() < moment(`${year}-${month}-${index + 1}`)
      ? null
      : (statsData[0] && statsData[0][index + 1]) || 0,
  );
  const arrComments = getDaysArray({month, year}).map((day, index) =>
    moment() < moment(`${year}-${month}-${index + 1}`)
      ? null
      : (statsData[1] && statsData[1][index + 1]) || 0,
  );
  const arrViews = getDaysArray({month, year}).map((day, index) =>
    moment() < moment(`${year}-${month}-${index + 1}`)
      ? null
      : (statsData[2] && statsData[2][index + 1]) || 0,
  );
  // console.log(arrLikes, arrComments, arrViews);
  return [
    {
      label: '# of Likes',
      data: arrLikes,
      backgroundColor: 'rgb(255, 99, 132)',
    },
    {
      label: '# of Comments',
      data: arrComments,
      backgroundColor: 'rgb(54, 162, 235)',
    },
    {
      label: '# of Views',
      data: arrViews,
      backgroundColor: 'rgb(75, 192, 192)',
    },
  ];
};
export default function DashboardPage() {
  const classes = useStyles();

  const [likes, setLikes] = useState({});
  const [views, setViews] = useState({});
  const [comments, setComments] = useState({});

  const [year, setYear] = useState(moment().year());
  const [month, setMonth] = useState(moment().month() + 1);
  const [countData, setCountData] = useState({});
  const [statsData, setStatsData] = useState({});
  const [latestComments, setLatestComments] = useState([]);
  const [favCollections, setFavCollections] = useState([]);
  useEffect(() => {
    const promise1 = databaseRef
      .child('analytics/likesByDate' + `/${year}/${month}`)
      .once('value');
    const promise2 = databaseRef
      .child('analytics/commentsByDate' + `/${year}/${month}`)
      .once('value');
    const promise3 = databaseRef
      .child('analytics/viewsByDate' + `/${year}/${month}`)
      .once('value');

    Promise.all([promise1, promise2, promise3]).then((snapshots) => {
      const arrStats = [];
      for (let index = 0; index < snapshots.length; index++) {
        const snapshot = snapshots[index];
        const val = snapshot.val();
        arrStats.push(val);
      }

      setStatsData(arrStats);
    });
  }, [year, month]);

  useEffect(() => {
    getCollectionsCount().then((res) => {
      if (res.isSuccess) {
        setCountData(res.data);
      }
    });
    loadLatestComments(10).then((res) => {
      if (res.isSuccess) {
        setLatestComments(Object.values(res.data || {}));
      }
    });
    getFavoriteCollections().then((res) => {
      if (res.isSuccess) {
        setFavCollections(res.data);
      }
    });
  }, []);
  return (
    <div>
      <Grid container justify="space-between" className="mb-1">
        <Grid item md={6} sm={12}>
          <Grid container spacing={1}>
            <Grid item>
              <h2>Dashboard</h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sm={12}>
          <Grid container justify="flex-end">
            {/* <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              startIcon={<i className={`fa fa-refresh fa-spin`} />}>
              Sync
            </Button> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12} sm={6}>
          <Paper
            elevation={3}
            style={{
              padding: 10,
              backgroundColor: '#2aa55c',
            }}>
            <Typography style={{color: 'white'}} variant="h6">
              Jumlah User
            </Typography>
            <Typography
              style={{color: 'white', textAlign: 'right'}}
              variant="h4">
              {countData.usersCount || 0}
            </Typography>
          </Paper>
        </Grid>
        <Grid item md={4} xs={12} sm={6}>
          <Paper
            elevation={3}
            style={{
              padding: 10,
              backgroundColor: '#f15722',
            }}>
            <Typography style={{color: 'white'}} variant="h6">
              Jumlah Cerita
            </Typography>
            <Typography
              style={{color: 'white', textAlign: 'right'}}
              variant="h4">
              {countData.articlesCount || 0}
            </Typography>
          </Paper>
        </Grid>
        <Grid item md={4} xs={12} sm={6}>
          <Paper
            elevation={3}
            style={{
              padding: 10,
              backgroundColor: '#fbad18',
            }}>
            <Typography style={{color: 'white'}} variant="h6">
              Jumlah Kelas
            </Typography>
            <Typography
              style={{color: 'white', textAlign: 'right'}}
              variant="h4">
              {countData.coursesCount || 0}
            </Typography>
          </Paper>
        </Grid>
        <Grid item md={4} xs={12} sm={6}>
          <Paper
            elevation={3}
            style={{
              padding: 10,
              backgroundColor: '#5cc3cb',
            }}>
            <Typography style={{color: 'white'}} variant="h6">
              Jumlah Video
            </Typography>
            <Typography
              style={{color: 'white', textAlign: 'right'}}
              variant="h4">
              {countData.videosCount || 0}
            </Typography>
          </Paper>
        </Grid>
        <Grid item md={4} xs={12} sm={6}>
          <Paper
            elevation={3}
            style={{
              padding: 10,
              backgroundColor: '#f8ada7',
            }}>
            <Typography style={{color: 'white'}} variant="h6">
              Jumlah Jerat Data
            </Typography>
            <Typography
              style={{color: 'white', textAlign: 'right'}}
              variant="h4">
              {countData.papersCount || 0}
            </Typography>
          </Paper>
        </Grid>
        <Grid item md={4} xs={12} sm={6}>
          <Paper
            elevation={3}
            style={{
              padding: 10,
              backgroundColor: '#26213c',
            }}>
            <Typography style={{color: 'white'}} variant="h6">
              Jumlah Amunisi
            </Typography>
            <Typography
              style={{color: 'white', textAlign: 'right'}}
              variant="h4">
              {countData.toolkitsCount || 0}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h6">Daily Statistics</Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <FormControl style={{width: '100%'}}>
                    <InputLabel htmlFor="status">Bulan</InputLabel>
                    <Select
                      native
                      style={{width: '100%'}}
                      defaultValue={moment().month() + 1}
                      // value={value}
                      onChange={(e) => setMonth(e.target.value)}
                      input={
                        <Input id="status" style={{width: '100%'}} />
                      }>
                      {moment.months().map((item, index) => (
                        <option key={item} value={index + 1}>
                          {item}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl style={{width: '100%'}}>
                    <InputLabel htmlFor="status">Tahun</InputLabel>
                    <Select
                      native
                      style={{width: '100%'}}
                      defaultValue={moment().year()}
                      // value={value}
                      onChange={(e) => setYear(e.target.value)}
                      input={
                        <Input id="status" style={{width: '100%'}} />
                      }>
                      {[2020, 2021, 2022, 2023, 2024].map(
                        (item, index) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ),
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Paper elevation={3} style={{padding: 10, height: 330}}>
            <Line
              data={{
                labels: getDaysArray({month, year}),
                datasets: dataset({statsData, month, year}),
              }}
              options={options}
              style={{}}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={6}>
          <h4>Favorite Contents</h4>
          <Paper className={classes.root}>
            <MyTable
              rows={favCollections}
              columns={favColumns}
              height="500px"
              withFooter={false}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <h4>Latest Comments</h4>
          <Paper className={classes.root}>
            <MyTable
              rows={latestComments}
              columns={commentsColumns}
              height="500px"
              withFooter={false}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
