import {
  all,
  take,
  takeEvery,
  put,
  call,
  fork,
  select,
  cancel,
} from 'redux-saga/effects';

import actions from './actions';

import {loadBanners} from 'services/banners';

export function* LOAD_DATA({payload}) {
  yield put({
    type: 'banners/UPDATE_STATE',
    payload: {
      isLoading: true,
    },
  });

  const result = yield call(loadBanners, {...payload});

  yield put({
    type: 'banners/UPDATE_STATE',
    payload: {
      data: result.data || {},
      error: result.error,
    },
  });
  yield put({
    type: 'banners/UPDATE_STATE',
    payload: {
      isLoading: false,
    },
  });
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_DATA, LOAD_DATA)]);
}
