import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Grid,
  makeStyles,
  Container,
  Button,
  IconButton,
  Avatar,
} from '@material-ui/core';
import MyTable from 'components/MyTable';
import moment from 'moment';
import _ from 'lodash';
import SearchInput from 'components/SearchInput';
import DeleteIcon from '@material-ui/icons/Delete';
import BlockIcon from '@material-ui/icons/Block';
import {
  setUserRole,
  deleteUser,
  disableUser,
  enableUser,
} from 'services/users';
import Swal from 'sweetalert2';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import InfoIcon from '@material-ui/icons/Info';

export default function UsersPage() {
  const usersData = useSelector((state) =>
    Object.entries(
      _.get(state, 'users.data', {}),
    ).map(([key, itemData]) => ({key, ...itemData})),
  );
  const loading = useSelector((state) =>
    _.get(state, 'users.isLoading', false),
  );
  const [editorVisibel, setEditorVisible] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'users/LOAD_DATA',
      payload: {size: 500},
    });
  }, [dispatch]);
  // useEffect(() => {
  //   if (loading) {
  //     Swal.fire({
  //       title: 'Sedang mengambil data user',
  //       // html: 'I will close in <b></b> milliseconds.',
  //       didOpen: () => Swal.showLoading(),
  //     });
  //   } else {
  //     Swal.close();
  //   }
  // }, [loading]);

  const columns = [
    {
      dataKey: 'no',
      label: 'No.',
      width: 50,
      align: 'center',
      cellRender: (cellData, rowData, rowIndex) => rowIndex + 1,
    },
    {
      dataKey: 'avatar',
      label: '',
      width: 50,
      align: 'center',
      cellRender: (cellData, rowData, rowIndex) => (
        <Avatar alt={rowData.displayName} src={rowData.photoURL} />
      ),
    },
    {dataKey: 'displayName', label: 'Nama', minWidth: 170, flex: 1},
    {
      dataKey: 'email',
      label: 'Email',
      minWidth: 170,
      flex: 1,
      cellRender: (cellData, rowData, rowIndex) => (
        <div
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
          }}>
          <div>{cellData}</div>
          {rowData.emailVerified ? (
            <VerifiedUser style={{fontSize: 15, color: 'green'}} />
          ) : (
            <HourglassEmptyIcon style={{fontSize: 15}} />
          )}
        </div>
      ),
    },
    {
      dataKey: 'role',
      label: 'Role',
      minWidth: 80,
      flex: 1,
      cellRender: (cellData, rowData, rowIndex) =>
        _.get(rowData, 'customClaims.admin', false) ? (
          <span className="badge badge-success">admin</span>
        ) : (
          <span className="badge badge-secondary">visitor</span>
        ),
    },
    {
      dataKey: 'actions',
      label: 'Actions',
      width: 200,
      minWidth: 200,
      align: 'center',
      cellRender: (cellData, rowData, rowIndex) => {
        return (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            {_.get(rowData, 'customClaims.admin', false) ? (
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => {
                  Swal.fire({
                    title: 'Sedang mengubah role data user',
                    // html: 'I will close in <b></b> milliseconds.',
                    didOpen: () => Swal.showLoading(),
                  });
                  setUserRole({
                    email: rowData.email,
                    role: 'visitor',
                  }).then((res) => {
                    if (res && res.isSuccess) {
                      Swal.fire(
                        'Yeay',
                        'Berhasul set sebagai visitor',
                        'success',
                      );
                      dispatch({
                        type: 'users/LOAD_DATA',
                        payload: {size: 500},
                      });
                    }
                  });
                }}
                style={{fontSize: 9, padding: 5}}>
                Set as Visitor
              </Button>
            ) : (
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => {
                  Swal.fire({
                    // title: 'Sedang mengubah role data user',
                    html: 'Sedang mengubah role data user',
                    didOpen: () => Swal.showLoading(),
                  });
                  setUserRole({
                    email: rowData.email,
                    role: 'admin',
                  }).then((res) => {
                    if (res && res.isSuccess) {
                      Swal.fire(
                        'Yeay',
                        'Berhasul set sebagai admin',
                        'success',
                      );
                      dispatch({
                        type: 'users/LOAD_DATA',
                        payload: {size: 500},
                      });
                    }
                  });
                }}
                style={{fontSize: 9, padding: 5}}>
                Set as Admin
              </Button>
            )}

            <Grid item>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  Swal.fire({
                    title: 'yakin nih?',
                    text:
                      'Setelah dihapus tidak dapat dikembalikan lagi!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: 'Sedang menghapus user',
                        // html: 'I will close in <b></b> milliseconds.',
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: () => Swal.showLoading(),
                      });
                      deleteUser({userId: rowData.uid}).then(
                        (res) => {
                          if (res.isSuccess) {
                            Swal.fire(
                              'Yeay',
                              'Success hapus user',
                              'success',
                            );
                            dispatch({type: 'users/LOAD_DATA'});
                          }
                        },
                      );
                    }
                  });
                }}
                color="secondary"
                size="small">
                <DeleteIcon />
              </IconButton>
            </Grid>
            {rowData.disabled ? (
              <Grid item>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    Swal.fire({
                      title: 'yakin nih?',
                      text:
                        'Setelah di enable user dapat digunakan kembali!',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, enable it!',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        Swal.fire({
                          title: 'Sedang meng-enable user',
                          // html: 'I will close in <b></b> milliseconds.',
                          timer: 2000,
                          timerProgressBar: true,
                          didOpen: () => Swal.showLoading(),
                        });
                        enableUser({userId: rowData.uid}).then(
                          (res) => {
                            if (res.isSuccess) {
                              Swal.fire(
                                'Yeay',
                                'Success enable user',
                                'success',
                              );
                              dispatch({type: 'users/LOAD_DATA'});
                            }
                          },
                        );
                      }
                    });
                  }}
                  size="small"
                  color="primary">
                  <SettingsBackupRestoreIcon />
                </IconButton>
              </Grid>
            ) : (
              <Grid item>
                {' '}
                <IconButton
                  aria-label="delete"
                  onClick={() =>
                    Swal.fire({
                      title: 'yakin nih?',
                      text:
                        'Setelah di disable user tidak dapat digunakan hingga di enable kembali!',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, disable it!',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        Swal.fire({
                          title: 'Sedang men-disable user',
                          // html: 'I will close in <b></b> milliseconds.',
                          timer: 2000,
                          timerProgressBar: true,
                          didOpen: () => Swal.showLoading(),
                        });
                        disableUser({userId: rowData.uid}).then(
                          (res) => {
                            if (res.isSuccess) {
                              Swal.fire(
                                'Yeay',
                                'Success disable user',
                                'success',
                              );
                              dispatch({type: 'users/LOAD_DATA'});
                            }
                          },
                        );
                      }
                    })
                  }
                  color="secondary"
                  size="small">
                  <BlockIcon />
                </IconButton>
                <IconButton color="primary" size="small">
                  <InfoIcon />
                </IconButton>
              </Grid>
            )}
          </div>
        );
      },
    },
  ];

  const [searchText, setSearchText] = useState('');

  let filteredData = usersData.filter((item) => {
    return String(item.displayName)
      .toLowerCase()
      .includes(String(searchText).toLowerCase());
  });
  return (
    <>
      <div>
        <Grid container justify="space-between" className="mb-1">
          <Grid item md={6} sm={12}>
            <Grid container spacing={1}>
              <Grid item>
                <h2>Users</h2>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} sm={12}>
            <Grid container justify="flex-end">
              <Grid item>
                <SearchInput
                  statuses={[]}
                  onSearch={(text) => {
                    setSearchText(text);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <MyTable
          rows={filteredData}
          columns={columns}
          loading={loading}
        />
      </div>
    </>
  );
}
