import React, {useState, useEffect} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Container,
  makeStyles,
  Button,
  Grid,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {
  loadSingleCourseId,
  loadCourses,
  isJoined,
  joinCourse,
} from 'services/courses';

import moment from 'moment';
import CustomTopNav from 'components/layout/TopNav/CutomTopNav';

import Swal from 'sweetalert2';
import _ from 'lodash';
import ShareButtons from 'components/ShareButtons';
const useStyle = makeStyles((theme) => ({
  root: {
    color: 'white',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    paddingTop: 30,
  },
}));

export default function CourseDetail() {
  const classes = useStyle();
  let history = useHistory();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const {courseId} = useParams();
  const [data, setData] = useState({});
  const [coursesList, setCoursesList] = useState([]);

  useEffect(() => {
    if (courseId) {
      setIsLoading(true);
      loadSingleCourseId(courseId).then((res) => {
        if (res.isSuccess) {
          if (res.data) {
            setData(res.data);
          } else {
            Swal.fire(
              'Ups',
              'Maaf, link tidak ditemukan',
              'error',
            ).then(() => {
              history.push('/courses');
            });
          }
        } else {
          Swal.fire(
            'Ups',
            'Maaf, link tidak ditemukan',
            'error',
          ).then(() => {
            history.push('/courses');
          });
        }
        setIsLoading(false);
      });
      isJoined({courseId})
        .then((res) => {
          if (res.isSuccess) setIsRegistered(res.data.isJoined);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [history, courseId]);

  useEffect(() => {
    if (data.isBundled && data.order === 0 && courseId) {
      loadCourses({isBundled: true, parentId: courseId}).then(
        (res) => {
          setCoursesList(res.data);
        },
      );
    } else {
      setCoursesList([]);
    }
  }, [
    data.isBundled,
    data.parentId,
    data.courseId,
    data.order,
    courseId,
  ]);

  const isLoggedIn = useSelector(
    (state) => _.get(state, 'user.authorized'),
    false,
  );
  const isVerified = useSelector(
    (state) => _.get(state, 'user.emailVerified'),
    false,
  );

  return (
    <>
      {isMobile && (
        <CustomTopNav
          title=""
          showBack
          backURL="/courses"
          // showAuth={false}
        />
      )}
      <Container className={classes.root}>
        <Grid container justify="center">
          <Grid item xs={6} md={2} style={{textAlign: 'center'}}>
            {isLoading || !Boolean(data.coverImageURL) ? (
              <Skeleton
                variant="rect"
                width="100%"
                height={170}
                style={{borderRadius: 16, overflow: 'hidden'}}
              />
            ) : (
              <Paper
                elevation={4}
                style={{
                  borderRadius: 16,
                  overflow: 'hidden',
                  minHeight: 160,
                  width: '100%',
                }}>
                <img
                  src={data.coverImageURL}
                  style={{width: '100%'}}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
        <br />
        <Grid container alignItems="center" justify="center">
          <Grid
            item
            md={8}
            sm={8}
            xs={12}
            style={{
              fontSize: isMobile ? 16 : 18,
              overflow: 'hidden',
            }}>
            <Grid container justify="space-between">
              <Grid
                item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}></Grid>
              {!isLoading && (
                <ShareButtons
                  title={data.title}
                  url={window.location.href}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} md={8}>
            <Paper
              elevation={3}
              className=" my-3 "
              style={{
                borderRadius: 8,
                overflow: 'hidden',
                backgroundColor: '#3333',
                padding: isMobile ? 8 : 16,
              }}>
              <Typography
                variant="h3"
                // color="textPrimary"
                className="my-3 text-center"
                style={{width: '100%'}}>
                {isLoading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    <Skeleton
                      variant="text"
                      height={30}
                      width="50%"
                    />
                  </div>
                ) : (
                  data.title || ''
                )}
              </Typography>
              <div className="text-center">
                {isLoading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    <Skeleton
                      variant="text"
                      height={20}
                      width="30%"
                    />
                  </div>
                ) : (
                  <div className="badge badge-warning ">
                    {data.categoryLabel || ''}
                  </div>
                )}
              </div>
              <br />
              <Grid container justify="space-between" spacing={2}>
                <Grid
                  item
                  xs={8}
                  style={{
                    textAlign: 'left',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}>
                  <div>
                    {' '}
                    <i
                      className="fas fa-calendar-day"
                      style={{
                        fontSize: 30,
                        marginRight: 20,
                        marginLeft: 10,
                      }}></i>
                  </div>

                  {isLoading ? (
                    <Skeleton
                      variant="text"
                      height={30}
                      width="50%"
                    />
                  ) : (
                    <div>
                      {moment(data.startAt).format('DD MMMM YYYY')}
                      <br />
                      Pukul {moment(data.startAt).format('HH.mm')}
                    </div>
                  )}
                </Grid>
                <Grid item xs={4} style={{textAlign: 'right'}}>
                  {isRegistered ? (
                    <Button
                      variant="outlined"
                      disabled={isLoading}
                      size="small"
                      onClick={() => {
                        history.push(`/courses/${courseId}/rooms`);
                      }}>
                      Masuk
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      disabled={isLoading}
                      size="small"
                      onClick={() => {
                        if (isLoggedIn && isVerified)
                          joinCourse({courseId})
                            .then((res) => {
                              if (res.isSuccess) {
                                Swal.fire(
                                  'Berhasil ',
                                  'Anda telah terdaftar',
                                  'success',
                                );
                                setIsRegistered(true);
                              }
                            })
                            .catch((error) => {
                              Swal.fire(
                                'Terjadi Kesalahan',
                                'Gagal Mendaftar',
                                'error',
                              );
                              console.error(error);
                            });
                        else if (!isLoggedIn)
                          history.push({
                            pathname: '/signin',
                            state: {from: '/courses/' + courseId},
                          });
                        else if (isLoggedIn && !isVerified)
                          history.push({
                            pathname: '/email-verify',
                            state: {from: '/courses/' + courseId},
                          });
                      }}>
                      Daftar
                    </Button>
                  )}
                </Grid>
              </Grid>
              <br />
              <Divider />
              <br />
              <Grid container justify="space-between" spacing={2}>
                <Grid
                  item
                  xs={12}
                  style={{textAlign: 'left', width: '100%'}}>
                  <Typography variant="h2" color="textPrimary">
                    Deskripsi
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {isLoading ? (
                      <>
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton variant="text" height={20} />
                        <Skeleton
                          variant="text"
                          height={20}
                          width="30%"
                        />
                      </>
                    ) : (
                      data.description
                    )}
                  </Typography>
                </Grid>
              </Grid>
              {data.isBundled && !isLoading && (
                <>
                  <br />
                  <Divider />
                  <br />
                  <Grid container justify="space-between">
                    <Grid
                      item
                      xs={12}
                      style={{
                        textAlign: 'left',
                        width: '100%',
                        marginBottom: 10,
                      }}>
                      <Typography variant="h2" color="textPrimary">
                        Seri Kelas
                      </Typography>
                    </Grid>

                    {(coursesList || []).map((item, index) => (
                      <Grid
                        key={index}
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        style={{textAlign: 'left', padding: 10}}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={3}
                            style={{
                              textAlign: 'center',
                            }}>
                            <img
                              src={item.coverImageURL || ''}
                              style={{
                                maxWidth: '100%',
                                width: '100%',
                                borderRadius: 10,
                                overflow: 'hidden',
                              }}
                            />
                          </Grid>
                          <Grid item xs={9}>
                            {' '}
                            <Typography
                              color="textPrimary"
                              className="mb-2"
                              variant="h4">
                              {item.title}
                            </Typography>
                            <Typography
                              color="textPrimary"
                              variant="body2"
                              className="desc-ellipsis">
                              {item.description}
                              {item.description}
                              {item.description}
                              {item.description}
                              {item.description}
                              {item.description}
                              {item.description}
                              {item.description}
                              {item.description}
                              {item.description}
                            </Typography>
                          </Grid>
                        </Grid>
                        <br />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
        {/* </div> */}

        <br />
      </Container>
    </>
  );
}
