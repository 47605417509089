import React from 'react';
import {useTheme} from '@material-ui/core/styles';
import {Grid, IconButton, useMediaQuery} from '@material-ui/core';
import _ from 'lodash';
import {
  TwitterShareButton,
  WhatsappShareButton,
  FacebookShareButton,
} from 'react-share';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';

export default function ShareButtons({title = '', url = ''}) {
  const theme = useTheme();

  return (
    <>
      <Grid item>
        <TwitterShareButton url={url} title={title}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="share"
            component="span">
            <TwitterIcon />
          </IconButton>
        </TwitterShareButton>
        <WhatsappShareButton url={url} title={title}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="share"
            component="span">
            <WhatsAppIcon />
          </IconButton>
        </WhatsappShareButton>
        <FacebookShareButton url={url} title={title}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="share"
            component="span">
            <FacebookIcon />
          </IconButton>
        </FacebookShareButton>
      </Grid>
    </>
  );
}
