import {getFirebaseIdToken} from './user';
import {functionUrl} from 'helper/functions';
import axios from 'axios';
import _ from 'lodash';
import {serverTimestamp} from './firebase';

export async function loadComments(payload) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.get(
      `${functionUrl}/comments?collectionId=${payload.collectionId}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const data = _.get(response, 'data', {});
    // console.log('artikel dari fn,', response);
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function loadLatestComments(size) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.get(
      `${functionUrl}/comments?size=${size}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const data = _.get(response, 'data', {});
    // console.log('comments dari fn,', response);
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}

export async function createComment(payload) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/comments`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const data = _.get(response, 'data', {});
    // console.log('create comment dari fn,', response);
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function deleteComment(commentId, isPermanent = false) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/comments/delete`,
      {isPermanent, commentId},
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const data = _.get(response, 'data', {});
    // console.log('create artikel dari fn,', response);
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function restoreComment(commentId) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/comments/restore`,
      {commentId},
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const data = _.get(response, 'data', {});
    // console.log('create artikel dari fn,', response);
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function updateComment(payload) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.put(
      `${functionUrl}/comments/${payload.commentId}`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const data = _.get(response, 'data', {});
    // console.log('update artikel dari fn,', response);
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
