import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import BookIcon from '@material-ui/icons/Book';
import HomeIcon from '@material-ui/icons/Home';
import ArticleIcon from '@material-ui/icons/Description';
import PaperIcon from '@material-ui/icons/MenuBook';
import LaptopChromebookIcon from '@material-ui/icons/LaptopChromebook';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import {useHistory} from 'react-router';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const MENU = [
  {
    label: 'Home',
    path: '/',
    icon: <HomeIcon />,
  },
  {
    label: 'Kelas',
    path: '/courses',
    icon: <LaptopChromebookIcon />,
  },
  {
    label: 'Artikel',
    path: '/articles',
    icon: <ArticleIcon />,
  },
  {
    label: 'Paper',
    path: '/papers',
    icon: <PaperIcon />,
  },
  {
    label: 'Video',
    path: '/videos',
    icon: <VideoLibraryIcon />,
  },
  {
    label: 'Toolkit',
    path: '/toolkits',
    icon: <FolderSpecialIcon />,
  },
];
const AUTH_MENU = [
  {
    label: 'Login',
    path: '/signin',
    icon: <MeetingRoomIcon />,
  },
];
export default function TemporaryDrawer({
  onDismiss = () => {},
  visible = false,
}) {
  const classes = useStyles();
  const history = useHistory();
  const list = () => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={onDismiss}
      onKeyDown={onDismiss}>
      <List>
        {MENU.map((menu, index) => (
          <ListItem
            button
            key={menu.label}
            onClick={() => history.push(menu.path)}>
            <ListItemIcon>{menu.icon}</ListItemIcon>
            <ListItemText primary={menu.label} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {AUTH_MENU.map((menu, index) => (
          <ListItem
            button
            key={menu.label}
            onClick={() => history.push(menu.path)}>
            <ListItemIcon>{menu.icon}</ListItemIcon>
            <ListItemText primary={menu.label} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Drawer anchor="left" open={visible} onClose={onDismiss}>
      {list()}
    </Drawer>
  );
}
