import actions from './actions';

const initialState = {
  id: '',
  loading: false,
  profileLoading: false,
};

const defaultObj = {};
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return {...state, ...action.payload};
    }
    case actions.CLEAR_STATE: {
      return initialState;
    }
    case actions.UPDATE_USER_DATA: {
      return {
        ...state,
        userData: {
          ...(state.userData || defaultObj),
          ...action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
}
