import axios from 'axios';
import {functionUrl} from 'helper/functions';
import _ from 'lodash';

export async function checkWebsiteVersion() {
  try {
    const response = await axios.get(`/version.txt`);

    if (!response || !response.data) {
      return false;
    }
    const currentVersion = `${response.data}`.trim();
    const envVersion = `${process.env.REACT_APP_CURRENT_GIT_SHA}`.trim();

    console.log(
      `currentVersion: ${currentVersion} envVersion: ${envVersion}`,
    );
    if (envVersion !== currentVersion) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

export async function getAppConfig() {
  try {
    let response = await axios.get(`${functionUrl}/configs/app`, {});
    const data = _.get(response, 'data', {});
    // console.log('config dari fn,', response);
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
