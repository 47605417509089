import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Controller, FormProvider} from 'react-hook-form';
import {createAuthor, updateAuthor} from 'services/authors';
import Swal from 'sweetalert2';
import {Paper} from '@material-ui/core';
import {storageRef, databaseRef} from 'services/firebase';
import Resizer from 'react-image-file-resizer';

const authorScheme = yup
  .object()
  .noUnknown(true)
  .required()
  .shape({
    name: yup
      .string()
      .typeError('Format label salah')
      .required('nama author harus diisi')
      .max(200),
    photoURL: yup
      .string()
      .typeError('Format photo url salah')
      .nullable()
      .max(200),
    description: yup
      .string()
      .typeError('Format desc salah')
      .nullable()
      .max(500),
  });

export default function FormDialog({
  visible = false,
  onDismiss = () => {},
  onSubmitted = () => {},
  authorData = null,
}) {
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(authorScheme),
  });
  const {
    control,
    watch,
    getValues,
    handleSubmit,
    errors,
    register,
    reset,
    setValue,
  } = methods;

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (visible) reset(authorData || {});
  }, [authorData, visible, reset]);
  const handleClose = () => {
    onDismiss();
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        150,
        150,
        'webp',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'file',
      );
    });

  const handleFileChange = async (e) => {
    try {
      let file = e.target.files[0] || {};
      file = await resizeFile(file);
      const fileName = `${file.name}_${databaseRef.push().key}`;
      const fileRef = storageRef.child(fileName);
      fileRef.put(file).then((res) => {
        res.ref.getDownloadURL().then((url) => {
          setValue('photoURL', url);
          console.log('uploaded', url);
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    console.log(data);
    if (!Boolean(authorData && authorData.key)) {
      createAuthor(data)
        .then((res) => {
          setIsLoading(false);
          if (res.isSuccess) {
            console.log('berhasil');
            onSubmitted();
            Swal.fire(
              'Yeay',
              'Berhasil menambah kategori',
              'success',
            );
          } else console.log('gagal');
        })
        .catch((error) => {
          console.error(error);
          Swal.fire('Upps...', 'Gagal buat kategory', 'error');
          setIsLoading(false);
        });
    } else {
      updateAuthor(data, authorData.key)
        .then((res) => {
          setIsLoading(false);
          if (res.isSuccess) {
            console.log('berhasil');
            onSubmitted();
            Swal.fire(
              'Yeay',
              'Berhasil mengubah kategori',
              'success',
            );
          } else console.log('gagal');
        })
        .catch((error) => {
          console.error(error);
          Swal.fire('Upps...', 'Gagal update kategory', 'error');
          setIsLoading(false);
        });
    }
  };
  const hiddenFileInput = React.useRef(null);

  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };
  return (
    <Dialog
      open={visible}
      onClose={onDismiss}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">New Author</DialogTitle>
      <DialogContent>
        <Paper
          variant="outlined"
          style={{
            padding: 10,
            borderRadius: 10,
            textAlign: 'center',
          }}>
          <div>Photo</div>
          <br />

          <Controller
            control={control}
            defaultValue={''}
            name="photoURL"
            render={({onChange, onBlur, value}) => {
              if (value)
                return (
                  <img
                    src={value}
                    style={{width: 200, height: 200}}
                  />
                );
              else return <></>;
            }}
          />

          <input
            type="file"
            ref={hiddenFileInput}
            style={{display: 'none'}}
            onChange={handleFileChange}
            name="fileToUpload"
            id="fileToUpload"
          />
          <div>
            <Button onClick={handleClick}>Upload</Button>
          </div>
        </Paper>

        <Controller
          control={control}
          defaultValue=""
          name="name"
          render={({onChange, onBlur, value}) => (
            <TextField
              autoFocus
              value={value}
              onChange={onChange}
              margin="dense"
              label="Nama"
              type="text"
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          defaultValue=""
          name="description"
          render={({onChange, onBlur, value}) => (
            <TextField
              autoFocus
              value={value}
              onChange={onChange}
              margin="dense"
              label="Description"
              type="text"
              fullWidth
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isLoading}
          onClick={handleClose}
          color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
          color="primary">
          {isLoading ? (
            <i className="fas fa-spinner fa-pulse"></i>
          ) : (
            'Simpan'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
