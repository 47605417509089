import {getFirebaseIdToken} from './user';
import {functionUrl} from 'helper/functions';
import axios from 'axios';
import _ from 'lodash';

export async function loadAuthors(payload) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.get(
      `${functionUrl}/authors`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    const data = _.get(response, 'data', {});
    // console.log('author dari fn,', response);
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}

export async function createAuthor(payload) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/authors/create`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    return {
      data: response,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function updateAuthor(payload, authorId) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.put(
      `${functionUrl}/authors/${authorId}`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    return {
      data: response,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}

export async function deleteAuthor(authorId, isPermanent = false) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/authors/delete`,
      {isPermanent, authorId},
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    return {
      data: response,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function restoreAuthor(authorId) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/authors/restore`,
      {authorId},
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    return {
      data: response,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
