import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Grid,
  makeStyles,
  Container,
  Button,
  IconButton,
  Avatar,
} from '@material-ui/core';
import MyTable from 'components/MyTable';
import moment from 'moment';
import _ from 'lodash';
import ModalAuthorEditor from './ModalAuthorEditor';
import SearchInput from 'components/SearchInput';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import {deleteAuthor, restoreAuthor} from 'services/authors';
import Swal from 'sweetalert2';

function createData(name, code, population, size) {
  const density = population / size;
  return {name, code, population, size, density};
}
export default function AuthorsPage() {
  let authorsData = useSelector((state) =>
    Object.entries(
      _.get(state, 'authors.data', {}),
    ).map(([key, itemData]) => ({key, ...itemData})),
  );
  const [editorVisibel, setEditorVisible] = useState(false);
  const [selectedAuthor, setSelectedAuthor] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'authors/LOAD_DATA',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loading = useSelector((state) =>
    _.get(state, 'authors.isLoading', false),
  );

  const [selectedFilter, setSelectedFilter] = useState('all');
  const [searchText, setSearchText] = useState('');
  const columns = [
    {
      dataKey: 'no',
      label: 'No.',
      width: 60,
      align: 'center',
      cellRender: (cellData, rowData, rowIndex) => rowIndex + 1,
    },
    {
      dataKey: 'avatar',
      label: '',
      width: 50,
      align: 'center',
      cellRender: (cellData, rowData, rowIndex) => (
        <Avatar alt={rowData.name} src={rowData.photoURL} />
      ),
    },
    {dataKey: 'name', label: 'Name', minWidth: 170, flex: 1},
    {
      dataKey: 'actions',
      label: 'Actions',
      width: 100,
      align: 'center',
      cellRender: (cellData, rowData, rowIndex) => {
        return (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            {!Boolean(_.get(rowData, 'deletedAt', 0)) && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setSelectedAuthor(rowData);
                  setEditorVisible(true);
                }}
                color="primary"
                style={{fontSize: 9, padding: 5}}>
                Edit
              </Button>
            )}

            {!Boolean(_.get(rowData, 'deletedAt', 0)) ? (
              <Grid item>
                <IconButton
                  aria-label="delete"
                  style={{color: 'red'}}
                  onClick={() => {
                    Swal.fire({
                      title: 'yakin nih?',
                      text: 'Kategori akan dihapus?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, delete it!',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        Swal.fire({
                          title: 'Sedang menghapus kategory',
                          // html: 'I will close in <b></b> milliseconds.',
                          timer: 2000,
                          timerProgressBar: true,
                          didOpen: () => Swal.showLoading(),
                        });
                        deleteAuthor(rowData.key, false).then(
                          (res) => {
                            if (res.isSuccess) {
                              Swal.fire(
                                'Yeay',
                                'Success kembalikan kategori',
                                'success',
                              );
                              dispatch({
                                type: 'authors/LOAD_DATA',
                              });
                            }
                          },
                        );
                      }
                    });
                  }}
                  size="small">
                  <DeleteIcon />
                </IconButton>
              </Grid>
            ) : (
              <Grid item>
                <IconButton
                  aria-label="restore"
                  color="secondary"
                  // style={{color: 'red'}}
                  onClick={() => {
                    Swal.fire({
                      title: 'yakin nih?',
                      text: 'Kategori akan dikembalikan?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, restore it!',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        Swal.fire({
                          title: 'Sedang kembalikan kategory',
                          // html: 'I will close in <b></b> milliseconds.',
                          timer: 2000,
                          timerProgressBar: true,
                          didOpen: () => Swal.showLoading(),
                        });

                        restoreAuthor(rowData.key).then((res) => {
                          if (res.isSuccess) {
                            Swal.fire(
                              'Yeay',
                              'Success kembalikan kategori',
                              'success',
                            );
                            dispatch({type: 'authors/LOAD_DATA'});
                          }
                        });
                      }
                    });
                  }}
                  size="small">
                  <RestoreIcon />
                </IconButton>
              </Grid>
            )}
          </div>
        );
      },
    },
  ];

  if (selectedFilter === 'all') {
    authorsData = authorsData.filter((author) => {
      return !Boolean(author.deletedAt);
    });
  } else if (selectedFilter === 'trash') {
    authorsData = authorsData.filter((author) => {
      return Boolean(author.deletedAt);
    });
  }

  authorsData = authorsData.filter((author) =>
    String(author.label)
      .toLowerCase()
      .includes(searchText.toLowerCase()),
  );
  return (
    <>
      <div>
        <Grid container justify="space-between" className="mb-1">
          <Grid item md={6} sm={12}>
            <Grid container spacing={1}>
              <Grid item>
                <h2>Authors</h2>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => setEditorVisible(true)}
                  size="small"
                  color="secondary"
                  style={
                    {
                      // backgroundColor: '#fbad18',
                      // height: 30,
                      // padding: 10,
                    }
                  }>
                  Create New
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} sm={12}>
            <Grid container justify="flex-end">
              <Grid item>
                <SearchInput
                  onSelectedFilter={(val) => {
                    setSelectedFilter(val);
                  }}
                  onSearch={(val) => setSearchText(val)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <MyTable
          rows={authorsData}
          columns={columns}
          loading={loading}
        />
      </div>
      <ModalAuthorEditor
        visible={editorVisibel}
        onDismiss={() => setEditorVisible(false)}
        onSubmitted={() => {
          setEditorVisible(false);
          dispatch({
            type: 'authors/LOAD_DATA',
          });
        }}
        authorData={selectedAuthor}
      />
    </>
  );
}
