import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import DescriptionIcon from '@material-ui/icons/Description';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  Paper,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Drawer,
} from '@material-ui/core';
import {useHistory, useLocation} from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    // backgroundColor: '#262213',
    // borderTop: 'grey solid 1px',
    position: 'fixed',
    boxSizing: 'border-box',
    bottom: 0,
    left: 0,
    width: '100%',
    overflow: 'hidden',
    '& .Mui-selected': {
      color: '#F15722',
    },
    '& .MuiBottomNavigationAction-root': {
      padding: 0,
    },
  },
  drawerRoot: {
    '& .MuiDrawer-paperAnchorBottom': {
      borderRadius: '10px 10px 0 0',
    },
  },
});

export default function AppNavigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState(-1);

  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === `/`) {
      setValue(0);
    } else if (location.pathname === `/courses`) {
      setValue(1);
    } else if (location.pathname === `/articles`) {
      setValue(2);
    } else if (location.pathname === `/papers`) {
      setValue(3);
    } else if (
      location.pathname === `/videos` ||
      location.pathname === `/toolkits`
    ) {
      setValue(4);
    }
  }, [location.pathname]);
  const [visible, setVisible] = useState(false);
  return (
    <Paper elevation={3}>
      <BottomNavigation
        value={value}
        // onChange={(event, newValue) => {
        //   setValue(newValue);
        // }}
        showLabels
        className={classes.root}>
        <BottomNavigationAction
          onClick={() => history.push('/')}
          label="Home"
          icon={<HomeIcon />}
        />
        <BottomNavigationAction
          onClick={() => history.push('/courses')}
          label="Kelas"
          icon={<SchoolIcon />}
        />
        <BottomNavigationAction
          onClick={() => history.push('/articles')}
          label="Cerita"
          icon={
            <i
              className="far fa-newspaper"
              style={{fontSize: 22, margin: 2}}></i>
          }
        />
        <BottomNavigationAction
          onClick={() => history.push('/papers')}
          label="Jerat Data"
          icon={<DescriptionIcon />}
        />
        <BottomNavigationAction
          onClick={() => history.push('/toolkits')}
          label="Amunisi"
          icon={
            <i
              className="fas fa-photo-video"
              style={{fontSize: 20, margin: 4}}></i>
          }
        />
        {/* <BottomNavigationAction
          onClick={() => history.push('/videos')}
          label="Video"
          icon={<VideoLibraryIcon />}
        /> */}
        {/* <BottomNavigationAction
          onClick={() => setVisible(true)}
          label=""
          icon={<MoreVertIcon />}
        /> */}
      </BottomNavigation>
      <Drawer
        className={classes.drawerRoot}
        // style={{overflow: 'hidden'}}
        anchor={'bottom'}
        open={visible}
        onClose={() => setVisible(false)}>
        <Grid container className="px-2 py-2">
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem
                button
                onClick={() => history.push('/papers')}>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary="Selisik" />
              </ListItem>
              <Divider />
              <ListItem
                button
                onClick={() => history.push('/toolkits')}>
                <ListItemIcon>
                  <i className="fas fa-photo-video"></i>
                </ListItemIcon>
                <ListItemText primary="Amunisi" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Drawer>
    </Paper>
  );
}
