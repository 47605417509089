import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Grid, IconButton, useMediaQuery} from '@material-ui/core';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {createLike, deleteLike, getLikesCount} from 'services/likes';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import _ from 'lodash';
import CommentDrawer from 'components/CommentDrawer';

export default function LikesAndComments({
  collectionId,
  collectionType,
}) {
  const [value, setValue] = React.useState(-1);

  const history = useHistory();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLoggedIn = useSelector(
    (state) => _.get(state, 'user.authorized'),
    false,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [commentCount, setCommentsCount] = useState(0);
  const [commentsVisible, setCommentsVisble] = useState(false);

  useEffect(() => {
    if (collectionId && collectionType)
      getLikesCount({
        collectionType,
        collectionId,
      }).then((res) => {
        if (res.isSuccess) {
          setLikesCount(res.data.count);
          setIsLiked(res.data.isLiked);
        }
      });
  }, [collectionType, collectionId]);
  return (
    <>
      <Grid
        item
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <IconButton
          size="small"
          // color="secondary"
          style={{color: isLiked ? 'red' : 'white'}}
          aria-label="like"
          onClick={() => {
            if (isLoggedIn) {
              if (!isLiked) {
                createLike({
                  collectionId: collectionId,
                  collectionType: collectionType,
                })
                  .then((res) => {
                    if (res.isSuccess) console.log(res.data.message);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
                setLikesCount(likesCount + 1);
                setIsLiked(true);
              } else {
                deleteLike({
                  collectionId,
                  collectionType,
                })
                  .then((res) => {
                    if (res.isSuccess) console.log(res.data.message);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
                setLikesCount(likesCount - 1);
                setIsLiked(false);
              }
            } else
              history.push({
                pathname: '/signin',
                state: {from: location.pathname},
              });
          }}
          component="span">
          {isLiked ? (
            <i className="fas fa-heart" style={{fontSize: 20}}></i>
          ) : (
            <i className="far fa-heart" style={{fontSize: 20}}></i>
          )}
        </IconButton>
        <span
          className={{
            fontSize: 16,
            weight: 600,
            marginLeft: 10,
          }}>
          {likesCount}
        </span>
        <IconButton
          size="small"
          style={{marginLeft: 15, color: '#FBAD18'}}
          onClick={() => setCommentsVisble(true)}
          aria-label="share"
          component="span">
          <ChatBubbleIcon />
        </IconButton>
        <span
          className={{
            fontSize: 16,
            weight: 600,
            marginLeft: 10,
          }}>
          {commentCount}
        </span>
      </Grid>
      <CommentDrawer
        visible={commentsVisible}
        onDismiss={() => setCommentsVisble(false)}
        onCount={(val) => setCommentsCount(val)}
        onOpen={() => setCommentsVisble(true)}
        collectionId={collectionId || ''}
        collectionType={collectionType}
      />
    </>
  );
}
