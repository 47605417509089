import React, {useState, useEffect} from 'react';
import {useTheme} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
export default function ComingSoonPage() {
  const theme = useTheme();
  const history = useHistory();
  const isMaintenance = useSelector((state) =>
    _.get(state, 'configs.maintenance', false),
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({type: 'configs/CHECK_MODE'});
  }, [dispatch]);

  useEffect(() => {
    if (!isMaintenance) {
      history.replace('/');
    }
  }, [isMaintenance, history]);
  return (
    <div
      style={{
        backgroundColor: '#153758',
        height: '100vh',
        verticalAlign: 'center',
        color: 'white',
        padding: 40,
        justifyContent: 'center',
        textAlign: 'center',
        display: 'flex',
      }}>
      <div>
        <img src="/images/init.png" width="100%" />
        <h1>Coming soon!</h1>
      </div>
    </div>
  );
}
