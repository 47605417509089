import React, {memo, useState, useEffect} from 'react';
import useEventListener from 'helper/useEventListener';
import TopNav from 'components/layout/TopNav';
import MobileDrawer from 'components/layout/MobileDrawer';
import Footer from 'components/layout/Footer';
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core/styles';
import AppNavigation from 'components/AppNavigation';
import {useMediaQuery} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import _ from 'lodash';
import {useHistory} from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    minHeight: '100vh',
    overflow: 'hidden',
    paddingTop: 60,
  },
}));

const MainLayout = memo(({children = ''}) => {
  const classes = useStyles();
  const history = useHistory();
  // navbar mestinya di sini
  const dispatch = useDispatch();

  const isMaintenance = useSelector((state) =>
    _.get(state, 'configs.maintenance', true),
  );
  const isAdmin = useSelector(
    (state) => _.get(state, 'user.isAdmin'),
    false,
  );

  useEffect(() => {
    dispatch({type: 'configs/CHECK_MODE'});
  }, [dispatch]);
  useEffect(() => {
    if (isMaintenance && !isAdmin) {
      history.replace('/coming-soon');
    }
  }, [history, isMaintenance, isAdmin]);
  const matches = useMediaQuery((theme) =>
    theme.breakpoints.up('md'),
  );

  const [ismain, setIsMain] = useState(true);

  useEffect(() => {
    const pagesWithNavbar = [
      `/`,
      `/courses`,
      `/articles`,
      `/videos`,
      `/videos`,
      `/papers`,
      `/toolkits`,
    ];
    if (pagesWithNavbar.includes(location.pathname)) setIsMain(true);
    else setIsMain(false);
  }, []);
  return (
    <div className={classes.root}>
      {matches && <TopNav />}
      {children}
      {matches && <Footer />}
      {!matches && ismain && <AppNavigation />}
    </div>
  );
});

export default MainLayout;
