const actions = {
  SET_STATE: 'user/SET_STATE',
  CLEAR_STATE: 'user/CLEAR_STATE',
  LOGIN: 'user/LOGIN',
  LOGIN_WITH_TOKEN: 'user/LOGIN_WITH_TOKEN',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LISTEN_USER_DATA: 'user/LISTEN_USER_DATA',
  UPDATE_USER_DATA: 'user/UPDATE_USER_DATA',
  UPDATE_TOKEN: 'user/UPDATE_TOKEN',

  PROFILE_CHANGE_DATA: 'user/PROFILE_CHANGE_DATA',
  PROFILE_CHANGE_PASSWORD: 'user/PROFILE_CHANGE_PASSWORD',
  PROFILE_CHANGE_PROFPIC: 'user/PROFILE_CHANGE_PROFPIC',

  UPDATE_DATA: 'user/UPDATE_DATA',
  LOGOUT: 'user/LOGOUT',
  LOGGED_OUT: 'user/LOGGED_OUT',
};

export default actions;
