import React, {
  memo,
  useState,
  useEffect,
  Children,
  useContext,
} from 'react';
import {
  Drawer,
  Button,
  useMediaQuery,
  Divider,
} from '@material-ui/core';
import {Link, useLocation, useHistory} from 'react-router-dom';
import _ from 'lodash';
import clsx from 'clsx';
import {useSelector, useDispatch} from 'react-redux';

const urlImage = '/images/logo.png';

const Sidebar = ({open = false, setOpen = () => {}}) => {
  const location = useLocation();
  const history = useHistory();
  const matches = useMediaQuery((theme) =>
    theme.breakpoints.up('md'),
  );
  const dispatch = useDispatch();

  const menuData = [
    {
      key: 'analytics',
      title: 'Dashboard',
      icon: 'fas fa-chart-line',
      url: '/dashboard/analytics',
      visible: true,
    },
    {
      key: 'users',
      title: 'Users',
      icon: 'fas fa-users',
      url: '/dashboard/users',
      visible: true,
    },
    {
      key: 'authors',
      title: 'Authors',
      icon: 'fas fa-user-edit',
      url: '/dashboard/authors',
      visible: true,
    },
    {
      key: 'categories',
      title: 'Categories',
      icon: 'fas fa-tags',
      url: '/dashboard/categories',
      visible: true,
    },
    {
      key: 'articles',
      title: 'Articles',
      icon: 'far fa-newspaper',
      url: '/dashboard/articles',
      visible: true,
    },
    {
      key: '/courses',
      title: 'Courses',
      icon: 'fas fa-chalkboard-teacher',
      url: '/dashboard/courses',
      visible: true,
    },
    {
      key: 'videos',
      title: 'Videos',
      icon: 'fa fa-video',
      url: '/dashboard/videos',
      visible: true,
    },
    {
      key: 'papers',
      title: 'Papers',
      icon: 'fas fa-book-reader',
      url: '/dashboard/papers',
      visible: true,
    },
    {
      key: 'toolkits',
      title: 'Toolkits',
      icon: 'fas fa-tools',
      url: '/dashboard/toolkits',
      visible: true,
    },
    {
      key: 'banners',
      title: 'Banners',
      icon: 'fas fa-image',
      url: '/dashboard/banners',
      visible: true,
    },
  ];

  useEffect(() => {
    dispatch({
      type: 'categories/LOAD_DATA',
    });
  }, [dispatch]);
  const [activeItem, setactiveItem] = useState('');
  const [activeSubMenu, setactiveSubMenu] = useState('');
  const [submenuOpen, setsubmenuOpen] = useState({});

  useEffect(() => {
    if (!menuData.length) {
      return;
    }
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item);
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key));
        }
        return flattenedItems;
      }, []);
    const lactiveItem = _.find(
      flattenItems(menuData, 'children'),
      (item) =>
        typeof location.pathname === 'string' &&
        location.pathname.includes(item.url),
    );
    if (lactiveItem) {
      const lactiveSubmenu = menuData.reduce((key, parent) => {
        if (Array.isArray(parent.children)) {
          parent.children.map((child) => {
            if (child.key === lactiveItem.key) {
              key = parent;
            }
            return '';
          });
        }
        return key;
      });
      setactiveItem(lactiveItem.key);
      setactiveSubMenu(lactiveSubmenu.key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const generateMenuItems = () => {
    let lmenuData = menuData;

    const menuItem = (item) => {
      const {key, title, icon, url, visible} = item;
      if (item.category) {
        return null;
      }
      return (
        <li className="nav-item" key={key}>
          <Link
            onClick={() => {
              if (!matches) setOpen(false);
            }}
            className={clsx('sidebar-link single', {
              active: activeItem === key,
            })}
            to={url}>
            <span className="icon-holder">
              {icon && (
                <i style={{fontSize: 18}} className={icon}></i>
              )}
            </span>
            <span className="title">{title}</span>
          </Link>
        </li>
      );
    };

    const submenuItem = (item) => {
      const {key, title, icon, url, visible} = item;
      return (
        <li
          key={key}
          className={clsx('sidebar-link nav-item dropdown', {
            active: activeSubMenu === key,
            open: activeSubMenu === key || Boolean(submenuOpen[key]),
          })}>
          <a
            className="dropdown-toggle"
            onClick={(e) => {
              e.preventDefault();
              setsubmenuOpen((prev) => ({
                ...prev,
                [key]: !prev[key],
              }));
            }}>
            <span className="icon-holder">
              {icon && (
                <i style={{fontSize: 18}} className={icon}></i>
              )}
            </span>
            <span className="title">{title}</span>
            <span className="arrow">
              <i className="fa fa-angle-right"></i>
            </span>
          </a>
          <ul className="dropdown-menu">
            {_.isArray(item.children) &&
              item.children
                .filter((item) => item.visible)
                .map((sub) => {
                  const {key, title, icon, url} = sub;
                  return (
                    <li key={key}>
                      <Link
                        onClick={() => {
                          if (!matches) setOpen(false);
                        }}
                        className={clsx('sidebar-link', {
                          active: activeItem === key,
                        })}
                        to={url}
                        style={{
                          fontSize: 12,
                          paddingTop: 9,
                          paddingBottom: 9,
                        }}>
                        {title}
                      </Link>
                    </li>
                  );
                })}
          </ul>
        </li>
      );
    };

    return lmenuData
      .filter((item) => item.visible)
      .map((item) => {
        if (item.children) {
          return submenuItem(item);
        }
        return menuItem(item);
      });
  };
  const [dialogUpgradeVisible, setDialogUpgradeVisible] = useState(
    false,
  );

  return (
    <>
      <Drawer
        anchor="left"
        open={open}
        variant={'persistent'}
        onClose={() => {
          setOpen(false);
        }}>
        <div className="sidebar">
          <div className="sidebar-inner">
            <div className="sidebar-logo">
              <div className="peers ai-c fxw-nw"></div>
              <div className="peer ml-auto">
                <div className="mobile-toggle sidebar-toggle">
                  <a
                    style={{cursor: 'pointer'}}
                    className="td-n"
                    onClick={() => {
                      setOpen(false);
                    }}>
                    <i
                      className="fa fa-angle-left"
                      style={{color: '#fff'}}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="text-center my-3 mx-2">
              <Link to="/">
                <img
                  src={urlImage}
                  style={{
                    borderRadius: '20px',
                    // width: 180,
                    height: 70,
                    backgroundColor: '#1a4072',
                  }}
                />
              </Link>
            </div>
            <ul className="sidebar-menu scrollable pos-r">
              <li className="sidebar-background"></li>
              {generateMenuItems()}
              <Divider
                style={{backgroundColor: 'white', margin: 10}}
              />
            </ul>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Sidebar;
