import {getFirebaseIdToken} from './user';
import axios from 'axios';
import {functionUrl} from 'helper/functions';
import _ from 'lodash';

export async function getAllUsers(payload) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/users/get-all-users`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    return {
      data: _.get(response, 'data.data.users', []),
      pageToken: _.get(response, 'data.data.pageToken', []),
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function createUser(payload) {
  try {
    let response = await axios.post(
      `${functionUrl}/users/create`,
      payload,
    );
    return {
      data: _.get(response, 'data.data.users', []),
      pageToken: _.get(response, 'data.data.pageToken', []),
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}

export async function setUserRole(payload) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/users/change-role`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    return {
      data: response || {},
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function deleteUser(payload) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/users/delete`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    return {
      data: response || {},
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function disableUser(payload) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/users/disable`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    return {
      data: response || {},
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
export async function enableUser(payload) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/users/enable`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    return {
      data: response || {},
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
