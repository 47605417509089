import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Controller, FormProvider} from 'react-hook-form';
import {createVideo, updateVideo} from 'services/videos';
import Swal from 'sweetalert2';
import slugify from 'slugify';
import {useSelector} from 'react-redux';
import {
  FormControl,
  InputLabel,
  Select,
  Input,
  Paper,
  TextareaAutosize,
} from '@material-ui/core';
import _ from 'lodash';
import {storageRef, databaseRef} from 'services/firebase';

const videoScheme = yup
  .object()
  .noUnknown(true)
  .required()
  .shape({
    title: yup
      .string()
      .typeError('Format title salah')
      .required('Judul artikel harus diisi')
      .max(200),
    description: yup
      .string()
      .typeError('Format deskripsi salah')
      .required('Label Packaging Unit harus diisi'),
    slug: yup
      .string()
      .typeError('Format deskripsi salah')
      .required('Label Packaging Unit harus diisi')
      .max(200),
    coverImageURL: yup
      .string()
      .typeError('Format Label salah')
      .required('featuredImageURL harus diisi')
      .max(400),
    videoURL: yup
      .string()
      .typeError('Format Label salah')
      .required('videoURL harus diisi')
      .max(400),
    categoryId: yup
      .string()
      .typeError('Format Label salah')
      .max(200)
      .required('video harus diisi'),
    status: yup
      .string()
      .oneOf(['published', 'draft'])
      .default('draft'),
  });

const STATUSES = [
  {label: 'Draft', value: 'draft'},
  {label: 'Published', value: 'published'},
];
export default function ModalVideoEditor({
  visible = false,
  onDismiss = () => {},
  onSubmitted = () => {},
  videoData = {},
}) {
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(videoScheme),
  });
  const categories = useSelector((state) =>
    Object.entries(
      _.get(state, 'categories.data', {}),
    ).map(([key, value]) => ({key, ...value})),
  );
  const {
    control,
    watch,
    getValues,
    handleSubmit,
    errors,
    register,
    reset,
    setValue,
  } = methods;

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (visible) reset(videoData);
    console.log(videoData);
  }, [videoData, visible, reset]);
  const handleClose = () => {
    onDismiss();
  };
  const onSubmit = (data) => {
    setIsLoading(true);
    console.log(data);
    if (!Boolean(videoData && videoData.videoId)) {
      createVideo(data)
        .then((res) => {
          setIsLoading(false);
          if (res.isSuccess) {
            console.log('berhasil');
            onSubmitted();
            Swal.fire('Yeay', 'Berhasil menambah video', 'success');
          } else console.log('gagal');
        })
        .catch((error) => {
          console.error(error);
          Swal.fire('Upps...', 'Gagal buat kategory', 'error');
          setIsLoading(false);
        });
    } else {
      console.log('videoData.videoId: ', videoData.videoId);
      updateVideo({...videoData, ...data})
        .then((res) => {
          setIsLoading(false);
          if (res.isSuccess) {
            console.log('berhasil');
            onSubmitted();
            Swal.fire('Yeay', 'Berhasil mengubah video', 'success');
          } else console.log('gagal');
        })
        .catch((error) => {
          console.error(error);
          Swal.fire('Upps...', 'Gagal update video', 'error');
          setIsLoading(false);
        });
    }
  };
  const onError = (error) => {
    console.log(error);
    console.log(getValues());
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0] || {};
    const fileRef = storageRef.child(databaseRef.push().key);
    fileRef.put(file).then((res) => {
      res.ref.getDownloadURL().then((url) => {
        setValue('coverImageURL', url);
        console.log('uploaded', url);
      });
    });
  };

  const [permalink, setPermalink] = useState('');
  const hiddenFileInput = React.useRef(null);

  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };

  return (
    <Dialog
      open={visible}
      onClose={onDismiss}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {Boolean(videoData.videoId) ? 'Edit' : 'New'} Video
      </DialogTitle>
      <DialogContent>
        <Paper
          variant="outlined"
          style={{
            padding: 10,
            borderRadius: 10,
            textAlign: 'center',
          }}>
          <div>Cover Image</div>
          <br />

          <Controller
            control={control}
            defaultValue={''}
            name="coverImageURL"
            render={({onChange, onBlur, value}) => {
              if (value)
                return (
                  <img
                    src={value}
                    style={{width: 200, height: 200}}
                  />
                );
              else return <></>;
            }}
          />

          <input
            type="file"
            ref={hiddenFileInput}
            style={{display: 'none'}}
            onChange={handleFileChange}
            name="fileToUpload"
            id="fileToUpload"
          />
          <div>
            <Button onClick={handleClick}>Upload</Button>
          </div>
        </Paper>

        <Controller
          control={control}
          defaultValue=""
          name="title"
          render={({onChange, onBlur, value}) => (
            <TextField
              autoFocus
              value={value}
              onChange={(e) => {
                const val = _.get(e, 'target.value', '');
                onChange(val);
                const slug = slugify(String(val), {
                  lower: true,
                  strict: true,
                });
                setPermalink(slug);
                setValue('slug', slug);
              }}
              margin="dense"
              label="Title"
              type="text"
              fullWidth
            />
          )}
        />
        <Controller
          control={control}
          defaultValue=""
          name="slug"
          render={({onChange, onBlur, value}) => <></>}
        />
        <br />
        <br />
        <Controller
          control={control}
          defaultValue=""
          name="description"
          render={({onChange, onBlur, value}) => (
            <>
              <TextareaAutosize
                aria-label="Deskription"
                rowsMin={5}
                value={value}
                onChange={onChange}
                style={{
                  width: '100%',
                  borderColor: '1px solid rgba(0, 0, 0, 0.12)',

                  borderRadius: 8,
                  padding: 8,
                }}
                placeholder="Description"
              />
            </>
          )}
        />
        <Controller
          control={control}
          defaultValue=""
          name="videoURL"
          render={({onChange, onBlur, value}) => (
            <TextField
              autoFocus
              value={value}
              onChange={onChange}
              margin="dense"
              label="Video URL"
              type="text"
              fullWidth
            />
          )}
        />
        <br />
        <br />
        <Controller
          control={control}
          defaultValue={'draft'}
          name="status"
          render={({onChange, onBlur, value, name}) => (
            <FormControl style={{width: '100%'}}>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                native
                style={{width: '100%'}}
                value={value}
                onChange={onChange}
                input={<Input id="status" style={{width: '100%'}} />}>
                {STATUSES.map((status, index) => (
                  <option key={status.value} value={status.value}>
                    {status.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <br />
        <br />
        <Controller
          control={control}
          defaultValue={_.get(categories, '[0].key', '')}
          name="categoryId"
          render={({onChange, onBlur, value, name}) => (
            <FormControl style={{width: '100%'}}>
              <InputLabel htmlFor="category">Category</InputLabel>
              <Select
                native
                style={{width: '100%'}}
                value={value}
                onChange={onChange}
                input={
                  <Input id="category" style={{width: '100%'}} />
                }>
                {(categories || []).map((category, index) => (
                  <option key={category.key} value={category.key}>
                    {category.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isLoading}
          onClick={handleClose}
          color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit, onError)}
          disabled={isLoading}
          color="primary">
          {isLoading ? (
            <i className="fas fa-spinner fa-pulse"></i>
          ) : (
            'Simpan'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
