import {all} from 'redux-saga/effects';
import user from './user/sagas';
import articles from './articles/sagas';
import courses from './courses/sagas';
import videos from './videos/sagas';
import papers from './papers/sagas';
import toolkits from './toolkits/sagas';
import categories from './categories/sagas';
import banners from './banners/sagas';
import authors from './authors/sagas';
import users from './users/sagas';
import configs from './configs/sagas';

export default function* rootSaga() {
  yield all([
    user(),
    articles(),
    courses(),
    videos(),
    papers(),
    toolkits(),
    categories(),
    banners(),
    authors(),
    users(),
    configs(),
  ]);
}
