import React, {useState, useEffect} from 'react';
import {useHistory, useLocation, Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Grid,
  makeStyles,
  Container,
  Button,
  Typography,
  useMediaQuery,
  Paper,
} from '@material-ui/core';
import Slider from 'react-slick';
import TopNav from 'components/layout/TopNav';
import {loadPapers, loadPublishedPapers} from 'services/papers';
import {loadPublishedArticles} from 'services/articles';
import {Skeleton} from '@material-ui/lab';
import {loadCourses, loadPublishedCourses} from 'services/courses';
import _ from 'lodash';
import {loadPublishedToolkits} from 'services/toolkits';
import {loadBanners} from 'services/banners';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    height: 256,
    width: '100%',
    color: theme.palette.text.secondary,
  },
}));

export default function HomePage() {
  const bannerSliderSettings = {
    dots: true,
    // dotsClass: 'custom-slick-dots',
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const history = useHistory();
  const [courseLoading, setCourseLoading] = useState(false);
  const [articleLoading, setArticleLoading] = useState(false);
  const [paperLoading, setPaperLoading] = useState(false);
  const [toolkitLoading, setToolkitLoading] = useState(false);
  const [coursesData, setCoursesData] = useState([]);
  const [articlesData, setArticlesData] = useState([]);
  const [papersData, setPapersData] = useState([]);
  const [toolkitsData, setToolkitsData] = useState([]);
  const [isLoadingBanner, setIsLoadingBanner] = useState(false);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    setCourseLoading(true);
    setArticleLoading(true);
    setPaperLoading(true);
    setToolkitLoading(true);
    setIsLoadingBanner(true);
    loadPublishedPapers({}).then((res) => {
      if (res.isSuccess) {
        const data = _.get(res, 'data.data', []);
        if (_.isArray(data)) setPapersData(data);
      }
      setPaperLoading(false);
    });
    loadPublishedToolkits({}).then((res) => {
      if (res.isSuccess) {
        const data = _.get(res, 'data.data', []);
        if (_.isArray(data)) setToolkitsData(data);
      }
      setToolkitLoading(false);
    });
    loadBanners({}).then((res) => {
      if (res.isSuccess) {
        const data = Object.values(_.get(res, 'data', {}));
        if (_.isArray(data)) setBanners(data);
      }
      setIsLoadingBanner(false);
    });
    loadPublishedArticles({}).then((res) => {
      if (res.isSuccess) {
        const data = _.get(res, 'data.data', []);
        if (_.isArray(data)) setArticlesData(data);
      }
      setArticleLoading(false);
    });
    loadPublishedCourses({})
      .then((res) => {
        if (res.isSuccess) {
          const data = _.get(res, 'data.data', []);
          if (_.isArray(data)) setCoursesData(data);
        }
        setCourseLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  const productSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          // className: 'center',
          // centerMode: true,
          slidesToShow: 2.2,
          centerPadding: '50px',
          slidesToScroll: 2,
        },
      },
    ],
  };

  const matches = useMediaQuery((theme) =>
    theme.breakpoints.up('sm'),
  );

  return (
    <>
      {!matches && <TopNav />}
      <div
        style={{
          backgroundColor: '#2E4462',
          textAlign: 'center',
          width: '100%',
          paddingBottom: 70,
        }}>
        <Container
          style={{
            ...{marginTop: 20, width: '100%'},
            ...(matches ? {} : {padding: 0}),
          }}>
          <Grid
            container
            justify="center"
            style={{
              width: '100%',
              maxWidth: '100%',
              marginTop: 10,
              textAlign: 'center',
              overflow: 'hidden',
              // padding: '10px 0px',
            }}>
            <Grid item lg={11} md={11} xs={10}>
              {isLoadingBanner ? (
                <Skeleton
                  variant="rect"
                  width="100%"
                  style={{paddingTop: '30%'}}
                />
              ) : (
                <Slider {...bannerSliderSettings}>
                  {Object.values(banners || {})
                    .filter(
                      (item) =>
                        !Boolean(item.deletedAt) &&
                        Boolean(item.imageURL),
                    )
                    .map((item, index) => (
                      <div key={index} style={{}}>
                        <a
                          href={item.targetLink}
                          style={{
                            // maxHeight: 600,
                            // backgroundColor: 'white',
                            textAlign: 'center',

                            // padding: '3% 5%',
                          }}>
                          <img
                            src={`${item.imageURL}`}
                            // height="100%"
                            width="100%"
                            style={{
                              padding: '0px 5px',
                              borderRadius: '20px',
                            }}
                          />
                        </a>
                      </div>
                    ))}
                </Slider>
              )}
            </Grid>
          </Grid>
        </Container>
        <br />
        <div
          style={
            matches
              ? {textAlign: 'center'}
              : {textAlign: 'left', paddingLeft: 10}
          }>
          <Container
            style={{
              ...{marginTop: 20, width: '100%'},
              ...(matches ? {} : {padding: 0}),
            }}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography style={{color: 'white'}} variant="h1">
                  Kelas
                </Typography>
              </Grid>
              <Grid item>
                <Link
                  style={{color: 'white', paddingRight: 10}}
                  to={'/courses'}>
                  <Button
                    size="small"
                    variant="outlined"
                    style={{textTransform: 'capitalize'}}>
                    <Typography variant="h5">Lainnya</Typography>
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <br />
            <Slider {...productSliderSettings} style={{}}>
              {(courseLoading
                ? [1, 2, 3, 4]
                : Object.values(coursesData || {}) || []
              ).map((item, index) => (
                <Grid
                  container
                  justify="flex-start"
                  key={index}
                  spacing={matches ? 5 : 1}>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{textAlign: 'left'}}>
                    {courseLoading ? (
                      <>
                        <Skeleton
                          variant="rect"
                          width="100%"
                          height={150}
                        />
                        <br />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                      </>
                    ) : (
                      <Link to={`/courses/${item.courseId}`}>
                        <img
                          src={item.coverImageURL}
                          style={{
                            maxWidth: '100%',
                            width: '100%',
                            borderRadius: 5,
                          }}
                        />
                        <br />
                        <Typography variant="h5">
                          {item.title}
                        </Typography>

                        <div className="badge badge-danger mt-2">
                          <Typography variant="h5">
                            {Boolean(item.liveYoutubeURL) ||
                            Boolean(item.liveZoomURL) ? (
                              <>
                                LIVE{' '}
                                <i className="fab fa-youtube"></i>
                              </>
                            ) : (
                              <>
                                Recorded{' '}
                                <i className="fab fa-youtube"></i>
                              </>
                            )}
                          </Typography>
                        </div>
                      </Link>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Slider>
          </Container>
        </div>

        <div
          style={{
            ...(matches
              ? {textAlign: 'center'}
              : {textAlign: 'left', paddingLeft: 10}),
            ...{paddingTop: 30},
          }}>
          <Container
            style={{
              ...{marginTop: 20, width: '100%'},
              ...(matches ? {} : {padding: 0}),
            }}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography style={{color: 'white'}} variant="h1">
                  Cerita
                </Typography>
              </Grid>
              <Grid item>
                <Link
                  style={{color: 'white', paddingRight: 10}}
                  to={'/articles'}>
                  <Button
                    size="small"
                    variant="outlined"
                    style={{textTransform: 'capitalize'}}>
                    <Typography variant="h5">Lainnya</Typography>
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <br />
            <Slider {...productSliderSettings} style={{}}>
              {(articleLoading
                ? [1, 2, 3, 4]
                : Object.values(articlesData || {}) || []
              ).map((item, index) => (
                <Grid
                  container
                  justify="center"
                  key={index}
                  spacing={matches ? 5 : 1}>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{textAlign: 'left'}}>
                    {articleLoading ? (
                      <>
                        <Skeleton
                          variant="rect"
                          width="100%"
                          height={150}
                        />
                        <br />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                      </>
                    ) : (
                      <Link to={`/articles/${item.slug}`}>
                        <img
                          src={item.coverImageURL}
                          style={{
                            maxWidth: '100%',
                            borderRadius: 5,
                          }}
                        />
                        <br />
                        <Typography variant="h5">
                          {item.title}
                        </Typography>

                        <br />
                        <Button
                          size="small"
                          variant="outlined"
                          color="secondary">
                          Baca Sekarang
                        </Button>
                      </Link>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Slider>
          </Container>
        </div>
        <div
          style={{
            ...(matches
              ? {textAlign: 'center'}
              : {textAlign: 'left', paddingLeft: 10}),
            ...{paddingTop: 30},
          }}>
          <Container
            style={{
              ...{marginTop: 20, width: '100%'},
              ...(matches ? {} : {padding: 0}),
            }}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography style={{color: 'white'}} variant="h1">
                  Jerat Data
                </Typography>
              </Grid>
              <Grid item>
                <Link
                  style={{color: 'white', paddingRight: 10}}
                  to={'/papers'}>
                  <Button
                    size="small"
                    variant="outlined"
                    style={{textTransform: 'capitalize'}}>
                    <Typography variant="h5">Lainnya</Typography>
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <br />
            <Slider {...productSliderSettings} style={{}}>
              {(paperLoading
                ? [1, 2, 3, 4]
                : Object.values(papersData || {}) || []
              ).map((item, index) => (
                <Grid
                  container
                  justify="flex-start"
                  key={index}
                  spacing={matches ? 5 : 1}>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{textAlign: 'left'}}>
                    {paperLoading ? (
                      <>
                        <Skeleton
                          variant="rect"
                          width="100%"
                          height={150}
                        />
                        <br />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                      </>
                    ) : (
                      <Link to={`/papers/${item.slug}`}>
                        <img
                          src={item.coverImageURL}
                          style={{
                            maxWidth: '100%',
                            borderRadius: 5,
                          }}
                        />
                        <br />
                        <Typography variant="h5">
                          {item.title}
                        </Typography>
                      </Link>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Slider>
          </Container>
        </div>
        <div
          style={{
            ...(matches
              ? {textAlign: 'center'}
              : {textAlign: 'left', paddingLeft: 10}),
            ...{paddingTop: 30},
          }}>
          <Container
            style={{
              ...{marginTop: 20, width: '100%'},
              ...(matches ? {} : {padding: 0}),
            }}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography style={{color: 'white'}} variant="h1">
                  Amunisi
                </Typography>
              </Grid>
              <Grid item>
                <Link
                  style={{color: 'white', paddingRight: 10}}
                  to={'/toolkits'}>
                  <Button
                    size="small"
                    variant="outlined"
                    style={{textTransform: 'capitalize'}}>
                    <Typography variant="h5">Lainnya</Typography>
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <br />
            <Slider {...productSliderSettings} style={{}}>
              {(toolkitLoading
                ? [1, 2, 3, 4]
                : toolkitsData || []
              ).map((item, index) => (
                <Grid
                  container
                  justify="flex-start"
                  key={index}
                  spacing={matches ? 5 : 1}>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{textAlign: 'left'}}>
                    {toolkitLoading ? (
                      <>
                        <Skeleton
                          variant="rect"
                          width="100%"
                          height={150}
                        />
                        <br />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                      </>
                    ) : (
                      <Link to={`/toolkits/${item.slug}`}>
                        <img
                          src={item.coverImageURL}
                          style={{
                            maxWidth: '100%',
                            borderRadius: 5,
                          }}
                        />
                        <br />
                        <Typography variant="h5">
                          {item.title}
                        </Typography>
                      </Link>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Slider>
          </Container>
        </div>
      </div>
    </>
  );
}
