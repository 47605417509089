import {getFirebaseIdToken} from './user';
import {functionUrl} from 'helper/functions';
import axios from 'axios';
import _ from 'lodash';
import {serverTimestamp} from './firebase';

export async function sendMessage(payload) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/contact-us`,
      payload,
    );
    const data = _.get(response, 'data', {});
    // console.log('sendMessage dari fn,', response);
    return {
      data: data,
      isSuccess: response.status === 200,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {error};
  }
}
