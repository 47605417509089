import React, {useState, useEffect} from 'react';
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Container,
  makeStyles,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {Pagination, PaginationItem, Skeleton} from '@material-ui/lab';
import CustomTopNav from 'components/layout/TopNav/CutomTopNav';
import {loadToolkits, loadPublishedToolkits} from 'services/toolkits';
import Swal from 'sweetalert2';
import _ from 'lodash';
const useStyle = makeStyles((theme) => ({
  root: {
    color: 'white',
    width: '100%',
    padding: 16,
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function Toolkits() {
  const classes = useStyle();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();

  const [activePage, setActivePage] = useState(1); //start from 1
  const [maxPageNumber, setMaxPageNumber] = useState(0); //start from 0
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const [toolkitsData, setToolkitsData] = useState([]);
  useEffect(() => {
    setLoading(true);
    loadPublishedToolkits({page: activePage - 1})
      .then((res) => {
        if (res.isSuccess) {
          setToolkitsData(_.get(res, 'data.data', []));
          setMaxPageNumber(_.get(res, 'data.maxPageNumber', 0));
        }

        setLoading(false);
      })
      .catch((error) => {
        Swal.fire(
          'Terjadi kesalahan',
          'Mohon cek koneksi Anda',
          'error',
        );
      });
  }, [activePage]);
  return (
    <>
      {isMobile && <CustomTopNav title="Amunisi" showBack={false} />}
      <Container className={classes.root}>
        <div
          style={{
            marginTop: 50,
            padding: 20,
            color: 'white',
            fontWeight: 'bold',
            fontSize: 60,
            textAlign: 'left',
            display: !isMobile ? 'block' : 'none',
          }}>
          Amunisi
        </div>

        <Grid container spacing={4}>
          {/* {!isMobile && (
            <Grid item md={3} xs={12}>
              <List
                component="nav"
                aria-label="secondary mailbox folders">
                <ListItem button>
                  <ListItemText primary="ALL" />
                </ListItem>
                <ListItem button>
                  <ListItemText primary="GRAFIS" />
                </ListItem>
                <ListItem button>
                  <ListItemText primary="CAMPAIGN" />
                </ListItem>
                <ListItem button>
                  <ListItemText primary="VIDEO" />
                </ListItem>
              </List>
            </Grid>
          )} */}
          <Grid item md={12} xs={12}>
            <Grid container spacing={3} alignItems="center">
              {(loading
                ? [1, 2, 3, 4, 5, 6]
                : toolkitsData || []
              ).map((item, index) => (
                <Grid
                  key={index}
                  item
                  md={4}
                  xs={6}
                  style={{textAlign: 'left'}}>
                  {loading ? (
                    <>
                      <Skeleton
                        variant="rect"
                        width="100%"
                        height={150}
                      />
                      <br />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                    </>
                  ) : (
                    <Link to={`/toolkits/${item.slug}`}>
                      <img
                        src={item.coverImageURL || ''}
                        style={{
                          maxWidth: '100%',
                          borderRadius: 5,
                          // width: '100%',
                        }}
                      />
                      <div className="badge badge-warning mt-2">
                        <Typography
                          style={{color: '#333'}}
                          variant="h5">
                          {item.categoryLabel}
                        </Typography>
                      </div>
                      <br />
                      <br />
                      <Typography variant="h5">
                        {item.title}
                      </Typography>
                    </Link>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <div
          style={{
            width: '100%',
            marginTop: 30,
            paddingBottom: 60,
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Pagination
            count={maxPageNumber + 1}
            onChange={(e, val) => setActivePage(val)}
            size="small"
            color="secondary"
            itemProp={{style: {color: 'white'}}}></Pagination>
        </div>
      </Container>
    </>
  );
}
