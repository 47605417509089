import React, {useState, useEffect} from 'react';
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  Container,
  makeStyles,
  Button,
  Grid,
  Divider,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import CustomTopNav from 'components/layout/TopNav/CutomTopNav';
import * as yup from 'yup';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Recaptcha from 'components/Recaptcha';
import {sendMessage} from 'services/contactUs';
import Swal from 'sweetalert2';
import _ from 'lodash';

const useStyle = makeStyles((theme) => ({
  root: {
    color: 'white',
    textAlign: 'center',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const contactUsScheme = yup.object().shape({
  token: yup
    .string()
    .typeError('token salah')
    .required('token harus diisi'),
  name: yup
    .string()
    .typeError('penulisan nama salah')
    .required('nama harus diisi'),
  subject: yup
    .string()
    .typeError('penulisan subject salah')
    .required('subject harus diisi'),
  message: yup
    .string()
    .typeError('penulisan subject salah')
    .required('pesan harus diisi'),
  phone: yup
    .string()
    .min(10, 'minimal nomor telepon 10 karakter')
    .max(14, 'maskimal nomor telepon 14 karakter')
    .typeError('penulisan nomor telepon salah')
    .required('nomor handphone harus diisi')
    .test(
      'is-phone-valid',
      'phone tidak valid, gunakan kode negara misal +6281234123412',
      function (value) {
        // pastikan isinya angka semua
        return /^\+?[1-9]\d{1,14}$/.test(value);
      },
    ),
  email: yup
    .string()
    .typeError('penulisan email salah')
    .email('format email salah')
    .required('email harus diisi')
    .transform(function (value, originalvalue) {
      if (this.isType(value)) return value.trim();
      return value;
    }),
});

export default function About() {
  const classes = useStyle();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('sm'),
  );
  const {control, setValue, handleSubmit, reset} = useForm({
    resolver: yupResolver(contactUsScheme),
  });
  useEffect(() => {
    // eslint-disable-next-line no-undef
    const captcha = grecaptcha;
    captcha.ready(function () {
      captcha
        .execute('6LceuMIaAAAAAEFFFH_MFpALymbccOy6_4pSLdP8')
        .then(function (token) {
          setValue('token', token);
          console.log('v3 Token: ' + token);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSucess = (data) => {
    console.log(data);
    sendMessage(data).then((res) => {
      Swal.fire('Berhasil', 'Pesan telah kami terima', 'success');
      reset({});
      console.log(res.data);
    });
  };
  const onError = (data) => {
    console.log(data);
    Swal.fire(
      'Mohon isi form dengan benar',
      _.get(Object.values(data || {}), '[0].message', ''),
      'error',
    );
  };
  return (
    <>
      {isMobile && (
        <CustomTopNav title="Contact Us" showBack={true} showAuth />
      )}
      <Container className={classes.root}>
        <div
          style={{
            marginBottom: 30,
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            fontSize: 18,
            width: '100%',
          }}>
          <div
            style={{
              color: 'white',
              fontSize: 18,
              maxWidth: 600,
            }}>
            <img src="/images/logo.png" style={{maxWidth: '50%'}} />
            <Typography variant="body1">
              Yuk sapa kami dengan mengisi form berikut!
            </Typography>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSucess, onError)}>
          <Grid container justify="center">
            <Grid item xs={11} md={5}>
              <Grid container spacing={2} justify="center">
                <Controller
                  name="token"
                  control={control}
                  defaultValue=""
                  render={({onChange, value}) => <></>}
                />
                <Grid
                  item
                  sm={12}
                  style={{width: '100%', textAlign: 'left'}}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({onChange, value}) => (
                      <>
                        <label style={{marginBottom: 3}}>Nama</label>
                        <input
                          className="form-control"
                          name="name"
                          value={value}
                          onChange={onChange}
                          size="large"
                          placeholder="Nama Anda"
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{width: '100%', textAlign: 'left'}}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({onChange, value}) => (
                      <>
                        <label style={{marginBottom: 3}}>Email</label>

                        <input
                          className="form-control"
                          name="email"
                          value={value}
                          onChange={onChange}
                          size="large"
                          placeholder="Email"
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{width: '100%', textAlign: 'left'}}>
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    render={({onChange, value}) => (
                      <>
                        <label style={{marginBottom: 3}}>
                          No. WhatsApp / Handphone
                        </label>
                        <input
                          className="form-control"
                          name="phone"
                          value={value}
                          onChange={onChange}
                          size="large"
                          placeholder="Phone"
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  style={{width: '100%', textAlign: 'left'}}>
                  <Controller
                    name="subject"
                    control={control}
                    defaultValue=""
                    render={({onChange, value}) => (
                      <>
                        <label style={{marginBottom: 3}}>
                          Topik Pesan
                        </label>
                        <input
                          className="form-control"
                          name="Topik Pesan"
                          value={value}
                          onChange={onChange}
                          size="large"
                          placeholder="Topik pesan"
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  style={{width: '100%', textAlign: 'left'}}>
                  <Controller
                    name="message"
                    control={control}
                    defaultValue=""
                    render={({onChange, value}) => (
                      <>
                        <label style={{marginBottom: 3}}>Pesan</label>
                        <textarea
                          className="form-control"
                          rows={5}
                          name="message"
                          value={value}
                          onChange={onChange}
                          size="large"
                          placeholder="Ketik pesan disini"
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="secondary"
                    style={{width: '100%'}}>
                    Send Now
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}
